import { ArrowBackIos, ArrowForwardIos, CloudUpload, ContentPasteSearch, Delete, Edit } from '@mui/icons-material'
import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import CustomInput from '../../../../../../helpers/custom-input'
import CustomDropDown from '../../../../../../helpers/dropdown/custom-dropdown'
import { DialogCommonHooks } from '../../../../../../helpers/modal/modal'
import CustomReactMaterialTable from '../../../../../../helpers/table/react-material-table'
import useInput from '../../../../../../helpers/use-input'
import { useRecoilState } from 'recoil'
import { formState } from '../../../../../../recoil/profile-atom'
import { postApiCallDynamicWithOutReturn, uploadFile } from '../../../../../../services/api-service'
import { API } from '../../../../../../services/api-config'
import AdditionalDocumentForm from './additional-document-form'
import { toastNotify } from '../../../../../../helpers/alert-msg'
import { KSPencilAltIcon, KSTrashIcon } from '../../../../../../icons/custome-icon'
import { UPDATE } from '../../../../../../constants/constatnt-variable'
import ConfirmationModel from '../../../../../../helpers/modal/confirm-modal'

export default function LoanAddditionalDocuments() {

    const route = useNavigate()
    const location = useLocation();

    let [formValue, setFormValue] = useRecoilState(formState)

    let [addditionalDocumentList, setAddditionalDocumentList] = useState(() =>
        formValue?.documents?.page?.additionalDocument?.subPage == null ? []
            : _.cloneDeep(formValue?.documents?.page?.additionalDocument?.subPage))


    const [editState, setEditState] = useState(() => {
        return null
    })


    // Edit table
    useEffect(() => {
        if (editState != null) {
            let list = _.cloneDeep(addditionalDocumentList)
            let editItem = list.find((item, i) => i == editState)
            setAddditionalDocumentEdit(editItem)
            setModalOpen(true);
        }

    }, [editState])


    const [deleteState, setDeleteState] = useState(() => {
        return null
    })


    // delete table
    useEffect(() => {
        if (deleteState != null) {
            addditionalDocumentList.splice(deleteState, 1)
            setAddditionalDocumentList([...addditionalDocumentList])
            setDeleteState(null)
        }

    }, [deleteState])

    const [modalOpen, setModalOpen] = useState(false);
    const [addditionalDocumentEdit, setAddditionalDocumentEdit] = useState();

    const handleClickOpen = () => {
        setAddditionalDocumentEdit([])
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
        setEditState(null)
    };

    const additionalSubmit = (data) => {
        data = Object.assign(data, {
            docId: uploadFileID,
        });
        if (editState != null) {
            addditionalDocumentList[editState] = {
                ...addditionalDocumentList[editState],
                ...data
            }

            setEditState(null)
        } else {
            addditionalDocumentList.push(data);
        }

        setAddditionalDocumentList([...addditionalDocumentList]);
        handleClose()
    }



    const tableColumn = useMemo(
        () => [
            {
                accessorKey: "documentName",
                header: "Document Name",
                enableColumnActions: false,
            },
            {
                accessorKey: "documentType",
                header: "Document Type",
                enableColumnActions: false,
            },
            {
                accessorKey: "note",
                header: "Note",
                enableColumnActions: false,
            },

        ],
        []
    );

    const handleSubmit = async () => {
        const apiCallData = {
            page: addditionalDocumentList,
            formId: formValue.id,
            isComplete: true
        }
        const updateState = () => {
            setFormValue({
                ...formValue,
                documents: {
                    ...formValue.documents,
                    page: {
                        ...formValue.documents.page,
                        additionalDocument: {
                            ...formValue.documents.page.additionalDocument,
                            subPage: addditionalDocumentList,
                            isComplete: true
                        }
                    }
                }
            })
            route(location.pathname.replace(/[^/]*$/, '../review'));
            console.log("formValue", formValue);
        }

        await postApiCallDynamicWithOutReturn(
            {
                data: apiCallData,
                path: API.additionalDocument,
                header: UPDATE,
                refresh: updateState
            }
        )
    }

    const [disableSave, setDisableSave] = useState(false)
    const [uploadFileID, setuploadFileID] = useState(null)

    const fileUpload = (event) => {
        setDisableSave(true)
        let file = event.target.files[0]
        if (event.target.files) {
            uploadFile({ formId: formValue?.id, file: file, serviceType: 'additional', path: API.proxyDocumentOthersUpload }).then((res) => {
                if (res.status === 201) {
                    toastNotify("success", res.data.message);
                    setDisableSave(false)
                    setuploadFileID(res.data.docId)
                }
            })
        } else {
            console.log("not found");
        }

    }

    const handleCloseDelete = (event, row) => {
        if (event.target.value == 'yes') {
            setDeleteState(row.index)
        }

    }

    return (
        <>
            <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                <Box>
                    <Typography variant='h3' mb={2}>{`Additional Documents`}</Typography>
                    <Typography variant='body1' mb={2}>{`The purpose of collecting this information is to make sure that every individual will be treated fairly`}</Typography>

                    <CustomReactMaterialTable
                        staticLoad={true}
                        staticData={addditionalDocumentList}
                        // endPoint={API.additionalDocument}
                        // params={{ formId: formValue?.id }}
                        columns={tableColumn}
                        enablePagination={false}
                        enableBottomToolbar={false}
                        enableSorting={false}
                        enableToolbarInternalActions={false}
                        renderTopToolbarCustomActions={
                            () => (
                                <Button variant='outlined' onClick={handleClickOpen}>Add</Button>
                            )
                        }
                        enableRowActions={true}
                        renderRowActions={
                            ({ row }) => {
                                return (
                                    <Stack direction={'row'}>
                                        <IconButton>
                                            <ContentPasteSearch />
                                        </IconButton>
                                        <IconButton onClick={() => { setEditState(row.index) }}>
                                            <KSPencilAltIcon />
                                        </IconButton>

                                        <ConfirmationModel
                                            body={<IconButton
                                                id={row.index} onClick={() => {

                                                }}>
                                                <KSTrashIcon />
                                            </IconButton>}
                                            message={`Are you sure want remove the  additional documents?`}
                                            title={`Delete Additional Documents`}
                                            handleSubmit={(event) => { handleCloseDelete(event, row) }}
                                        />
                                    </Stack>
                                )
                            }
                        }
                    />
                </Box >

                <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Link to={'../../documents/guided-document'}>
                            <Button startIcon={<ArrowBackIos />}>
                                Back
                            </Button>
                        </Link>
                        {/* <Link to={'../../review'}> */}
                        <Button onClick={handleSubmit} endIcon={<ArrowForwardIos />}>
                            Next
                        </Button>
                        {/* </Link> */}
                    </Stack>
                </Box>
            </Stack >



            <DialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={`Add additional documents`}
                body={
                    <AdditionalDocumentForm
                        handleClose={handleClose}
                        fileUpload={fileUpload}
                        onSubmit={additionalSubmit}
                        editItem={addditionalDocumentEdit}
                        disableSave={disableSave}
                        uploadFileID={uploadFileID}
                    />
                }
            />
        </>
    )
}
