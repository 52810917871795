import React from "react";
import {Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import loginImage from "../assets/bg_sign.png";

function AuthSideUi() {
  return (
    <>
      <Grid
        item
        container
        md={6}
        sm={6}
        xs={false}
        display={{ xs: "none", sm: "block" }}
      >
        <Grid item height={"40vh"}>
          <Box p={5}>
            <Typography
              mt={4}
              sx={{
                fontFamily: "inter",
                fontSize: "32px",
                fontWeight: 200,
                color: "#888888",
                lineHeight: "48px",
              }}
            >
              The Best Homes are
            </Typography>
            <Typography
              sx={{
                fontFamily: "inter",
                fontSize: "32px",
                fontWeight: 200,
                color: "#888888",
                lineHeight: "48px",
              }}
            >
              Financed through
            </Typography>
            <Typography
              sx={{
                fontFamily: "inter",
                fontSize: "60px",
                fontWeight: 700,
                color: "#2F80ED",
              }}
            >
              Kramasoft
            </Typography>
          </Box>
        </Grid>
        <Grid item height={"60vh"}>
          <Box width={{ xs: "100%", sm: "80%", md: "65%" }}>
            <img src={loginImage} width={"100%"} style={{ marginTop: "20%" }} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default AuthSideUi;
