import { Typography } from '@mui/material'
import React from 'react'
import MainLoyOut from '../../components/main-layout'

export default function Welcome(props) {
    return (
        <MainLoyOut>
            <Typography variant='h2' mb={2}>Welcome {props?.user?.data?.role} ({props?.user?.data?.firstName}) !</Typography>
            <Typography>{`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s`}</Typography>
        </MainLoyOut>
    )
}
