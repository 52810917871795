import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import {useRecoilState} from 'recoil'
import {formState} from '../../../../../../recoil/profile-atom'
import {postApiCallDynamicWithOutReturn} from '../../../../../../services/api-service'
import {API} from '../../../../../../services/api-config'

import {UPDATE} from '../../../../../../constants/constatnt-variable'
import {MilitaryServiceForm} from './military-service-form'

export default function LoanMilitaryService() {

    const route = useNavigate()
    const location = useLocation();
    const partnerInfoPath = location.pathname.split("/")[location.pathname.split("/").length - 2]

    let [formValue, setFormValue] = useRecoilState(formState)

    let [militaryService, SetMilitaryService] = useState(() => null)

    let [formValueId, setFormValueId] = useState(() => null);

    useEffect(() => {
        if (partnerInfoPath == "partner-info") {
            SetMilitaryService(formValue.partnerInfo?.page?.militaryServices?.subPage)
            setFormValueId(formValue.id)
        } else {
            SetMilitaryService(formValue.borrowerInfo?.page?.militaryServices?.subPage)
            setFormValueId(formValue.id)
        }
    }, [formValue.partnerInfo?.page?.militaryServices?.subPage, formValue.borrowerInfo?.page?.militaryServices?.subPage, formValue.id])


    const onSubmit = async data => {

        let path, updateState
        if (partnerInfoPath == "partner-info") {
            path = API.partnerInfoMilitaryService
            updateState = {
                ...formValue,
                partnerInfo: {
                    ...formValue.partnerInfo,
                    page: {
                        ...formValue.partnerInfo.page,
                        militaryServices: {
                            ...formValue.partnerInfo.page.militaryServices,
                            subPage: data,
                            isComplete: true
                        }
                    }
                }
            }
        } else {
            path = API.borrowerInfoMilitaryService
                updateState = {
                    ...formValue,
                    borrowerInfo: {
                        ...formValue.borrowerInfo,
                        page: {
                            ...formValue.borrowerInfo.page,
                            militaryServices: {
                                ...formValue.borrowerInfo.page.militaryServices,
                                subPage: data,
                                isComplete: true
                            }
                        }
                    }
                }
        }

        const apiCallData = {
            page: {
                subPage: data,
                isComplete: true,
            },
            isComplete: true,
            formId: formValue.id
        }

        const setMilitaryServiceInRecoil = () => {
            setFormValue(updateState)
            // route(location.pathname.replace(/[^/]*$/, 'assets-credits'))
            if (partnerInfoPath == "partner-info") {
                route(`/borrower/loan-application/${formValue?.id}/assets-credits`)
            } else {
                if (formValue?.hasPartner == true) {
                    route(`/borrower/loan-application/${formValue?.id}/partner-info/personal-information`)
                } else {
                    route(`/borrower/loan-application/${formValue?.id}/assets-credits`)
                }

            }

        }

        await postApiCallDynamicWithOutReturn(
            {
                data: apiCallData,
                path: path,
                header: UPDATE,
                refresh: setMilitaryServiceInRecoil,
            }
        )
    }

    return (
        <>
            {
                formValueId == null ?
                    <></>
                    :
                    <MilitaryServiceForm partnerInfoPath={partnerInfoPath} data={militaryService} onSubmit={onSubmit} />
            }
        </>
    )
}
