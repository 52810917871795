import axios from "axios";
import { APIConfig } from "../auth/config";
import { AlertError } from "./alert-msg";
import { API } from "../services/api-config";


export const downloadReportFromServer = (url, data) => {
    let params = {};
    if (data) {
        params = data;
    }
    return axios
        .get(
            url,
            {
                params: params,
                responseType: "blob",
            }

            // important
        )
        .then((response) => {
            let filename = "";
            let blob = new Blob([response.data]),
                downloadUrl = window.URL.createObjectURL(blob),
                disposition = response.headers["content-disposition"];
            console.log("disposition", disposition);

            if (disposition && disposition.indexOf("attachment") !== -1) {
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
                    matches = filenameRegex.exec(disposition);

                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            let a = document.createElement("a");
            if (typeof a.download === "undefined") {
                window.location.href = downloadUrl;
            } else {
                a.href = downloadUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
            }
        })
        .catch((error) => AlertError(error));
};



export const getImageUrl = (id, type) => {
    if (id) {
        return process.env.REACT_APP_BASE_URL + API.proxyUserDownload + `?id=${id}&&serviceType=${type}`
    }
}