import { ArrowForwardIos } from '@mui/icons-material';
import { Box, Button, Grid, Stack } from '@mui/material';
import React from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form';
import { LOAN_PARTNER_STATUS } from '../../../../../constants/constatnt-variable';
import CustomInput from '../../../../../helpers/custom-input';
import CustomLargeLabel from '../../../../../helpers/large-label';
import CustomRadioChip from '../../../../../helpers/radio-chip';
import loanPageSideImage from '../../../../../assets/loanPageSideImage.png'
import { GettingStartedValidation } from '../../../../../constants/validation-rules';

export default function Partner({ data, onSubmit }) {


    // const [loanPartnerStatus] = useInput({
    //     data: data ? 'yes' : 'no',
    //     status: true,
    //     initial: ""
    // });


    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            firstName: data?.partner?.firstName,
            lastName: data?.partner?.lastName,
            phone: data?.partner?.phone,
            email: data?.partner?.email,
            loanPartnerStatus: data?.hasPartner,
        },
    })



    const { loanPartnerStatus } = useWatch({ control })


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>

                <Grid container height={'inherit'} px={{ xs: 1, md: 4 }}>
                    <Grid item xs={12} md={8}>
                        <Stack direction={'column'} justifyContent={'space-between'} height={'100%'}>
                            <Box>
                                <Box>
                                    {/* <CustomRadioChip
                                        labelComponent={<CustomLargeLabel required value={'Do you have a partner for this loan ?'} />}
                                        state={loanPartnerStatus}
                                        data={LOAN_PARTNER_STATUS}

                                    /> */}
                                    <Controller
                                        name='loanPartnerStatus'
                                        control={control}
                                        rules={GettingStartedValidation.loanPartnerStatus}
                                        render={({ field }) => (
                                            <CustomRadioChip
                                                {...field}
                                                field={field}
                                                required
                                                labelComponent={<CustomLargeLabel required value={'Do you have a partner for this loan ?'} />}
                                                state={loanPartnerStatus}
                                                data={LOAN_PARTNER_STATUS}
                                                error={errors?.loanPartnerStatus?.message}

                                            />
                                        )}
                                    />

                                </Box>
                                {loanPartnerStatus == 'Yes' ?
                                    <>
                                        <CustomLargeLabel value={'Enter your partner details'} />
                                        <Box width={{ xs: '100%', md: '50%' }} my={2} >
                                            <Controller
                                                name="firstName"
                                                control={control}
                                                rules={GettingStartedValidation.firstName}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        {...field}
                                                        label="First Name"
                                                        required
                                                        error={errors?.firstName?.message}
                                                    />
                                                )}
                                            />
                                            {/* {errors.firstName && <p>This field is required</p>} */}
                                            <Controller
                                                name="lastName"
                                                control={control}
                                                rules={GettingStartedValidation.lastName}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        {...field}
                                                        label="Last Name"
                                                        required
                                                        error={errors?.lastName?.message}
                                                    />
                                                )}
                                            />

                                            <Controller
                                                name="phone"
                                                control={control}
                                                rules={GettingStartedValidation.phone}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        {...field}
                                                        label="Phone Number"
                                                        required
                                                        type="number"
                                                        error={errors?.phone?.message}
                                                    />
                                                )}
                                            />

                                            <Controller
                                                name="email"
                                                control={control}
                                                rules={GettingStartedValidation.email}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        {...field}
                                                        label="Email"
                                                        required
                                                        error={errors?.email?.message}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </>
                                    :
                                    null
                                }
                            </Box>

                            <Box my={2}>
                                <Button type="submit" endIcon={<ArrowForwardIos />} disabled={data.loanPartnerStatus == ''}>
                                    Next
                                </Button>
                            </Box>
                        </Stack >
                    </Grid >
                    <Grid item xs={12} md={4} display={'flex'} height={'100%'}>
                        <Box display={{ xs: 'none', md: 'block' }} pl={3} my={'auto'}>
                            <img src={loanPageSideImage} width={"100%"} />
                        </Box>
                    </Grid>
                </Grid >

            </form>


        </>
    )
}
