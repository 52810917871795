import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import CustomReactMaterialTable from "../../helpers/table/react-material-table";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import ProfileImg from "../../assets/Avatar-mlo.png";
import { Link, useNavigate } from "react-router-dom";
import { Path } from "../../routes/route-config";
import MainLoyOut from "../main-layout";
import { API } from "../../services/api-config";
import { useRecoilValue } from "recoil";
import { profileState } from "../../recoil/profile-atom";
import { LOAN_STATUS } from "../../constants/constatnt-variable";
import { KSChatSmIcon, KSPencilAltIcon } from "../../icons/custome-icon";
import ChatApp from "../../pages/chat/chat-app";

function LoanData() {
  let profileInfo = useRecoilValue(profileState);
  const [chatData, setChatData] = useState(
    {
      firstScreen: false,
      externalChat: {},
      internalChat: {},
      appNo: ""
    }
  );



  const [value, setValue] = useState("all");
  let route = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "appNo",
        header: "App No.",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          return (
            <Link
              key={cell.getValue()}
              to={Path.loanSummary + "/" + cell.getValue()}
            >
              {cell.getValue()}
            </Link>
          );
        },
      },

      //column definitions...
      {
        accessorFn: (row) => {
          return (
            <>
              <Typography>{row.borrowerName}</Typography>
              {row.otherBorrowers.map((item, index) => (
                <Typography key={index} my={1} sx={{ minWidth: 'max-content' }}>
                  {item.name}
                  {item.type ? ` (${item.type})` : null}
                </Typography>
              ))}
            </>
          );
        },
        accessorKey: "borrowerName",
        header: "Borrower Name",
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorFn: (row, cell) => {
          return (
            <>
              <Typography
                variant="body2"
                sx={{
                  backgroundColor:
                    row.formStatus === LOAN_STATUS.active
                      ? ""
                      : row.formStatus === LOAN_STATUS.inProgress
                        ? "#FFB21026"
                        : "#D82E5726",

                  borderRadius: "44px",
                  color:
                    row.formStatus === LOAN_STATUS.active
                      ? ""
                      : row.formStatus === LOAN_STATUS.inProgress
                        ? "#FFB210"
                        : "#D82E57",
                  maxWidth: "12ch",
                  p: "0.25rem",
                  textAlign: "center",
                  m: "0.25rem",
                }}
              >
                {row.formStatus}
              </Typography>

              {row?.otherBorrowers?.map((item, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  sx={{
                    backgroundColor:
                      row.formStatus === LOAN_STATUS.active
                        ? ""
                        : row.formStatus === LOAN_STATUS.inProgress
                          ? "#FFB21026"
                          : "#D82E5726",

                    borderRadius: "44px",
                    color:
                      row.formStatus === LOAN_STATUS.active
                        ? ""
                        : row.formStatus === LOAN_STATUS.inProgress
                          ? "#FFB210"
                          : "#D82E57",
                    maxWidth: "12ch",
                    p: "0.25rem",
                    textAlign: "center",
                    m: "0.25rem",
                  }}
                >
                  {item?.formStatus}
                </Typography>
              ))}
            </>
          );
        },
        id: "formStatus",
        header: "App. Status",
        enableColumnActions: false,
        enableSorting: false,
      },

      {
        accessorKey: "mloName",
        header: "MLO",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {cell.getValue() === null ? (
                "Not assigned"
              ) : (
                <img
                  alt="Profile"
                  height={30}
                  src={'https://picsum.photos/200/300'}
                  loading="lazy"
                  style={{ borderRadius: "50%" }}
                />
              )}
              <Typography>{cell.getValue()}</Typography>
            </Box>
          );
        },
      },
      {
        accessorKey: "mlpName",
        header: "MLP",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {cell.getValue() === null ? (
                "Not assigned"
              ) : (
                <img
                  alt="Profile"
                  height={30}
                  src={ProfileImg}
                  loading="lazy"
                  style={{ borderRadius: "50%" }}
                />
              )}
              <Typography>{cell.getValue()}</Typography>
            </Box>
          );
        },
      },
      {
        accessorKey: "action",
        header: "Action",
        enableColumnActions: false,
        enableSorting: false,

        Cell: ({ cell, column, row, table }) => {
          return (
            <Stack direction={"row"}>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton>
                  <KSPencilAltIcon />
                </IconButton>
              </Tooltip>

              <Tooltip arrow placement="right" title="Chat">
                <IconButton
                  onClick={() => {
                    setChatData({
                      firstScreen: true,
                      externalChat: row?.original?.externalChat,
                      internalChat: row?.original?.internalChat,
                      appNo: row?.original?.appNo
                    })
                  }}
                >
                  <KSChatSmIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        },
      },
      {
        accessorKey: "id",
        header: "",
        enableColumnActions: false,
        enableSorting: false,

        Cell: ({ cell, column, row, table }) => {
          return (
            <Box justifyContent={"center"}>
              <Button variant="contained" onClick={() => { route(window.open(Path.loanApplication + `/${row.original.formId}`, "_blank")) }}>
                {row.original.isLocked ? `View application` : `Continue your application`}
              </Button>
            </Box>
          );
        },
      },

      //end
    ],
    []
  );



  return (
    <>
      <ChatApp setChatData={setChatData} chatData={chatData} />
      <Grid container>
        <Grid item md={7} xs={12}>
          <Typography variant="h2">
            Welcome back, {profileInfo.firstName}
          </Typography>
          <Typography variant="body1" my={2}>
            {`Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text ever
            since the 1500s`}
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <TabContext value={value}>
            <Paper>
              <Box>
                <TabList
                  onChange={handleChange}
                  variant="scrollable"
                  allowScrollButtonsMobile
                >
                  <Tab label="All" value="all" />
                  <Tab label="Active" value="active" />
                  <Tab label="In Progress" value="in Progress" />
                  <Tab label="Closed" value="closed" />
                  <Tab label="Rejected" value="rejected" />
                  <Tab label="Locked" value="locked" />
                  <Tab label="Cancelled" value="archived" />
                </TabList>
              </Box>
            </Paper>
          </TabContext>
          <CustomReactMaterialTable
            enableToolbarInternalActions={false}
            enablePagination={false}
            // staticLoad={true}
            // staticData={tableData}
            // refetch={reloadTable}
            params={{ status: value }}
            endPoint={API.loanApplications}
            columns={columns}
            enableTopToolbar={false}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default LoanData;
