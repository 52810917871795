import {Autocomplete, Box, Button, Grid, InputLabel, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React from 'react'


export function CustomAutocomplete({
  searchOptions = [],
  onChange,
  value,
  freeSolo = true,
  popupIcon = false,
  onClick,
  placeholder,
  renderOption,
  getOptionLabel,
  onInputChange,
  label,
  change
}) {
  return (
    <Grid container py={3} margin="auto">
      <Autocomplete
        freeSolo={freeSolo} // for no option indication
        fullWidth
        popupIcon={popupIcon}
        options={searchOptions}
        autoHighlight
        getOptionLabel={getOptionLabel}
        value={value}
        onChange={onChange}
        onInputChange={onInputChange} // for external search
        renderOption={renderOption}
        renderInput={(params) => (
          <>
            <InputLabel>{label}</InputLabel>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px 0px 0px 8px",
                    height: "50px",
                  },
                }}
                {...params}
                inputProps={{
                  ...params.inputProps,
                }}
                placeholder={placeholder}
                {...change}
              />
              <Button
                variant="contained"
                size="large"
                onClick={onClick}
                sx={{
                  height: "50px",
                  borderRadius: "0px 8px 8px 0px",
                }}
              >
                <SearchIcon />
              </Button>
            </Box>
          </>
        )}
      />
    </Grid>
  );
}
