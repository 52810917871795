import { Box, Button, Grid } from '@mui/material'
import { useFormik } from 'formik';
import React from 'react'
import CustomInput from '../../../helpers/custom-input'
import useInput from '../../../helpers/use-input';
import { API } from '../../../services/api-config';
import { postApiCallDynamicWithOutReturn } from '../../../services/api-service';
import { UPDATE } from '../../../constants/constatnt-variable';

export default function NmlsNoteForm({ userCode }) {

    let [nmlsNotes] = useInput({
        data: "",
        status: false,
        initial: "",
    });

    const handleValidation = (values) => {
        let errors = {};

        if (nmlsNotes.value === "") {
            errors.nmlsNotes = "Please enter a note";
        }
        return errors;
    };

    const handleClose = () => {
        nmlsNotes.saveValue("")
    }

    const formik = useFormik({
        initialValues: {
            nmlsNotes: nmlsNotes.value,
        },
        validate: (values) => handleValidation(values),
        onSubmit: (values) => {
            let apiCallData = {
                userCode: userCode,
                nmlsNotes: nmlsNotes.value
            }
            postApiCallDynamicWithOutReturn({
                data: apiCallData, refresh: () => {
                    // Nothing

                }, path: API.nmlsStatusChange, header: UPDATE
            })
            handleClose()
        },
    });

    return (
        <Grid item xs={12} sm={12} md={12}>
            <form id="newcCmpNmlsId" onSubmit={formik.handleSubmit}>
                <CustomInput
                    label="Note"
                    fullWidth
                    size="large"
                    error={formik.errors.nmlsNotes}
                    placeholder="Write a note when its invalidate, that will be send to the respective person."
                    multiline
                    rows={10}
                    change={nmlsNotes}
                />
                <Box display="flex" justifyContent="flex-end" m={1}>
                    <Button type="submit" variant="contained">Send</Button>
                </Box>
            </form>
        </Grid>
    )
}
