import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box, Stack } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import "../styles/admin.css";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useRecoilState } from "recoil";
import { profileState } from "../recoil/profile-atom";
import { getApiCallDynamic } from "../services/api-service";
import { API } from "../services/api-config";
import ConfirmationModel from "../helpers/modal/confirm-modal";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { Path } from "../routes/route-config";
import Notificaiton from "./notification";
import { KSArrowLeftIcon } from "../icons/custome-icon";

const useStyle = makeStyles({
  iconColor: {
    color: "white",
  },
});

//white space for main content area
const drawerWidth = 260;

//menu bar onclick drawer open
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }),
}));

export default function KSLoanAppbar({ appbarBackNavigation, ...props }) {
  const router = useNavigate();
  const classes = useStyle();
  const location = useLocation();

  // app bar user account state
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open_user = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Notificaiton state
  const [notificationEl, setNotificationEl] = React.useState(null);
  const notificaitonOpen = Boolean(notificationEl);
  const notificationClick = (event) => {
    setNotificationEl(event.currentTarget);
  };
  const notificationClose = () => {
    setNotificationEl(null);
  };

  // drawer state
  const { open, handleDrawerOpen, toptitle } = props;
  let [profileData, setProfileData] = useRecoilState(profileState);

  useEffect(() => {
    let param = { userCode: props.user.data.userCode };
    getApiCallDynamic({ path: API.ownInfo, param: param }).then((res) => {
      if (res?.status === 200) {
        setProfileData(res.data);
        // setProfileData(res.data, res.data.role = "MLO Owner"); //for role based testing
      }
    });
  }, []);
  return (
    <>
      {/* appbar area start here */}
      <AppBar position="fixed" open={open} sx={{ boxShadow: "none" }}>
        <Toolbar sx={{ px: "8px !important" }}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
            overflow={"hidden"}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={{ sm: 0, md: 1 }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                sx={{
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>

              {
                appbarBackNavigation && <IconButton color="inherit" onClick={() => {
                  router(location.pathname.split("/").at(-3));
                }}>
                  <KSArrowLeftIcon />
                </IconButton>
              }

              <Typography
                variant={{ sm: "body1", md: "h6" }}
                fontWeight={{ sm: "normal", md: "bold" }}
              >
                {toptitle}
              </Typography>
            </Stack>

            <Stack direction="row" gap={{ sm: 0, md: 1 }}>
              <Tooltip title="Setting">
                <IconButton
                  onClick={() => {
                    router(Path.setting);
                  }}
                >
                  <SettingsOutlinedIcon className={classes.iconColor} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Notifications">
                <IconButton
                  id="notificaiton-button"
                  aria-controls={
                    notificaitonOpen ? "notificaiton-menu" : null
                  }
                  aria-haspopup="true"
                  aria-expanded={notificaitonOpen ? "true" : null}
                  onClick={notificationClick}
                >
                  <NotificationsNoneOutlinedIcon
                    className={classes.iconColor}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                id="notificaiton-menu"
                anchorEl={notificationEl}
                open={notificaitonOpen}
                onClose={notificationClose}
                MenuListProps={{
                  "aria-labelledby": "notificaiton-button",
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <Notificaiton />
              </Menu>

              <Tooltip title="Account">
                <IconButton
                  onClick={handleClick}
                  size="medium"
                  aria-controls={open_user ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open_user ? "true" : undefined}
                >
                  <AccountCircleOutlinedIcon className={classes.iconColor} />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open_user}
                onClose={handleMenuClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem>
                  <ListItemIcon>
                    <VerifiedUserIcon fontSize="small" />
                  </ListItemIcon>
                  {profileData?.firstName}
                </MenuItem>
                <MenuItem>
                  <ListItemIcon>
                    <RecentActorsIcon fontSize="small" />
                  </ListItemIcon>
                  {profileData?.role}
                </MenuItem>
                <Divider />
                <ConfirmationModel
                  body={
                    <MenuItem>
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  }
                  handleSubmit={props.handleLogout}
                  message="Are you sure, You want to Logout?"
                  title={"Confirmation"}
                />
              </Menu>
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>
      {/* AppBar area end here */}
    </>
  );
}
