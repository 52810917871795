import React, { useState } from 'react'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import { LOAN_DECLARATIONS } from '../../../../../../constants/constatnt-variable';
import { DialogCommonHooks } from '../../../../../../helpers/modal/modal';
import CustomRadioChip from '../../../../../../helpers/radio-chip';
import LoanDeclarationsCheck from '../../../../../../components/loan-application/loan-declaration-check';
import { Controller, useForm } from 'react-hook-form'
import useInput from '../../../../../../helpers/use-input';
import { useLocation, useNavigate } from 'react-router-dom';


export const DeclarationForm = ({ data, onCheckSubmit }) => {
    const route = useNavigate()
    const location = useLocation()

    const [modalOpen, setModalOpen] = useState(false);

    const onSubmit = async data => {
        handleClickOpen()
    }

    const handleClickOpen = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };



    const { control, handleSubmit, formState: { errors }, watch } = useForm({
        defaultValues: {
            checkbox: data?.checkbox,
            alimony: data?.alimony,
            moneyForLoan: data?.moneyForLoan,
            primaryResident: data?.primaryResident,
            ownershipProperty: data?.ownershipProperty,
            affiliation: data?.affiliation,
            moneyFromAnotherParty: data?.moneyFromAnotherParty,
            loanOnAnotherProperty: data?.loanOnAnotherProperty,
            creditOnBeforeLoan: data?.creditOnBeforeLoan,
            lien: data?.lien,
            coSignerOrGuarantor: data?.coSignerOrGuarantor,
            outstandingJudgement: data?.outstandingJudgement,
            federalDept: data?.federalDept,
            partyLawsuit: data?.partyLawsuit,
            convertTitle: data?.convertTitle,
            preForeclosure: data?.preForeclosure,
            propertyForeclosed: data?.propertyForeclosed,
            text: data?.text,
            isDeclared: true,
        }
    })

    const [loanOnAnotherProperty] = useInput({
        data: null,
        status: false,
        initial: null
    });

    const [creditOnBeforeLoan] = useInput({
        data: null,
        status: false,
        initial: null
    });

    const [lien] = useInput({
        data: null,
        status: false,
        initial: null
    });

    const [coSignerOrGuarantor] = useInput({
        data: null,
        status: false,
        initial: null
    });

    const [outstandingJudgement] = useInput({
        data: null,
        status: false,
        initial: null
    });

    const [federalDept] = useInput({
        data: null,
        status: false,
        initial: null
    });

    const [partyLawsuit] = useInput({
        data: null,
        status: false,
        initial: null
    });

    const [convertTitle] = useInput({
        data: null,
        status: false,
        initial: null
    });

    const [preForeclosure] = useInput({
        data: null,
        status: false,
        initial: null
    });

    const [propertyForeclosed] = useInput({
        data: null,
        status: false,
        initial: null
    });

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                    <Box>
                        <Typography variant='h3'>{`Hello Peter John, Government wants us to collect this info`}</Typography>

                        <Controller
                            name='alimony'
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    label={'Are you a obligated to pay alimony?'}

                                    data={LOAN_DECLARATIONS}
                                />
                            )}
                        />

                        <Controller
                            name='moneyForLoan'
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    label={'About this property and your money for this loan'}

                                    data={LOAN_DECLARATIONS}
                                />
                            )}
                        />

                        <Controller
                            name='primaryResident'
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    label={'Will you occupy this property as your primary resident?'}

                                    data={LOAN_DECLARATIONS}
                                />

                            )}
                        />

                        <Controller
                            name='ownershipProperty'
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    label={'Have you had ownership property in last three years?'}

                                    data={LOAN_DECLARATIONS}
                                />
                            )}
                        />


                        <Controller
                            name='affiliation'
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    label={`If this is a purchase transaction, do you have a family relationship 
                                    or business affiliation with the seller of the property?`}

                                    data={LOAN_DECLARATIONS}
                                />
                            )}
                        />

                        <Controller
                            name='moneyFromAnotherParty'
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    label={`Are you borrowing any money for this real estate transaction 
                                ( e.g. money for your closing cost or down payment) 
                                for obtaining any money from another party, such as the seller or realtor, 
                                that you have not disclosed on this loan application.`}

                                    data={LOAN_DECLARATIONS}
                                />
                            )}
                        />

                        <Controller
                            name='loanOnAnotherProperty'
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    label={`Have you or will you be applying for a Mortgage Loan on another 
                                property ( Not the property securing this loan) on or 
                                before closing this transaction that is not disclosed on this loan application.`}
                                    state={loanOnAnotherProperty}
                                    data={LOAN_DECLARATIONS}
                                />
                            )}
                        />

                        <Controller
                            name='creditOnBeforeLoan'
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    label={`Have you or will you be applying for any new credit ( e.g.  installment loan
                                , credit card, etc.) on or before closing this loan
                                that is not disclosed in this application.`}
                                    state={creditOnBeforeLoan}
                                    data={LOAN_DECLARATIONS}
                                />
                            )}
                        />

                        <Controller
                            name='lien'
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    label={`Will this property be subject to a lien that could take priority or the first 
                                mortgage lien, such as a Clean Energy lien paid
                                through your property taxes ( e g. the property assessed Clean Energy program) ?`}
                                    state={lien}
                                    data={LOAN_DECLARATIONS}
                                />
                            )}
                        />

                        <Controller
                            name='coSignerOrGuarantor'
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    label={`Are you a co-signer or guarantor on any Dept or loan that is not disclosed on this application?`}
                                    state={coSignerOrGuarantor}
                                    data={LOAN_DECLARATIONS}
                                />
                            )}
                        />

                        <Controller
                            name='outstandingJudgement'
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    label={`Are there any outstanding judgement against you?`}
                                    state={outstandingJudgement}
                                    data={LOAN_DECLARATIONS}
                                />
                            )}
                        />


                        <Controller
                            name='federalDept'
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    label={`are you currently delinquent or under fault on a federal Dept`}
                                    state={federalDept}
                                    data={LOAN_DECLARATIONS}
                                />
                            )}
                        />

                        <Controller
                            name='partyLawsuit'
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    label={`Are you party lawsuit in which you potentially have any personal financial liability?`}
                                    state={partyLawsuit}
                                    data={LOAN_DECLARATIONS}
                                />
                            )}
                        />

                        <Controller
                            name='convertTitle'
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    label={`Have you convert title to any property in lieu of foreclosure in the past seven years?`}
                                    state={convertTitle}
                                    data={LOAN_DECLARATIONS}
                                />
                            )}
                        />

                        <Controller
                            name='preForeclosure'
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    label={`Within the past seven years, Have you completed a pre-
                                foreclosure sale or a short sale, whereby the property was 
                                sold to a third party and the lender agreed to accept less 
                                than the outstanding mortgage balance due.`}
                                    state={preForeclosure}
                                    data={LOAN_DECLARATIONS}
                                />
                            )}
                        />

                        <Controller
                            name='propertyForeclosed'
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    label={`Have you had property foreclosed upon in the last seven years?`}
                                    state={propertyForeclosed}
                                    data={LOAN_DECLARATIONS}
                                />
                            )}
                        />

                        <Typography variant='h6'>{`Add a note`}</Typography>
                        <Box maxWidth={{ xs: '100%', md: '50%' }}>
                            <Controller
                                name='text'
                                control={control}
                                rules={{}}
                                render={({ field }) => (
                                    <TextField {...field} type={'text'} multiline minRows={4} fullWidth />
                                )}
                            />
                        </Box>
                    </Box>


                    <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Button startIcon={<ArrowBackIos />} onClick={() => { route(location.pathname.replace(/[^/]*$/, 'demographics')) }}>
                                Previous
                            </Button>
                            <Button type='submit' endIcon={<ArrowForwardIos />} >
                                Next
                            </Button>
                        </Stack>
                    </Box>
                </Stack>
            </form>

            <DialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={`We need your consent to proceed.`}
                body={
                    <LoanDeclarationsCheck
                        onSumbit={onCheckSubmit}
                        handleSubmit={handleSubmit}
                        Controller={Controller}
                        control={control}
                        checkbox={data?.checkbox}
                        errors={errors}
                    />
                }
            />
        </>
    )
}