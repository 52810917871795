import { Box, Button } from "@mui/material";
import React from "react";
import { CREATE, MODAL_ACTION } from "../../../../constants/constatnt-variable";
import CustomInput from "../../../../helpers/custom-input";
import { Controller, useForm } from "react-hook-form";
import { LoanNoteValidation } from "../../../../constants/validation-rules";

export default function LoanNoteForm({
  data,
  action = CREATE,
  onSubmit,
}) {



  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      note: data?.note,
      id: data?.id
    }
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name='note'
        control={control}
        rules={LoanNoteValidation.note}
        render={({ field }) => (
          <CustomInput
            {...field}
            label={'Notes'}
            required
            placeholder={'This loan is in the process'}
            size="large"
            multiline
            rows={10}
            error={errors?.note?.message}
          />
        )}
      />

      <Box display="flex" justifyContent="flex-end" m={1}>
        <Button type='submit' id={action} variant="contained" onClick={handleSubmit}>
          {MODAL_ACTION[action]}
        </Button>
      </Box>
    </form>
  );
}
