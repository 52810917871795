import React from 'react';
import { CreditScore, CurrencyExchangeOutlined, ErrorOutlineOutlined, ForwardToInboxOutlined, HelpOutline, Leaderboard, SettingsOutlined } from '@mui/icons-material';
import { ROLE_LIST } from '../../constants/constatnt-variable';

const allRole = [ROLE_LIST.broker, ROLE_LIST.mloOwner, ROLE_LIST.borrower, ROLE_LIST.mlo, ROLE_LIST.mlp]
const allRoleWithAdmin = [ROLE_LIST.broker, ROLE_LIST.mloOwner, ROLE_LIST.borrower, ROLE_LIST.mlo, ROLE_LIST.mlp, ROLE_LIST.admin]
const internalRole = [ROLE_LIST.broker, ROLE_LIST.mloOwner, ROLE_LIST.mlo, ROLE_LIST.mlp]

export const menuItems = [
    {
        id: 1,
        label: 'Loans',
        icon: CurrencyExchangeOutlined,
        url: 'loan-application',
        roles: allRole
    },
    {
        id: 2,
        label: 'Invitations',
        icon: ForwardToInboxOutlined,
        url: 'invitation-list',
        roles: allRole
    },
    {
        id: 3,
        label: 'Leads',
        icon: Leaderboard,
        url: 'leads',
        roles: internalRole
    },
    {
        id: 4,
        label: 'Support',
        icon: HelpOutline,
        url: 'support',
        roles: [ROLE_LIST.admin]
    },
    {
        id: 5,
        label: 'Demo Request',
        icon: ErrorOutlineOutlined,
        url: 'demo-request',
        roles: [ROLE_LIST.admin]
    },
    {
        id: 6,
        label: 'Team Invitation',
        icon: ErrorOutlineOutlined,
        url: 'team-invitation',
        roles: [ROLE_LIST.mlp]
    },
];

export const menuItemsDown = [
    {
        id: 1,
        label: 'Credco Credentials',
        icon: CreditScore,
        url: 'credco-credentials',
        roles: [ROLE_LIST.broker, ROLE_LIST.mlo]
    },
    {
        id: 2,
        label: 'Settings',
        icon: SettingsOutlined,
        url: 'settings',
        roles: allRoleWithAdmin
    },
];