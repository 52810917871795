import React from "react";

import { Box, styled } from "@mui/material";
import { AlertSeverity } from "../helpers/alert-msg";

//drawerHeader style
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function MainLoyOut({ p = { md: 3, xs: 2 }, ...props }) {
  return (
    <Box p={p} name={"mainLayout"} position={"relative"}>
      <Box
        position={"absolute"}
        height={"100%"}
        sx={{ top: 0, left: 0, right: 0 }}
      >
        <Box
          sx={{ top: 0, left: 0, right: 0 }}
          spacing={2}
          position={"sticky"}
          zIndex={999}
        >
          {/* Showing the alert notification on top of main layout */}
          <AlertSeverity />
        </Box>
      </Box>
      <Box position={"relative"}>{props.children}</Box>
    </Box>
  );
}
