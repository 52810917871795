import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import MainLoyOut from "../../../components/main-layout";
import CustomReactMaterialTable from "../../../helpers/table/react-material-table";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { useNavigate } from "react-router-dom";
import { Path } from "../../../routes/route-config";
import { API } from "../../../services/api-config";
import { KSMailIcon } from "../../../icons/custome-icon";
import { TimeStampToMonthDateYear } from "../../../helpers/time-formater";
import { CustomSwitch } from "../../../helpers/custom-switch";
import { kstheme } from "../../../theme/theme";
import { openMail } from "../../../helpers/common_fuctions/open-mail";

export default function Leads() {
  const router = useNavigate();

  const isTab = useMediaQuery(kstheme.breakpoints.down("md"));

  let assignSwitch;

  const handleForm = ({ state }) => {
    assignSwitch = state;
  };
  const handleClick = ({ row }) => {
    console.log("assignSwitch", assignSwitch);
    if (assignSwitch == undefined) {
      if (row?.original?.requestedInfo == "Pre-approval") {
        assignSwitch = false;
      } else {
        assignSwitch = true;
      }
    }
    assignSwitch
      ? handleQuoteForm(row.original.id)
      : handlePreapprovalForm(row.original.id);
  };

  const handlePreapprovalForm = (id) => {
    router(Path.preapprovalForm + "/" + id);
  };

  const handleQuoteForm = (id) => {
    router(Path.quoteForm + "/" + id);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Lead ID",
        enableColumnActions: false,
        enableSorting: false,
        size: 0,
      },
      //column definitions...
      {
        accessorKey: "info",
        header: "LEAD INFO",
        enableColumnActions: false,
        enableSorting: false,
        size: 0,
        Cell: ({ row }) => (
          <>
            <Typography>
              {`${row.original.leadInfo.firstName} ${row.original.leadInfo.lastName}`}
            </Typography>
            <Typography>{`${row.original.leadInfo.phone}`}</Typography>
            <Typography>{`${row.original.leadInfo.email}`}</Typography>
          </>
        ),
      },
      {
        accessorKey: "mloName",
        header: "MLO",
        enableColumnActions: false,
        enableSorting: false,
        size: 0,
      },
      {
        accessorKey: "propertyType",
        header: "Property Type",
        enableColumnActions: false,
        enableSorting: false,
        size: 0,
      },
      {
        accessorKey: "requestedInfo",
        header: "Requested info",
        enableColumnActions: false,
        enableSorting: false,
        size: 0,
      },
      {
        accessorKey: "id",
        header: "Action",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          const [test, setTest] = useState();
          return row?.original?.requestedInfo == "Contact Me" ? (
            <Box display={"flex"} justifyContent={"center"}>
              <IconButton onClick={(e) => {
                openMail(row.original.leadInfo.email);
              }}>
                <KSMailIcon />
              </IconButton>
            </Box>
          ) : (
            <Box display={"flex"} justifyContent={"center"}>
              <Box
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                  <Typography sx={{ minWidth: "max-content" }}>
                    Pre-approval
                  </Typography>
                  <CustomSwitch
                    disabled={
                      row?.original?.requestedInfo == "Pre-approval" ||
                        row?.original?.requestedInfo == "Quote"
                        ? true
                        : false
                    }
                    defaultValue={
                      row?.original?.requestedInfo == "Pre-approval"
                        ? false
                        : true
                    }
                    callBackForSwitch={handleForm}
                  />
                  <Typography>Quote</Typography>
                </Stack>
                <Box>
                  <Button
                    key={cell.getValue()}
                    size="small"
                    variant="outlined"
                    endIcon={<SendOutlinedIcon />}
                    onClick={(event) => {
                      handleClick(cell, row);
                    }}
                  >
                    Generate
                  </Button>
                </Box>
                <Typography
                  sx={{ color: "#999999", fontWeight: "400", fontSize: "10px" }}
                >
                  {row?.original?.lastSentDate
                    ? `Last sent on ` +
                    TimeStampToMonthDateYear(row?.original?.lastSentDate)
                    : ""}{" "}
                </Typography>
              </Box>
            </Box>
          );
        },
      },
      //end
    ],
    []
  );

  let data = [
    {
      id: "1",
      leadInfo: "sal",
      mloName: "Mlo Name",
      propertyType: "ok",
      requestType: "Quote",
    },
    {
      id: "2",
      leadInfo: "sal",
      mloName: "Mlo Name2",
      propertyType: "ok",
      requestType: "Pre-approval",
    },
    {
      id: "3",
      leadInfo: "sal",
      mloName: "Mlo Name3",
      propertyType: "ok",
      requestType: "Quote & Pre-approval",
    },
  ];

  return (
    <MainLoyOut>
      <CustomReactMaterialTable
        // staticLoad={true}
        // staticData={data}
        enableToolbarInternalActions={false}
        enablePagination={false}
        enableColumnFilters={false}
        endPoint={API.leads}
        columns={columns}
        showGlobalFilter={true}
        muiSearchTextFieldProps={{
          placeholder: isTab ? 'Search' : "Search for Loan Contacts",
          variant: "outlined",
        }}
      />
    </MainLoyOut>
  );
}
