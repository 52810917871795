import React from 'react'
import { EditOutlined, ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Stack, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';

import { useRecoilValue } from 'recoil';
import { profileState } from '../../../../../recoil/profile-atom';
import { NEW_KEYS, REMOVE_KEYS, ROLE_LIST } from '../../../../../constants/constatnt-variable';

export default function ReviewSection({
    sectionInfo,
    sectionName,
    chidSectionName,
    hasPartner,
    editUrl,
    isLocked,
}) {

    const location = useLocation();
    const route = useNavigate();
    let profileInfo = useRecoilValue(profileState);

    const goToSelectPage = () => {
        route(location.pathname.replace(/[^/]*$/, editUrl))
    }

    const removeKeys = (obj) => {
        return _.omit(obj, REMOVE_KEYS);
    };

    return (
        <Box mb={1}>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant='h3'>{sectionName}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant='h5' color='primary' mb={2}>{hasPartner}</Typography>

                    {
                        sectionInfo.map((data, j) =>
                            <Box key={j}>
                                <Stack direction={'row'} justifyContent={'space-between'} mb={1}>
                                    <Typography variant='h5' color='primary'>{chidSectionName ? chidSectionName : data['chidSectionName']}</Typography>
                                    <Button variant='outlined' disabled={profileInfo.role == ROLE_LIST.borrower ? isLocked : false} onClick={goToSelectPage} startIcon={<EditOutlined />}>{`Edit`}</Button>
                                </Stack>
                                <Grid container>

                                    {
                                        Object.keys(removeKeys(data)).map(
                                            (key, i) => {
                                                return (
                                                    <Grid key={i} item xs={12} sm={6} md={4} xl={3} p={2}>
                                                        <Typography variant='body1' color={'accent.A5'}>
                                                            {NEW_KEYS[key] || key}
                                                        </Typography>
                                                        <Typography variant='body1'>
                                                            {data[key]}
                                                        </Typography>
                                                    </Grid>
                                                );
                                            }
                                        )

                                    }
                                </Grid>
                            </Box>
                        )
                    }
                </AccordionDetails>
            </Accordion>
        </Box>

    )
}
