import { Box, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { TEAM_TABS } from "../../../constants/constatnt-variable";
import Manage from "./manage";
import ReassignMLO from "./reassign-mlo";
import ReassignMLP from "./reassign-mlp";
import { getApiCallDynamic } from "../../../services/api-service";
import { API } from "../../../services/api-config";

export default function Team() {
  const [value, setValue] = useState(() => {
    return TEAM_TABS.Manage;
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [MloList, setMloList] = useState([]);
  const [MlpList, setMlpList] = useState([]);

  useEffect(() => {
    getApiCallDynamic({ path: API.teams, param: { role: "mlo" } }).then(
      (res) => {
        if (res?.status === 200) {
          setMloList(res.data.data);
        }
      }
    );
    getApiCallDynamic({ path: API.teams, param: { role: "mlp" } }).then(
      (res) => {
        if (res?.status === 200) {
          setMlpList(res.data.data);
        }
      }
    );
  }, [value]);

  return (
    <Box>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            variant="scrollable"
            allowScrollButtonsMobile
          >
            <Tab label="Manage" value={TEAM_TABS.Manage} />
            <Tab label="Reassign MLO" value={TEAM_TABS.ReassignMLO} />
            <Tab label="Reassign MLP" value={TEAM_TABS.ReassignMLP} />
          </TabList>
        </Box>
        <TabPanel value={TEAM_TABS.Manage} sx={{ px: { xs: 0, md: 3 } }}>
          <Manage />
        </TabPanel>
        <TabPanel value={TEAM_TABS.ReassignMLO} sx={{ px: { xs: 0, md: 3 } }}>
          <ReassignMLO MloList={MloList} />
        </TabPanel>
        <TabPanel value={TEAM_TABS.ReassignMLP} sx={{ px: { xs: 0, md: 3 } }}>
          <ReassignMLP MlpList={MlpList} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
