import React, { useState } from "react";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  UPDATE,
} from "../../../../constants/constatnt-variable";
import {
  KSCloudDownloadIcon,
  KSDocumentSearchIcon,
} from "../../../../icons/custome-icon";
import CustomInput from "../../../../helpers/custom-input";
import { API } from "../../../../services/api-config";
import { postApiCallDynamicWithOutReturn } from "../../../../services/api-service";
import { ResloveBrokerRules } from "../../../../constants/validation-rules";
import { downloadReportFromServer } from "../../../../helpers/file-upload-download";

export default function LoanSummaryTaskResolveBrokerForm({
  formId,
  taskResolveData,
  handleClose,
  taskReload,
}) {
  const [taskStatus, setTaskStatus] = useState("");

  // For Changing Task Status Start

  const handleReslove = () => {
    setTaskStatus("Resolved");
  };

  const handleRejected = () => {
    setTaskStatus("Rejected");
  };
  // For Changing Task Status End


  const downloadBorrowerDocument = () => {
    downloadReportFromServer(API.proxyDocumentDownload, {
      id: taskResolveData?.borrowerDocId,
      serviceType: "task",
    });
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: taskResolveData.id,
      formId: formId,
      borrowerNote: taskResolveData.borrowerNote,
      taskType: taskResolveData?.taskType,
    },
  });

  const resloveOnSubmit = async (data) => {
    data = Object.assign(data, {
      statusInfo: taskStatus,
      formId: formId,
      id: data?.id,
    });

    await postApiCallDynamicWithOutReturn({
      data: data,
      path: API.taskStatus,
      header: taskResolveData ? UPDATE : "",
      refresh: taskReload,
    });

    handleClose();
  };
  return (
    <>
      <Typography variant="h6">{`Task Description`}</Typography>
      <Typography color={"A7.main"} mb={2}>
        {taskResolveData.description}
      </Typography>
      <Typography variant="h6">{`Is Lender's Condition`}</Typography>
      <Typography color={"A7.main"} mb={2}>
        {taskResolveData.isLender ? "Yes" : "No"}
      </Typography>
      <Typography variant="h6">{`Borrower's Note`}</Typography>
      <Typography color={"A7.main"} mb={2}>
        {taskResolveData.borrowerNote
          ? taskResolveData.borrowerNote
          : "Not available."}
      </Typography>
      {taskResolveData.taskType == "Upload" ? (
        <>
          <Stack direction={"row"} gap={1} alignItems={"center"} my={2}>
            <Typography variant="h6">{`Uploaded Document`}</Typography>
            <IconButton>
              <KSDocumentSearchIcon />
            </IconButton>
            <IconButton onClick={downloadBorrowerDocument}>
              <KSCloudDownloadIcon />
            </IconButton>
          </Stack>
        </>
      ) : null}

      {taskResolveData.taskType == "Download & upload" ? (
        <>
          <Stack direction={"row"} gap={1} alignItems={"center"} my={2}>
            <Typography variant="h6">{`Original Document`}</Typography>
            <IconButton>
              <KSDocumentSearchIcon />
            </IconButton>
          </Stack>
          <Stack direction={"row"} gap={1} alignItems={"center"} my={2}>
            <Typography variant="h6">{`Signed Document`}</Typography>
            <IconButton>
              <KSDocumentSearchIcon />
            </IconButton>
            <IconButton onClick={downloadBorrowerDocument}>
              <KSCloudDownloadIcon />
            </IconButton>
          </Stack>
        </>
      ) : null}

      <form onSubmit={handleSubmit(resloveOnSubmit)}>
        <Controller
          name="ownerNote"
          control={control}
          rules={ResloveBrokerRules.ownerNote}
          render={({ field }) => (
            <CustomInput
              {...field}
              field={field}
              required
              type="text"
              label={"Note"}
              error={errors?.ownerNote?.message}
            />
          )}
        />

        <Stack direction={{ xs: "column", md: "row-reverse" }} gap={2}>
          <Button type="submit" variant="contained" onClick={handleReslove}>
            {`Resolve`}
          </Button>
          <Button type="submit" variant="text" onClick={handleRejected}>
            {`Reject`}
          </Button>
        </Stack>
      </form>
    </>
  );
}
