import {Box, Button, Grid, Typography} from "@mui/material";
import React from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {toastNotify} from "../../../helpers/alert-msg";
import {globalSpinner, profileState, webInfoState,} from "../../../recoil/profile-atom";
import {API} from "../../../services/api-config";
import {getApiCallDynamic} from "../../../services/api-service";

function Publish(props) {
  let [webInfo, setwebInfo] = useRecoilState(webInfoState);

  let [spinner, setSpinner] = useRecoilState(globalSpinner);

  let profileInfo = useRecoilValue(profileState);
  let param = { userCode: profileInfo.userCode };
  const websiteInfoFetch = () => {
    getApiCallDynamic({ param: param, path: API.webInfo }).then((res) => {
      if (res?.status === 200) {
        setwebInfo(res.data);
      }
    });
  };

  const publishWebsite = (e) => {
    setSpinner(true);

    getApiCallDynamic({ param: param, path: API.webPublish }).then((res) => {
      if (res?.status === 200) {
        websiteInfoFetch();
        setwebInfo(res.data);
        toastNotify("success", res?.data?.message);
      }
      setSpinner(false);
    });
  };
  return (
    <>
      <Grid container>
        <Grid item md={5} sm={12} xs={12} my={1}>
          <Typography variant="h3">Website link</Typography>
        </Grid>
        <Grid item md={7} sm={12} xs={12} my={1} overflow={'auto'}>
          <Box
            display={{ md: "flex", xs: "unset" }}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={{ md: "flex-end", xs: "flex-start" }}
          >
            <Box mr={2}>
              <Typography
                href={webInfo?.profileLink}
                target="_blank"
                variant="body2"
                component={"a"}
              >
                {webInfo?.profileLink}
              </Typography>
            </Box>
            <Box my={{ md: "unset", xs: "16px" }}>
              <Button
                // disabled={webInfo?.published}
                variant="contained"
                onClick={publishWebsite}
              >
                Publish
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Publish;
