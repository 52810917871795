import { createTheme } from "@mui/material";

let theme = createTheme({
  palette: {
    common: {
      main: "#000000",
      light: "#FFFFFF",
    },

    primary: {
      main: "#2f80ed",
    },

    secondary: {
      main: "#000435",
    },

    success: {
      main: "#30B085",
      // light: "#30B08515",
      // dark: "#00CB14",
    },

    warning: {
      main: "#FFB210",
      // light: "#FFB21015",
    },

    info: {
      main: "#438BED",
      // light: "#438BED15",
    },

    error: {
      main: "#D94166",
      // light: "#D9416615",
    },
    A1: { main: "#2F80ED30" },
    A8: { main: "#DBDBDB" },
    A7: { main: "#22222260" },
    A6: { main: "#22222275" },
    A5: { main: "#00000075" },
    A4: { main: "#F97B34" },

    warninglight: { main: "#FFB21020" },
    primarylight: { main: "#2F80ED35" },

    accent: {
      A1: "#2F80ED30",
      A2: "#F5F9FA",
      A3: "#FAFAFA",
      A4: "#F97B34",
      A5: "#00000075",
      A6: "#22222275",
      A7: "#22222260",
      A8: "#DBDBDB",
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "DMSans-Regular",
          paddingBottom: "5px",
          fontSize: "14px",
        },
      },
    },

    MuiAvatar: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: "max-content",
          borderRadius: "50px",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: "Inter-Bold",
          fontSize: "12px",
          letterSpacing: "0rem",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: { fontFamily: "DMSans-Regular", borderRadius: "8px" },
        input: {
          padding: "16px 14px",
          fontSize: "16px",
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: "#2F80ED",
          color: "white",
        },
      },
    },

    MuiTooltip: {
      defaultProps: {
        enterTouchDelay: 0,
      },
      styleOverrides: {
        tooltip: {
          color: "#657285",
          backgroundColor: "#E3F1FF",
          margin: '2px !important'
        },
        arrow: {
          color: "#E3F1FF",
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#C4C4C440",
            width: "10px",
            borderRadius: 100,
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 100,
            backgroundColor: "#C4C4C4",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
          {
            backgroundColor: "#8b8b8b",
          },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
          {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
          {
            backgroundColor: "#959595",
          },
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    },

    // Mui Stepper custom color of icon and line Start
    // MuiStepIcon: {
    //   styleOverrides: {
    //     root: {
    //       "&.Mui-completed.Mui-active": {
    //         color: "#47F158",
    //       },
    //       "&.Mui-active": {
    //         color: "#2f80ed",
    //       },
    //     },
    //   },
    // },

    MuiStepLabel: {
      styleOverrides: {
        iconContainer: {
          color: "#00000030",
          "&.Mui-completed.Mui-active, &.Mui-completed, &.Mui-completed.Mui-active.Mui-disabled":
          {
            color: "#47F158",
          },
          "&.Mui-active, &.Mui-active.Mui-disabled": {
            color: "#2f80ed",
          },

          "&.Mui-disabled": {
            color: "#00000030",
          },
        },
      },
    },

    MuiStep: {
      styleOverrides: {
        root: {
          "&.Mui-completed .MuiStepContent-root": {
            borderLeft: "2px solid #47F158",
          },
          "& .MuiStepContent-root": {
            borderLeft: "2px solid #00000030",
          },
        },
      },
    },

    MuiStepConnector: {
      styleOverrides: {
        root: {
          "&.Mui-active.Mui-disabled .MuiStepConnector-line": {
            borderLeft: "2px solid #47F158",
          },

          "&.Mui-disabled .MuiStepConnector-line": {
            borderLeft: "2px solid #00000030",
          },
        },
      },
    },
    // Mui Stepper custom color of icon and line End
  },

  typography: {
    fontFamily: "DMSans-Regular",

    button: {
      fontFamily: "DMSans-Bold",
      fontSize: "18px",
      letterSpacing: " .1rem ",
      textTransform: "capitalize",
    },

    h1: {
      fontSize: "44px",
      fontFamily: "Inter-Bold",
    },
    h2: {
      fontSize: "32px",
      fontFamily: "Inter-Bold",
    },
    h3: {
      fontSize: "24px",
      fontFamily: "Inter-SemiBold",
    },
    h4: {
      fontSize: "20px",
      fontFamily: "Inter-SemiBold",
    },
    h5: {
      fontSize: "18px",
      fontFamily: "Inter-Medium",
    },
    h6: {
      fontSize: "16px",
      fontFamily: "Inter-Medium",
    },
    body1: {
      fontSize: "16px",
    },
    body2: {
      fontSize: "14px",
    },
    body3: {
      fontSize: "18px",
    },
    overline: {
      fontSize: "12px",
    },
    caption: {
      fontSize: "10px",
    },
  },
});

theme = {
  ...theme,
  typography: {
    ...theme.typography,
    h1: {
      ...theme.typography.h1,
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "36px",
      },
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "28px",
      },
    },
    h2: {
      ...theme.typography.h2,
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "27px",
      },
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "22px",
      },
    },
    h3: {
      ...theme.typography.h3,
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "21px",
      },
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "18px",
      },
    },
    h4: {
      ...theme.typography.h4,
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "16px",
      },
    },
    h5: {
      ...theme.typography.h5,
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "15px",
      },
    },
    h6: {
      ...theme.typography.h6,
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "14px",
      },
    },
    body1: {
      ...theme.typography.body1,
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "14px",
      },
    },
    body2: {
      ...theme.typography.body2,
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "12px",
      },
    },
    body3: {
      ...theme.typography.body3,
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "16px",
      },
    },
    disable: {
      ...theme.typography.disable,
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "8px",
      },
    },

    button: {
      ...theme.typography.button,
      [theme.breakpoints.between("sm", "md")]: {
        fontFamily: "DMSans-Bold",
        fontSize: "16px",
        letterSpacing: " .1rem ",
        textTransform: "capitalize",
      },
      [theme.breakpoints.between("xs", "sm")]: {
        fontFamily: "DMSans-regular",
        fontSize: "14px",
        letterSpacing: "0rem ",
        textTransform: "capitalize",
      },
    },
  },
};

export const kstheme = theme;
