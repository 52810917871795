import React from "react";

import { Button, InputAdornment, Stack, } from "@mui/material";
import Grid from "@mui/material/Grid";
import CustomInput from "../../../helpers/custom-input";

import { TEAM_INVITATION_ROLE } from "../../../constants/constatnt-variable";
import CustomDropDown from '../../../helpers/dropdown/custom-dropdown'
import { KSMailIcon, KSPhoneIcon } from "../../../icons/custome-icon";
import { Controller, useForm } from "react-hook-form";
import { TeamInvitationValidation } from "../../../constants/validation-rules";

export default function TeamInvitationForm({ onSubmit, data, handleClose }) {

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      id: data?.id,
      role: data?.role,
      firstName: data?.firstName,
      lastName: data?.lastName,
      phone: data?.phone,
      email: data?.email,
      invitationMessage: data?.message,

    }
  })

  return (

    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
        <Controller
          name='role'
          control={control}
          rules={TeamInvitationValidation.role}
          render={({ field }) => (
            <CustomDropDown
              {...field}
              field={field}
              label={'Role'}
              required
              option={TEAM_INVITATION_ROLE}
              error={errors?.role?.message}
            />
          )}
        />
      </Grid>
      <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
        <Controller
          name='firstName'
          control={control}
          rules={TeamInvitationValidation.firstName}
          render={({ field }) => (
            <CustomInput
              {...field}
              label={"First Name"}
              required
              size="small"
              fullWidth
              placeholder="Enter the name of the person "
              error={errors?.firstName?.message}
            />
          )}
        />
      </Grid>

      <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
        <Controller
          name='lastName'
          control={control}
          rules={TeamInvitationValidation.lastName}
          render={({ field }) => (
            <CustomInput
              {...field}
              label={"Last Name"}
              required
              size="small"
              fullWidth
              placeholder="Enter the name of the person "
              error={errors?.lastName?.message}
            />
          )}
        />
      </Grid>
      <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
        <Controller
          name='phone'
          control={control}
          rules={TeamInvitationValidation.phone}
          render={({ field }) => (
            <CustomInput
              {...field}
              label={"Phone Number"}
              required
              size="small"
              fullWidth
              placeholder="(655)349-4367"
              sicon={
                <InputAdornment position="start">
                  <KSPhoneIcon />
                </InputAdornment>
              }
              type={"number"}
              error={errors?.phone?.message}
            />
          )}
        />
      </Grid>
      <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
        <Controller
          name='email'
          control={control}
          rules={TeamInvitationValidation.email}
          render={({ field }) => (
            <CustomInput
              {...field}
              label={"Email Id"}
              required
              sicon={<KSMailIcon />}
              size="small"
              disabled={data}
              fullWidth
              placeholder="Enter recipent Email Id"
              error={errors?.email?.message}
            />
          )}
        />
      </Grid>
      <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
        <Controller
          name='invitationMessage'
          control={control}
          rules={TeamInvitationValidation.invitationMessage}
          render={({ field }) => (
            <CustomInput
              {...field}
              label={"What can we help you with?"}
              required
              size="large"
              fullWidth
              placeholder="Hello Peter John, we are Inviting you  to apply
            for loan through cliqloan is easier"
              multiline
              rows={5}
              rowsMax={7}
              error={errors?.invitationMessage?.message}
            />
          )}
        />
      </Grid>

      <Stack direction={'row'} justifyContent={'end'} gap={2}>
        <Button onClick={handleClose} variant="outlined">
          {'Cancel'}
        </Button>
        <Button type="submit" variant="contained">
          {data ? "Update" : "Send"}
        </Button>
      </Stack>
    </form>
  );
}
