// noinspection EqualityComparisonWithCoercionJS

import axios from "axios";
import { AlertError, ServerSuccessMsg, SuccessMsg } from "../helpers/alert-msg";
import { UPDATE } from "../constants/constatnt-variable";

// if api call is successfull then refresh function will be called
export function postApiCallDynamic({ data = {}, refresh = () => {
  // Nothing

}, path = "", header = "" }) {
  if (header === UPDATE) {
    return axios
      .put(path, data, {
        withCredentials: true,
        credentials: 'include',

        headers: { "XTR-API-Action": header }
      })
      .then((res) => {
        if (
          res &&
          (res.status === 200 ||
            res.status === 201 ||
            res.status === 202 ||
            res.status === 203 ||
            res.status === 204)
        ) {
          // SuccessMsg(res.status);
          return refresh({ ...res, requestData: data });
        }
      })
      .catch((error) => AlertError(error));
  }
  return axios
    .post(path, data, {
      withCredentials: true,
      credentials: 'include',
      headers: { "XTR-API-Action": header }
    })
    .then((res) => {
      if (
        res &&
        (res.status === 200 ||
          res.status === 201 ||
          res.status === 202 ||
          res.status === 203 ||
          res.status === 204)
      ) {
        // SuccessMsg(res.status);
        return refresh({ ...res, requestData: data });
      }
    })
    .catch((error) => AlertError(error));
}
export function getApiCallDynamic({ path = "", param = {}, serverMsg = false }) {
  return axios
    .get(path, {
      withCredentials: true,
      credentials: 'include',

      params: param, headers: { "XTR-API-Action": "read" }
    })
    .then(res => {

      serverMsg && ServerSuccessMsg(res)

      return res;
    })
    .catch((error) => AlertError(error));
}

export function postApiCallDynamicWithOutReturn(
  {
    data = {},
    refresh = () => {
      // Nothing

    },
    path = "",
    header = ""
  }
) {
  if (header === UPDATE) {
    return axios
      .put(path, data, {
        withCredentials: true,
        credentials: 'include',

        headers: { "XTR-API-Action": header }
      })
      .then((res) => {
        if (
          res &&
          (res.status === 200 ||
            res.status === 201 ||
            res.status === 202 ||
            res.status === 203 ||
            res.status === 204)
        ) {
          SuccessMsg(res.status);
          refresh();
        }
      })
      .catch((error) => AlertError(error));
  }
  return axios
    .post(path, data, {
      withCredentials: true,
      credentials: 'include',

      headers: { "XTR-API-Action": header }
    })
    .then((res) => {
      if (
        res &&
        (res.status === 200 ||
          res.status === 201 ||
          res.status === 202 ||
          res.status === 203 ||
          res.status === 204)
      ) {
        SuccessMsg(res.status);
        refresh();
      }
    })
    .catch((error) => AlertError(error));
}

export function deleteApiCallDynamic({ path = "", param = {}, data = {}, refresh = () => {
  // Nothing

} }) {
  return axios
    .delete(path, {
      withCredentials: true,
      credentials: 'include',
      params: param,
      data: data,
    })
    .then((res) => {
      if (
        res &&
        (res.status === 200 ||
          res.status === 201 ||
          res.status === 202 ||
          res.status === 203 ||
          res.status === 204)
      ) {
        SuccessMsg(res.status);
        return refresh({ ...res, requestData: data });
      }
    })
    .catch((error) => AlertError(error));
}


export function uploadFile({ docId, formId, file, serviceType, path }) {
  let formData = new FormData();

  formData.append("file", file);
  serviceType && formData.append("serviceType", serviceType);
  docId && formData.append("docId", docId);
  formId && formData.append("formId", formId);

  return axios.post(path, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}