import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormHelperText,
    IconButton,
    Link as MuLink,
    Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

export default function TermCondition({
  open,
  handleClose,
  setTermCondtion,
  termConditon,
  handleClickOpen,
  formik,
}) {
  return (
    <>
      <Typography>
        <Checkbox
          style={{ paddingLeft: 0 }}
          sx={
            formik.errors.termConditon
              ? {
                color: "error.main",
              }
              : {}
          }
          checked={termConditon}
          onChange={(e) => {
            setTermCondtion((prev) => !prev);
          }}
        />
        <span>{`By signing up, I accept Kramasoft's`}</span>
        <MuLink
          sx={{ textDecoration: "none", cursor: "pointer" }}
          onClick={handleClickOpen}
        >
          {`Terms & Conditions`}
        </MuLink>
      </Typography>

      <FormHelperText sx={{ color: "error.main", ml: "2" }}>
        {formik.errors.termConditon}
      </FormHelperText>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            backgroundColor: "#2F80ED",
            color: "white",
            fontWeight: "bold",
          }}
        >
          {"Terms & Conditions"}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ mt: "20px" }}>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.Let
            Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.Let Google
            help apps determine location. This means sending anonymous location
            data to Google, even when no apps are running.Let Google help apps
            determine location. This means sending anonymous location data to
            Google, even when no apps are running.Let Google help apps determine
            location. This means sending anonymous location data to Google, even
            when no apps are running.Let Google help apps determine location.
            This means sending anonymous location data to Google, even when no
            apps are running.Let Google help apps determine location. This means
            sending anonymous location data to Google, even when no apps are
            running.Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.Let
            Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.Let Google
            help apps determine location. This means sending anonymous location
            data to Google, even when no apps are running.Let Google help apps
            determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ pb: 2, pt: 2 }}>
          <Button
            onClick={() => {
              setTermCondtion(false);
              handleClose();
            }}
            sx={{ borderRadius: "24px" }}
          >
            Decline
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setTermCondtion(true);
              handleClose();
            }}
            sx={{ borderRadius: "24px", fontWeight: "bold", mr: 2 }}
          >
            I Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
