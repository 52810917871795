import React, { useMemo, useState } from "react";

import { createTheme, responsiveFontSizes, styled } from "@mui/material/styles";
import { IconButton, Stack, Tooltip, useMediaQuery, } from "@mui/material";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";

import "../../../styles/admin.css";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import AddIcon from "@mui/icons-material/Add";

import InvitationForm from "./invitation-form";
import { DialogCommonHooks } from "../../../helpers/modal/modal";
import DialogSuccesMsg from "../../../helpers/modal/dialog-succes-msg";
import MainLoyOut from "../../../components/main-layout";
import CustomReactMaterialTable from "../../../helpers/table/react-material-table";
import { API } from "../../../services/api-config";
import { deleteApiCallDynamic, getApiCallDynamic } from "../../../services/api-service";
import { ClientSideMsg } from "../../../helpers/alert-msg";
import ConfirmationModel from "../../../helpers/modal/confirm-modal";
import { KSMailIcon, KSPencilAltIcon, KSTrashIcon } from "../../../icons/custome-icon";
import { useRecoilValue } from "recoil";
import { profileState } from "../../../recoil/profile-atom";
import { ROLE_LIST, SERVICE_TYPE } from "../../../constants/constatnt-variable";
import { MRT_GlobalFilterTextField } from "material-react-table";
import { kstheme } from "../../../theme/theme";

//white space for main content area

//for color and responsive font
let theme = createTheme();
theme = responsiveFontSizes(theme);

//menu bar onclick drawer open

//drawer and modal open and close state
export default function InvitationPage(props) {
  let profileInfo = useRecoilValue(profileState);
  const [openModal, setModalOpen] = useState(false);
  const [openSucess, setSucessModalOpen] = useState(false);

  const [edit, setEdit] = useState(false);

  const [data, setData] = useState(false);

  //pagination

  const isTab = useMediaQuery(kstheme.breakpoints.down("md"));


  //new invitation modal open
  const handleClickOpen = () => {
    setModalOpen(true);
  };

  //invitation sucess modal open
  const handleSucessOpen = () => {
    setreload(prev => !prev)
    setModalOpen(false);
    setSucessModalOpen(true);
  };

  //all modal close
  const handleClose = () => {
    setModalOpen(false);
    setSucessModalOpen(false);
    setreload(prev => !prev)
  };

  //role dropdown event


  //table design
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  //main html body

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",

        header: "ID",
        enableColumnActions: false,
      },
      //column definitions...
      {
        accessorKey: "borrowerInfo",
        header: "Borrower Info",
        enableColumnActions: false,
        size: 150,
        Cell: ({ cell, row }) => (
          <>
            <Typography>
              {`${row.original["firstName"]} ${row.original["lastName"]}`}
            </Typography>
            <Typography py={1}>{`${row.original["email"]}`}</Typography>
            <Typography>{`${row.original["phone"]}`}</Typography>
          </>
        ),
      },
      {
        accessorKey: "mloName",
        header: "MLO",
        enableColumnActions: false,
      },
      {
        accessorKey: "status",
        header: "Invitation Status",
        enableColumnActions: false,
      },

      //end
    ],
    []
  );
  const handleEmail = (id) => {
    getApiCallDynamic({ param: { id: id, service: SERVICE_TYPE.invitation }, path: API.loanInvitaionEmail, serverMsg: true })
  }
  const handleDelete = (id) => {
    deleteApiCallDynamic({ path: API.loanInvitaion, data: { id: id } });
    setreload(prev => !prev)
  };
  const [reload, setreload] = useState(false)

  return (
    <>
      {/* body area start here */}
      <MainLoyOut>
        {/* search, filter, new invitation area start here */}

        {/* data table area start here */}
        <CustomReactMaterialTable
          enableToolbarInternalActions={false}


          enablePagination={false}
          enableColumnFilters={false}
          refetch={reload}
          endPoint={API.loanInvitaion}
          columns={columns}
          showGlobalFilter={true}
          enableGlobalFilter={false}
          muiSearchTextFieldProps={{
            placeholder: isTab ? 'Search' : "Search for application number, MLO",
            variant: "outlined",
            sx: {
              width: isTab ? null : '25rem',
            }
          }}
          renderTopToolbarCustomActions={({ table }) => (
            <Stack justifyContent={'space-between'} direction={{ xs: 'column', sm: 'row' }} width={'100%'} alignItems={{ xs: 'initial', sm: 'center' }} gap={2}>
              <Box>
                <Button
                  sx={{ display: profileInfo.role == ROLE_LIST.mlp ? "none" : null }}
                  variant="outlined"
                  onClick={() => {
                    handleClickOpen();
                    setEdit(false);
                  }}
                >
                  <AddIcon /> New Invite
                </Button>
              </Box>
              <MRT_GlobalFilterTextField table={table} />
            </Stack>
          )}
          enableRowActions={true}
          renderRowActions={({ cell, row, table }) => {
            return (
              <Box display="flex" direction="row" justifyContent="start">
                <Tooltip title={'Edit Invitation'} arrow>
                  <IconButton onClick={() => {
                    handleClickOpen();
                    setEdit(true);
                    setData(row?.original);
                  }}>
                    <KSPencilAltIcon />
                  </IconButton>
                </Tooltip>
                <ConfirmationModel
                  body={
                    <Tooltip title={'Send Invitation'} arrow>
                      <IconButton>
                        <KSMailIcon />
                      </IconButton>
                    </Tooltip>
                  }
                  handleSubmit={() => {
                    handleEmail(row?.original?.id);
                  }}
                  message={
                    `Are you sure you want to send the invitation link ${row?.original?.firstName} ${row?.original?.lastName}   for Borrower role?`
                  }
                  title={"Confirmation"}
                />


                <ConfirmationModel
                  body={
                    <Tooltip title={'Delete'} arrow>
                      <IconButton>
                        <KSTrashIcon />
                      </IconButton>
                    </Tooltip>
                  }
                  handleSubmit={() => {
                    handleDelete(row?.original?.id);
                  }}
                  message={
                    `Are you sure you want to delete the invitation link sent to ${row?.original?.firstName} ${row?.original?.lastName}   for Borrower role?`
                  }
                  title={"Confirmation"}
                />

              </Box>
            );
          }}
        />
      </MainLoyOut>

      {/*new invitation modal */}
      <DialogCommonHooks
        title={edit ? "Edit Invitation" : "New Invitation"}
        show={openModal}
        handleClose={handleClose}
        body={
          <InvitationForm
            handleSubmit={handleSucessOpen}
            handleClose={handleClose}
            update={edit}
            data={data}
          />
        }
      />

      {/* <DialogCommonHooks
        title="New Invitation"
        show={openModal}
        handleClose={handleClose}
        body={
          <InvitationForm
            handleSubmit={handleSucessOpen}
            handleClose={handleClose}
          />
        }
      /> */}

      {/* invitation ok modal */}
      <DialogCommonHooks
        show={openSucess}
        handleClose={handleClose}
        body={
          <DialogSuccesMsg
            description={edit ? "Thank You! Email reminder has been sent" : "Invitation has been sent"}
            handleSubmit={handleSucessOpen}
            handleClose={handleClose}
          />
        }
      />
    </>
  );
}
