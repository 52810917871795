import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import CustomInput from "../../../../helpers/custom-input";
import { AttachMoney, Percent } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import {
  APPRAISAL_STATUS_VALUE,
  APPRAISAL_TYPE_VALUE,
  LOAN_TERM_VALUE,
  LOCK_STATUS_VALUE,
  UPDATE,
} from "../../../../constants/constatnt-variable";
import SingleSelectDynamicValueDropdown from "../../../../helpers/dropdown/single-select-dropdown";
import RadioButtonGroup from "../../../../helpers/radio-button-group";
import {
  DateToTimestapmFormater,
  TimeStampToMonthDateYearForInput,
} from "../../../../helpers/time-formater";
import { postApiCallDynamicWithOutReturn } from "../../../../services/api-service";
import { API } from "../../../../services/api-config";
import { loanSummaryStatisticsValidation } from "../../../../constants/validation-rules";

export default function LoanSummaryLoanStatisticsForm({
  handleClose,
  statisticsData,
  statisticsReloader,
}) {


  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      loanCode: statisticsData?.loanCode,
      loanAmount: statisticsData?.loanAmount,
      loanTerm: statisticsData?.loanTerm,
      interestRate: statisticsData?.interestRate,
      empAmount: statisticsData?.empAmount,
      emeAmount: statisticsData?.emeAmount,
      lockStatus: statisticsData?.lockStatus,
      lockExpirationDate: TimeStampToMonthDateYearForInput(
        statisticsData?.lockExpirationDate
      ),
      loanType: statisticsData?.loanType,
      appraisalStatus: statisticsData?.appraisalStatus,
    },
  });

  const formOnSubmit = async (data) => {
    data = Object.assign(data, {
      lockExpirationDate: DateToTimestapmFormater(data.lockExpirationDate),
    });

    await postApiCallDynamicWithOutReturn({
      data: data,
      path: API.loanSummaryStatistics,
      header: statisticsData ? UPDATE : "",
      refresh: statisticsReloader,
    });
    handleClose();
  };

  return (
    <>
      <Typography variant="h3" color={"A7.main"} textAlign={"center"} mb={2}>
        {`Loan Number: ${statisticsData.loanCode}`}
      </Typography>

      <form onSubmit={handleSubmit(formOnSubmit)}>
        <Controller
          name="loanTerm"
          control={control}
          rules={loanSummaryStatisticsValidation.loanTerm}
          render={({ field }) => (
            <SingleSelectDynamicValueDropdown
              {...field}
              field={field}
              required
              label={"Loan Term"}
              options={LOAN_TERM_VALUE}
              error={errors?.loanTerm?.message}
            />
          )}
        />

        <Controller
          name="loanAmount"
          control={control}
          rules={loanSummaryStatisticsValidation.loanAmount}
          render={({ field }) => (
            <CustomInput
              {...field}
              field={field}
              required
              type={"number"}
              label={"Loan Amount"}
              sicon={<AttachMoney />}
              error={errors?.loanAmount?.message}
            />
          )}
        />

        <Controller
          name="interestRate"
          control={control}
          rules={loanSummaryStatisticsValidation.interestRate}
          render={({ field }) => (
            <CustomInput
              {...field}
              field={field}
              required
              type={"number"}
              label={"Interest Rate"}
              eicon={<Percent />}
              error={errors?.interestRate?.message}
            />
          )}
        />

        <Controller
          name="empAmount"
          control={control}
          rules={loanSummaryStatisticsValidation.empAmount}
          render={({ field }) => (
            <CustomInput
              {...field}
              field={field}
              required
              type={"number"}
              label={"Estimated Monthly Payment"}
              sicon={<AttachMoney />}
              error={errors?.empAmount?.message}
            />
          )}
        />

        <Controller
          name="emeAmount"
          control={control}
          rules={loanSummaryStatisticsValidation.emeAmount}
          render={({ field }) => (
            <CustomInput
              {...field}
              field={field}
              required
              type={"number"}
              label={"Estimated Monthly Escrow"}
              sicon={<AttachMoney />}
              error={errors?.emeAmount?.message}
            />
          )}
        />

        <Controller
          name="loanType"
          control={control}
          rules={loanSummaryStatisticsValidation.loanType}
          render={({ field }) => (
            <SingleSelectDynamicValueDropdown
              {...field}
              field={field}
              required
              label={"Loan Type"}
              options={APPRAISAL_TYPE_VALUE}
              error={errors?.loanType?.message}
            />
          )}
        />

        <Controller
          name="lockStatus"
          control={control}
          rules={loanSummaryStatisticsValidation.lockStatus}
          render={({ field }) => (
            <RadioButtonGroup
              {...field}
              field={field}
              required
              direction={"row"}
              data={LOCK_STATUS_VALUE}
              label={"Lock Status"}
              error={errors?.lockStatus?.message}
            />
          )}
        />

        <Controller
          name="lockExpirationDate"
          control={control}
          rules={loanSummaryStatisticsValidation.lockExpirationDate}
          render={({ field }) => (
            <CustomInput
              {...field}
              field={field}
              required
              type={"date"}
              label={"Lock Expiration Date"}
              error={errors?.lockExpirationDate?.message}
            />
          )}
        />

        <Controller
          name="appraisalStatus"
          control={control}
          rules={loanSummaryStatisticsValidation.appraisalStatus}
          render={({ field }) => (
            <SingleSelectDynamicValueDropdown
              {...field}
              field={field}
              required
              label={"Loan Appraisal Status"}
              options={APPRAISAL_STATUS_VALUE}
              error={errors?.appraisalStatus?.message}
            />
          )}
        />

        <Stack my={2} direction={"row-reverse"} spacing={2}>
          <Button type="submit" variant="contained">{`Ok`}</Button>
          <Button variant="outlined" onClick={handleClose}>{`Cancel`}</Button>
        </Stack>
      </form>
    </>
  );
}
