import { Avatar, Box, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import Profile from "../../../assets/profileDemo.png";
import IconLabelDescription from "../../../components/mui-custom/icon-label-description";
import { CustomDivider } from "../../../components/mui-custom/custome-divider";
import { API } from "../../../services/api-config";
import { postApiCallDynamicWithOutReturn } from "../../../services/api-service";
import {
  NMLS_STATUS,
  NMLS_STATUS_INVALITED,
  ROLE_LIST,
  roleOwner,
  UPDATE,
} from "../../../constants/constatnt-variable";
import useInput from "../../../helpers/use-input";
import NmlsNoteForm from "./nmls-note-form";
import RadioButtonGroup from "../../../helpers/radio-button-group";
import { useRecoilValue } from "recoil";
import { profileState } from "../../../recoil/profile-atom";
import UpdateUserInfo from "../../../components/admin/update-user-info";
import {
  KSIdentificationIcon,
  KSMailIcon,
  KSPhoneIcon,
} from "../../../icons/custome-icon";

export function UserCard({ userInfo }) {
  let profileInfo = useRecoilValue(profileState);

  const [nmlsIdStatus] = useInput({
    data: null,
    status: false,
    initial: null,
  });

  const [companyNmlsIdStatus] = useInput({
    data: null,
    status: false,
    initial: null,
  });
  const [phone] = useInput({
    data: userInfo.phone,
    status: true,
    initial: null,
  });

  const [email] = useInput({
    data: userInfo.email,
    status: true,
    initial: null,
  });
  useEffect(() => {
    if (userInfo != null) {
      nmlsIdStatus.saveValue(userInfo.nmlsIdStatus);
      companyNmlsIdStatus.saveValue(userInfo.companyNmlsIdStatus);
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo != null) {
      if (
        nmlsIdStatus.value != null &&
        userInfo.nmlsIdStatus != nmlsIdStatus.value
      ) {
        nmlsChange({
          userCode: userInfo.userCode,
          nmlsId: userInfo.nmlsId,
          nmlsIdStatus: nmlsIdStatus.value,
        });
      }
      if (
        companyNmlsIdStatus.value != null &&
        userInfo.companyNmlsIdStatus != companyNmlsIdStatus.value
      ) {
        nmlsChange({
          userCode: userInfo.userCode,
          companyNmlsId: userInfo.companyNmlsId,
          companyNmlsIdStatus: companyNmlsIdStatus.value,
        });
      }
    }
  }, [nmlsIdStatus, companyNmlsIdStatus]);

  const nmlsChange = (data) => {
    postApiCallDynamicWithOutReturn({
      data: data,
      refresh: () => {
        // Nothing
      },
      path: API.nmlsStatusChange,
      header: UPDATE,
    });
  };

  if (!userInfo) {
    return <></>;
  }

  return (
    <Paper>
      <Grid container p={{ xs: 2, md: "1px solid #cccccc" }}>
        <Grid
          item
          container
          borderRight={{ xs: 0, md: "1px solid #cccccc" }}
          position={"relative"}
          md={6}
        >
          <IconLabelDescription
            alignItems="center"
            icon={
              <Avatar
                src={Profile}
                sx={{
                  height: { md: "50%", xs: "100%" },
                  width: { md: "50%", xs: "90%", sm: "70%" },
                }}
              />
            }
            description={
              <>
                <Typography variant="h3">
                  {userInfo.firstName + " " + userInfo.lastName}
                </Typography>
                <Typography variant="body1">{userInfo.role}</Typography>
              </>
            }
          />
        </Grid>
        <Grid
          item
          md={6}
          px={2}
          py={{ xs: 0, md: 2 }}
          container
          position={"relative"}
          justifyContent="center"
        >
          <CustomDivider display={{ xs: "block", md: "none" }} width={"90%"} />
        </Grid>

        <Grid
          item
          container
          borderRight={{ xs: 0, md: "1px solid #cccccc" }}
          position={"relative"}
          md={6}
        >
          <IconLabelDescription
            icon={<PlaceOutlinedIcon />}
            iconLabel={"Address"}
            description={
              userInfo?.zip +
              " " +
              userInfo?.state +
              " " +
              userInfo?.firstAddress
            }
          />
        </Grid>
        <Grid
          item
          md={6}
          px={2}
          py={{ xs: 0, md: 2 }}
          container
          justifyContent="center"
          position={"relative"}
        >
          <CustomDivider display={{ xs: "block", md: "none" }} width={"90%"} />
        </Grid>

        <Grid
          item
          container
          borderRight={{ xs: 0, md: "1px solid #cccccc" }}
          position={"relative"}
          md={6}
        >
          <IconLabelDescription
            icon={<KSPhoneIcon />}
            iconLabel={"Phn no"}
            description={phone.value}
          />
        </Grid>
        <Grid
          item
          md={6}
          px={2}
          py={{ xs: 0, md: 2 }}
          container
          justifyContent="center"
          position={"relative"}
        >
          {/* for phone update */}
          <UpdateUserInfo
            variant={"phone"}
            updatedValue={phone}
            userCode={userInfo.userCode}
            userEmail={userInfo.email}
            adminEmail={profileInfo.email}
          />

          <CustomDivider display={{ xs: "block", md: "none" }} width={"90%"} />
        </Grid>

        <Grid
          item
          container
          borderRight={{ xs: 0, md: "1px solid #cccccc" }}
          position={"relative"}
          md={6}
        >
          <IconLabelDescription
            icon={<KSMailIcon />}
            iconLabel={"Mail"}
            description={email.value}
          />
        </Grid>
        <Grid
          item
          md={6}
          px={2}
          py={{ xs: 0, md: 2 }}
          container
          justifyContent="center"
          position={"relative"}
        >
          {/* for Email update */}
          <UpdateUserInfo
            variant="email"
            updatedValue={email}
            userCode={userInfo.userCode}
            userEmail={userInfo.email}
            adminEmail={profileInfo.email}
          />

          <CustomDivider display={{ xs: "block", md: "none" }} width={"90%"} />
        </Grid>

        {/*  Nmls Id */}

        {roleOwner(userInfo.role) || userInfo.role == ROLE_LIST.mlo ? (
          <>
            <Grid
              item
              container
              borderRight={{ xs: 0, md: "1px solid #cccccc" }}
              position={"relative"}
              md={6}
            >
              <IconLabelDescription
                icon={<KSIdentificationIcon />}
                iconLabel={"NMLS ID"}
                description={userInfo.nmlsId}
              />
            </Grid>
            <Grid
              item
              md={6}
              px={2}
              py={{ xs: 0, md: 2 }}
              container
              justifyContent="center"
              position={"relative"}
            >
              <Box>
                <RadioButtonGroup
                  data={NMLS_STATUS}
                  state={nmlsIdStatus}
                  defaultValue={userInfo.nmlsIdStatus}
                />
              </Box>
              <CustomDivider
                display={{ xs: "block", md: "none" }}
                width={"90%"}
              />
            </Grid>
          </>
        ) : null}

        {/* Company Nmls Id */}

        {roleOwner(userInfo?.role) || userInfo.role == ROLE_LIST.mlo ? (
          <>
            <Grid
              item
              container
              borderRight={{ xs: 0, md: "1px solid #cccccc" }}
              position={"relative"}
              md={6}
            >
              <IconLabelDescription
                icon={<KSIdentificationIcon />}
                iconLabel={"Company NMLS ID"}
                description={userInfo.companyNmlsId}
              />
            </Grid>
            <Grid
              item
              md={6}
              px={2}
              py={{ xs: 0, md: 2 }}
              container
              justifyContent="center"
              position={"relative"}
            >
              <Box>
                <RadioButtonGroup
                  data={NMLS_STATUS}
                  state={companyNmlsIdStatus}
                  defaultValue={userInfo.companyNmlsIdStatus}
                />
              </Box>
              <CustomDivider
                display={{ xs: "block", md: "none" }}
                width={"90%"}
              />
            </Grid>
          </>
        ) : null}
      </Grid>

      <Grid
        display={
          nmlsIdStatus.value === NMLS_STATUS_INVALITED ||
            companyNmlsIdStatus.value === NMLS_STATUS_INVALITED
            ? ""
            : "none"
        }
        container
        p={{ xs: 2, md: "1px solid #cccccc" }}
      >
        <NmlsNoteForm userCode={userInfo.userCode} />
      </Grid>
    </Paper>
  );
}
