import { ClickAwayListener, Alert, AlertTitle } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import { GlobalAlertSeverity } from "../recoil/profile-atom";
import { setRecoil } from "recoil-nexus";
import { useRecoilValue } from "recoil";
import { DEFAULT_ALERET_SEVERITY } from '../constants/constatnt-variable';

// toast-configuration method,
// it is compulsory method.
toast.configure();

function DisplayMsg(props) {
  return (
    <ClickAwayListener
      onClickAway={() => {
        toast.dismiss();
      }}
    >
      <div>{props.msg}</div>
    </ClickAwayListener>
  );
}

export const toastNotify = (type, msg) => {
  toast.dismiss();
  toast[type](<DisplayMsg msg={msg} />, { autoClose: false, theme: "colored" });
};


export function AlertSeverity() {
  let alert = useRecoilValue(GlobalAlertSeverity)
  let title = null;

  if (alert.data.severity == 'error') {
    title = 'Error'
  }
  else if (alert.data.severity == 'success') {
    title = 'Success'
  }

  if (!alert.state) {
    return null
  }
  const handleClose = () => {
    // This is funtion from recoil-nexus
    // its used for setting data outside react component
    setRecoil(GlobalAlertSeverity,
      DEFAULT_ALERET_SEVERITY
    )
  }
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Alert
        severity={alert.data?.severity}
        onClose={handleClose}>
        <AlertTitle>{title}</AlertTitle>
        {alert.data.msg}</Alert>
    </ClickAwayListener>
  )
}


export const AlertError = (error) => {

  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.errorMessage
  ) {
    setRecoil(GlobalAlertSeverity, {
      state: true,
      data: {
        severity: "error",
        msg: error.response.data.errorMessage
      }
    })
    // toastNotify("error", error.response.data.errorMessage);
  } else if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.message
  ) {
    setRecoil(GlobalAlertSeverity, {
      state: true,
      data: {
        severity: "error",
        msg: error.response.data.message
      }
    }
    )
    // toastNotify("error", error.response.data.message);
  } else if (error && error.response && error.response.message) {
    setRecoil(GlobalAlertSeverity, {
      state: true,
      data: {
        severity: "error",
        msg: error.response.message
      }
    }
    )
    // toastNotify("error", error.response.message);
  } else {
    setRecoil(GlobalAlertSeverity, {
      state: true,
      data: {
        severity: "error",
        msg: "API Server Unreachable"
      }
    }
    )
    // toastNotify("error", "API Server Unreachable");
  }
};

export const SuccessMsg = (status) => {
  if (status === 201) {
    // toastNotify("success", "Successfully Created");
    setRecoil(GlobalAlertSeverity, {
      state: true,
      data: {
        severity: "success",
        msg: "Successfully Created"
      }
    }
    )
  }
  if (status === 200) {
    // toastNotify("success", "Successfully Updated");
    setRecoil(GlobalAlertSeverity, {
      state: true,
      data: {
        severity: "success",
        msg: "Successfully Updated"
      }
    }
    )
  }
  if (status === 204) {
    // toastNotify("success", "Successfully Deleted");
    setRecoil(GlobalAlertSeverity, {
      state: true,
      data: {
        severity: "success",
        msg: "Successfully Deleted"
      }
    }
    )
  }
};


export const ServerSuccessMsg = (res) => {
  if (res?.status) {
    if (res?.data['message']) {

      setRecoil(GlobalAlertSeverity, {
        state: true,
        data: {
          severity: "success",
          msg: res?.data['message']
        }
      }
      )

    }
  }


};


export const ClientSideMsg = (msg) => toastNotify("success", msg);
