import { Edit } from "@mui/icons-material";
import { Box, Button, Grid, TextField, Typography, } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { MAXLength } from "../../../constants/validation";
import { jsonValidation } from "../../../helpers/common_fuctions/json_checker";
import useInput from "../../../helpers/use-input";
import { webInfoState } from "../../../recoil/profile-atom";
import { API } from "../../../services/api-config";
import { postApiCallDynamic } from "../../../services/api-service";

function Bio() {
  const [webInfo, setWebInfo] = useRecoilState(webInfoState);

  let [bio] = useInput({
    data: jsonValidation(webInfo.bio),
    status: true,
    initial: "",
  });

  const stateReload = () => {
    bio.saveValue(jsonValidation(webInfo.bio));
  };
  useEffect(() => {
    stateReload();
  }, [webInfo]);

  const [openEdit, setOpenEdit] = useState(false);

  const handleEdit = () => {
    setOpenEdit(true);
  };

  const handleSubmit = async () => {
    if (openEdit) {
      let data = {
        websiteId: webInfo.id,
        bio: bio.value,
      };
      await postApiCallDynamic(
        {
          data: data,
          refresh: (res) => {
            if (res.status === 200) {
              setWebInfo((prev) => {
                return {
                  ...prev,
                  bio: res.data.bio,
                };
              });
            }
          },
          path: API.bio,
          header: "update"
        }
      );
    }
    setOpenEdit(false);
  };

  const handleValidation = (values) => {
    let errors = {};

    if (bio.value.trim().length < 1) {
      errors.bio = "Please enter your bio";
    }

    if (bio.value.trim().length >= 201) {
      errors.bio = "200 character max limit";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      bio: bio.value,
    },
    validate: (values) => handleValidation(values),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  return (
    <>
      <Grid container>
        <Grid item md={5} sm={12} xs={12} my={1}>
          <Typography variant="h3">Tell us about yourself</Typography>
        </Grid>
        <Grid item md={7} sm={12} xs={12} my={1}>
          <form id="update" onSubmit={formik.handleSubmit}>
            <Box display={openEdit ? "" : "none"}>
              <Box textAlign={{ md: "right", xs: "left" }}>
                <TextField
                  fullWidth
                  size="large"
                  placeholder="Please tell us more about yourself."
                  multiline
                  rows={4}
                  rowsMax={4}
                  {...bio}
                  inputProps={{ maxLength: MAXLength.bio }}
                  error={!!formik.errors.bio}
                />
                <Typography
                  pr={2}
                  my={2}
                  color={"error.main"}
                  variant={"caption"}
                >
                  {formik.errors.bio}
                </Typography>
              </Box>
              <Box my={1} textAlign={{ md: "right", xs: "left" }}>
                <Button
                  variant="outlined"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    setOpenEdit(false);
                    stateReload();
                  }}
                >
                  Cancel
                </Button>
                <Button id="update" type="submit" variant="contained">
                  Save
                </Button>
              </Box>
            </Box>
          </form>
          <Box display={openEdit ? "none" : ""}>
            <Typography variant="body2" textAlign={"justify"} sx={{ overflowWrap: 'break-word' }}>
              {bio.value}
              <Typography pl={1} component={"span"} variant="body2">
                <Edit fontSize="14" onClick={handleEdit} />
              </Typography>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Bio;
