import React, { useEffect, useState } from "react";
import {
  ArrowBackIos,
  ArrowForwardIos,
  AttachMoney,
  NavigateNext,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Link as MLink,
  Stack,
  Typography,
} from "@mui/material";
import {
  ATTACHMENT_TYPE,
  BUYING_AND_REFINACEING_LIST,
  HOME_HOA_MANAGEMENT,
  LOAN_TERM_TYPE,
  LOAN_TERM_TYPE_ARM,
  LOAN_TERM_TYPE_ARM_OPTION,
  LOAN_TERM_TYPE_FIXED,
  LOAN_TERM_TYPE_FIXED_OPTION,
  OCCUPANCY_TYPE,
  PROPERTY_TYPE,
  REASON_OF_REFINANCE,
  SOLAR_PURCHASED_STATUS,
  STATE,
  STATUS_OF_NEW_LENDER,
  STATUS_OF_PURCHASE,
  STATUS_OF_REALTOR,
  STATUS_OF_REFINANCE,
  TYPE_OF_CONTROL,
  TYPE_OF_REFINANCE,
} from "../../../../../constants/constatnt-variable";
import CustomInput from "../../../../../helpers/custom-input";
import CustomDropDown from "../../../../../helpers/dropdown/custom-dropdown";
import CustomLargeLabel from "../../../../../helpers/large-label";
import RadioButtonGroup from "../../../../../helpers/radio-button-group";
import CustomRadioChip from "../../../../../helpers/radio-chip";
import SingleSelectDynamicValueDropdown from "../../../../../helpers/dropdown/single-select-dropdown";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { TimeStampToMonthDateYearForInput } from "../../../../../helpers/time-formater";
import { LoanDetailsValidation } from "../../../../../constants/validation-rules";


export default function LoanDetailsForm({ data, onSubmit }) {
  const route = useNavigate();
  const location = useLocation();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      buyingOrRefinancing: data?.buyingOrRefinancing,
      statusOfPurchase: data?.statusOfPurchase,
      statusOfRealtor: data?.realtor?.name ? "Yes" : "No",
      name: data?.realtor?.name,
      companyName: data?.realtor?.companyName,
      phone: data?.realtor?.phone,
      email: data?.realtor?.email,
      solarPurchasedStatus: data?.solarPurchasedStatus,
      isRecentlyRefinanced: data?.isRecentlyRefinanced,
      isMortgageStatementAvailable: data?.isMortgageStatementAvailable,
      typeOfRefinance: data?.typeOfRefinance,
      reasonOfRefinance: data?.reasonOfRefinance,
      cashoutAmount: data?.cashoutAmount,
      loanTermType: data?.loanTermType,
      loanOptions: data?.loanOptions,
      homeHOAManagement: data?.homeHOAManagement,
      propertyType: data?.propertyType,
      attachmentType: data?.attachmentType,
      occupancyType: data?.occupancyType,
      addressLine1: data?.addressLine1,
      addressLine2: data?.addressLine2,
      unit: data?.unit,
      city: data?.city,
      country: data?.country,
      state: data?.state,
      zip: data?.zip,
      closingDate: TimeStampToMonthDateYearForInput(data?.closingDate),
      propertyBuiltDate: TimeStampToMonthDateYearForInput(
        data?.propertyBuiltDate
      ),
      purchasePrice: data?.purchasePrice,
      downPayment: data?.downPayment,
      downPaymentAmount: data?.downPaymentAmount,
      loanAmount: data?.loanAmount,
      typeOfTitle: data?.typeOfTitle,
      solarleaseapyment: data?.solarleaseapyment,
      countryPropertyTax: data?.countryPropertyTax,
      mortgageInsurance: data?.mortgageInsurance,
    },
  });

  const {
    buyingOrRefinancing,
    solarPurchasedStatus,
    statusOfRealtor,
    statusOfPurchase,
    loanTermType,
    homeHOAManagement,
    attachmentType,
    occupancyType,
    typeOfTitle,
    isRecentlyRefinanced,
    isMortgageStatementAvailable,
    typeOfRefinance,
  } = useWatch({ control });

  const [loanTerm, setLoanTerm] = useState([]);

  const [loanOptions, setLoanOptions] = useState([]);

  useEffect(() => {
    if (loanTermType == LOAN_TERM_TYPE_FIXED) {
      setLoanOptions(LOAN_TERM_TYPE_FIXED_OPTION);
    }
    if (loanTermType == LOAN_TERM_TYPE_ARM) {
      setLoanOptions(LOAN_TERM_TYPE_ARM_OPTION);
    }
  }, [loanTermType]);


  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ height: "100%" }}>
      <Stack
        direction={"column"}
        justifyContent={"space-between"}
        py={2}
        px={4}
        height={"inherit"}
        overflow={"auto"}
      >
        <Box>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNext fontSize="small" />}
          >
            <MLink underline="hover" color="inherit" href="#">
              Loan Details
            </MLink>
            <Typography color="primary.main">Property Details</Typography>
          </Breadcrumbs>

          <Box maxWidth={{ xs: "100%", md: "50%" }}>
            <Controller
              name="buyingOrRefinancing"
              control={control}
              rules={LoanDetailsValidation.buyingOrRefinancing}
              render={({ field }) => (
                <RadioButtonGroup
                  labelComponent={
                    <CustomLargeLabel
                      required
                      value={
                        "Are you buying a new home or refinancing the existing one?"
                      }
                    />
                  }
                  field={field}
                  {...field}
                  data={BUYING_AND_REFINACEING_LIST}
                  direction="row"
                  labelColor={true}
                  error={errors?.buyingOrRefinancing?.message}
                />
              )}
            />

            {buyingOrRefinancing == "Purchasing" ? (
              <>
                <Controller
                  name="loanTermType"
                  control={control}
                  rules={LoanDetailsValidation.loanTermType}
                  render={({ field }) => (
                    <CustomRadioChip
                      labelComponent={
                        <CustomLargeLabel required value={"Loan Term"} />
                      }
                      {...field}
                      field={field}
                      state={loanTermType}
                      data={LOAN_TERM_TYPE}
                      error={errors?.loanTermType?.message}
                    />
                  )}
                />

                {loanTermType ? (
                  <>
                    <Box my={2}>
                      <Controller
                        name="loanOptions"
                        control={control}
                        rules={LoanDetailsValidation.loanOptions}
                        render={({ field }) => (
                          <SingleSelectDynamicValueDropdown
                            {...field}
                            field={field}
                            options={loanOptions}
                            value={loanTerm}
                            setValue={setLoanTerm}
                            error={errors?.loanOptions?.message}
                          />
                        )}
                      />
                    </Box>
                  </>
                ) : null}

                <Controller
                  name="statusOfPurchase"
                  control={control}
                  rules={LoanDetailsValidation.statusOfPurchase}
                  render={({ field }) => (
                    <CustomDropDown
                      labelComponent={
                        <CustomLargeLabel
                          required
                          value={"Status of Purchase"}
                        />
                      }
                      {...field}
                      field={field}
                      state={statusOfPurchase}
                      option={STATUS_OF_PURCHASE}
                      error={errors?.statusOfPurchase?.message}
                    />
                  )}
                />
                <Controller
                  name="statusOfRealtor"
                  control={control}
                  rules={LoanDetailsValidation.statusOfRealtor}
                  render={({ field }) => (
                    <CustomRadioChip
                      labelComponent={
                        <CustomLargeLabel
                          required
                          value={"Are you working with Realtor?"}
                        />
                      }
                      {...field}
                      field={field}
                      state={statusOfRealtor}
                      data={STATUS_OF_REALTOR}
                      error={errors?.statusOfRealtor?.message}
                    />
                  )}
                />

                {statusOfRealtor == "Yes" ? (
                  <>
                    <Controller
                      name="name"
                      control={control}
                      rules={LoanDetailsValidation.name}
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          label="Name"
                          required
                          error={errors?.name?.message}
                        />
                      )}
                    />

                    <Controller
                      name="companyName"
                      control={control}
                      rules={LoanDetailsValidation.companyName}
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          label="Company Name"
                          required
                          error={errors?.companyName?.message}
                        />
                      )}
                    />

                    <Controller
                      name="phone"
                      control={control}
                      rules={LoanDetailsValidation.phone}
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          label="Phone Number,"
                          required
                          type="number"
                          error={errors?.phone?.message}
                        />
                      )}
                    />

                    <Controller
                      name="email"
                      control={control}
                      rules={LoanDetailsValidation.email}
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          label="Email"
                          required
                          error={errors?.email?.message}
                        />
                      )}
                    />
                  </>
                ) : null}

                <Controller
                  name="solarPurchasedStatus"
                  control={control}
                  rules={LoanDetailsValidation.solarPurchasedStatus}
                  render={({ field }) => (
                    <CustomRadioChip
                      labelComponent={
                        <CustomLargeLabel
                          required
                          value={"Is solar purchased ?"}
                        />
                      }
                      {...field}
                      field={field}
                      state={solarPurchasedStatus}
                      data={SOLAR_PURCHASED_STATUS}
                      error={errors?.solarPurchasedStatus?.message}
                    />
                  )}
                />
              </>
            ) : null}
            {buyingOrRefinancing == "Refinancing" ? (
              <>
                <Controller
                  name="isRecentlyRefinanced"
                  control={control}
                  rules={LoanDetailsValidation.isRecentlyRefinanced}
                  render={({ field }) => (
                    <CustomRadioChip
                      labelComponent={
                        <CustomLargeLabel
                          required
                          value={"Did you refinance to a new lender recently?"}
                        />
                      }
                      {...field}
                      field={field}
                      state={isRecentlyRefinanced}
                      data={STATUS_OF_REFINANCE}
                      error={errors?.isRecentlyRefinanced?.message}
                    />
                  )}
                />

                <Controller
                  name="isMortgageStatementAvailable"
                  control={control}
                  rules={LoanDetailsValidation.isMortgageStatementAvailable}
                  render={({ field }) => (
                    <CustomRadioChip
                      labelComponent={
                        <CustomLargeLabel
                          required
                          value={
                            "Do you have a mortgage statement with the new lender?"
                          }
                        />
                      }
                      {...field}
                      field={field}
                      state={isMortgageStatementAvailable}
                      data={STATUS_OF_NEW_LENDER}
                      error={errors?.isMortgageStatementAvailable?.message}
                    />
                  )}
                />
                {isMortgageStatementAvailable == "Yes" ? (
                  <>
                    <Controller
                      name="typeOfRefinance"
                      control={control}
                      rules={LoanDetailsValidation.typeOfRefinance}
                      render={({ field }) => (
                        <CustomRadioChip
                          labelComponent={
                            <CustomLargeLabel
                              required
                              value={"Refinance Type"}
                            />
                          }
                          {...field}
                          field={field}
                          state={typeOfRefinance}
                          data={TYPE_OF_REFINANCE}
                          error={errors?.typeOfRefinance?.message}
                        />
                      )}
                    />
                    <Controller
                      name="reasonOfRefinance"
                      control={control}
                      rules={LoanDetailsValidation.reasonOfRefinance}
                      render={({ field }) => (
                        <CustomDropDown
                          labelComponent={
                            <CustomLargeLabel
                              required
                              value={"Refinance Reason"}
                            />
                          }
                          {...field}
                          field={field}
                          option={REASON_OF_REFINANCE}
                          error={errors?.reasonOfRefinance?.message}
                        />
                      )}
                    />
                    <Controller
                      name="cashoutAmount"
                      control={control}
                      rules={LoanDetailsValidation.cashoutAmount}
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          type={"number"}
                          label={"Cashout Amount"}
                          sicon={<AttachMoney />}
                          required
                          error={errors?.cashoutAmount?.message}
                        />
                      )}
                    />

                    <Controller
                      name="loanTermType"
                      control={control}
                      rules={LoanDetailsValidation.loanTermType}
                      render={({ field }) => (
                        <CustomRadioChip
                          labelComponent={
                            <CustomLargeLabel required value={"Loan Term"} />
                          }
                          {...field}
                          field={field}
                          state={loanTermType}
                          data={LOAN_TERM_TYPE}
                          error={errors?.loanTermType?.message}
                        />
                      )}
                    />

                    {loanTermType ? (
                      <>
                        <Box my={2}>
                          <Controller
                            name="loanOptions"
                            control={control}
                            rules={LoanDetailsValidation.loanOptions}
                            render={({ field }) => (
                              <SingleSelectDynamicValueDropdown
                                {...field}
                                field={field}
                                options={loanOptions}
                                value={loanTerm}
                                setValue={setLoanTerm}
                                error={errors?.loanOptions?.message}
                              />
                            )}
                          />
                        </Box>
                      </>
                    ) : null}
                  </>
                ) : null}

                {isMortgageStatementAvailable == "No" ? (
                  <Typography variant="inherit" color={"success.main"}>
                    {`You will be asked to provide the new closing disclosures,
                                the new mortgage note, and the mortgage statement with the previous
                                lender in the documents section!`}
                  </Typography>
                ) : null}
              </>
            ) : null}

            {isMortgageStatementAvailable == "Yes" ||
              buyingOrRefinancing == "Purchasing" ? (
              <>
                {/* <Controller
                                    name='loanTermType'
                                    control={control}
                                    rules={LoanDetailsValidation.loanTermType}
                                    render={({ field }) => (
                                        <CustomRadioChip
                                            labelComponent={<CustomLargeLabel required value={'Loan Term'} />}
                                            {...field}
                                            field={field}
                                            state={loanTermType}
                                            data={LOAN_TERM_TYPE}
                                            error={errors?.loanTermType?.message}
                                        />
                                    )}
                                />

                                {loanTermType ?
                                    <>
                                        <Box my={2} >
                                            <Controller
                                                name="loanOptions"
                                                control={control}
                                                rules={LoanDetailsValidation.loanOptions}
                                                render={({ field }) => (
                                                    <SingleSelectDynamicValueDropdown
                                                        {...field}
                                                        field={field}
                                                        options={loanOptions}
                                                        value={loanTerm}
                                                        setValue={setLoanTerm}
                                                        error={errors?.loanOptions?.message}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </>
                                    : null
                                } */}

                <Controller
                  name="homeHOAManagement"
                  control={control}
                  rules={LoanDetailsValidation.homeHOAManagement}
                  render={({ field }) => (
                    <CustomRadioChip
                      labelComponent={
                        <CustomLargeLabel
                          required
                          value={"Do the home under HOA management?"}
                        />
                      }
                      {...field}
                      field={field}
                      state={homeHOAManagement}
                      data={HOME_HOA_MANAGEMENT}
                      error={errors?.homeHOAManagement?.message}
                    />
                  )}
                />

                <Controller
                  name="propertyType"
                  control={control}
                  rules={LoanDetailsValidation.propertyType}
                  render={({ field }) => (
                    <CustomDropDown
                      labelComponent={
                        <CustomLargeLabel required value={"Property Type"} />
                      }
                      {...field}
                      field={field}
                      option={PROPERTY_TYPE}
                      error={errors?.propertyType?.message}
                    />
                  )}
                />

                <Controller
                  name="attachmentType"
                  control={control}
                  rules={LoanDetailsValidation.attachmentType}
                  render={({ field }) => (
                    <CustomRadioChip
                      labelComponent={
                        <CustomLargeLabel required value={"Attachment Type"} />
                      }
                      {...field}
                      field={field}
                      state={attachmentType}
                      data={ATTACHMENT_TYPE}
                      error={errors?.attachmentType?.message}
                    />
                  )}
                />

                <Controller
                  name="occupancyType"
                  control={control}
                  rules={LoanDetailsValidation.occupancyType}
                  render={({ field }) => (
                    <CustomRadioChip
                      labelComponent={
                        <CustomLargeLabel required value={"Occupancy Type"} />
                      }
                      {...field}
                      field={field}
                      state={occupancyType}
                      data={OCCUPANCY_TYPE}
                      error={errors?.occupancyType?.message}
                    />
                  )}
                />

                <Typography variant="inherit" mt={2}>
                  Property Address
                </Typography>
                <Controller
                  name="addressLine1"
                  control={control}
                  rules={LoanDetailsValidation.addressLine1}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      label="Address Line 1"
                      required
                      type="text"
                      error={errors?.addressLine1?.message}
                    />
                  )}
                />

                <Controller
                  name="addressLine2"
                  control={control}
                  rules={LoanDetailsValidation.addressLine2}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      label="Address Line 2"
                      required
                      type="text"
                      error={errors?.addressLine2?.message}
                    />
                  )}
                />

                <Controller
                  name="unit"
                  control={control}
                  rules={LoanDetailsValidation.unit}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      label="Unit"
                      required
                      type="number"
                      error={errors?.unit?.message}
                    />
                  )}
                />

                <Controller
                  name="city"
                  control={control}
                  rules={LoanDetailsValidation.city}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      label="City"
                      required
                      type="text"
                      error={errors?.city?.message}
                    />
                  )}
                />

                {/* <Controller
                                    name="country"
                                    control={control}
                                    rules={LoanDetailsValidation.country}
                                    render={({ field }) => (
                                        <CustomInput
                                            {...field}
                                            label="Country"
                                            required
                                            type="text"
                                            error={errors?.country?.message}
                                        />
                                    )}
                                /> */}
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  gap={{ xs: 0, md: 2 }}
                >
                  <Box width={"100%"}>
                    <Controller
                      name="state"
                      control={control}
                      rules={LoanDetailsValidation.state}
                      render={({ field }) => (
                        <CustomDropDown
                          {...field}
                          field={field}
                          label="State"
                          required
                          option={STATE}
                          error={errors?.state?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <Controller
                      name="zip"
                      control={control}
                      rules={LoanDetailsValidation.zip}
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          field={field}
                          type={"number"}
                          label={"ZIP"}
                          required
                          error={errors?.zip?.message}
                        />
                      )}
                    />
                  </Box>
                </Stack>
                <Controller
                  name="closingDate"
                  control={control}
                  rules={LoanDetailsValidation.closingDate}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      field={field}
                      type={"date"}
                      label={"Estimated Closing Date"}
                      required
                      error={errors?.closingDate?.message}
                    />
                  )}
                />

                <Controller
                  name="propertyBuiltDate"
                  control={control}
                  rules={LoanDetailsValidation.propertyBuiltDate}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      field={field}
                      type={"date"}
                      label={"Date property built"}
                      required
                      error={errors?.propertyBuiltDate?.message}
                    />
                  )}
                />

                <Controller
                  name="purchasePrice"
                  control={control}
                  rules={LoanDetailsValidation.purchasePrice}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={"number"}
                      label={"Purchase Price"}
                      sicon={<AttachMoney />}
                      required
                      error={errors?.purchasePrice?.message}
                    />
                  )}
                />

                <Controller
                  name="downPayment"
                  control={control}
                  rules={LoanDetailsValidation.downPayment}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={"number"}
                      label={"Down Payment (%)"}
                      required
                      error={errors?.downPayment?.message}
                    />
                  )}
                />

                <Controller
                  name="downPaymentAmount"
                  control={control}
                  rules={LoanDetailsValidation.downPaymentAmount}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={"number"}
                      label={"Down Payment Amount"}
                      required
                      error={errors?.downPaymentAmount?.message}
                    />
                  )}
                />

                <Controller
                  name="loanAmount"
                  control={control}
                  rules={LoanDetailsValidation.loanAmount}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={"number"}
                      label={"Loan Amount"}
                      sicon={<AttachMoney />}
                      required
                      error={errors?.loanAmount?.message}
                    />
                  )}
                />

                <Controller
                  name="typeOfTitle"
                  control={control}
                  rules={LoanDetailsValidation.typeOfTitle}
                  render={({ field }) => (
                    <CustomRadioChip
                      {...field}
                      field={field}
                      state={typeOfTitle}
                      required
                      label={"Type of the title"}
                      data={TYPE_OF_CONTROL}
                      error={errors?.typeOfTitle?.message}
                    />
                  )}
                />

                {buyingOrRefinancing == "Refinancing" ? (
                  <>
                    <CustomLargeLabel value={"Payment"} required />
                    <Controller
                      name="solarleaseapyment"
                      control={control}
                      rules={LoanDetailsValidation.solarleaseapyment}
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          type={"number"}
                          label={"Solar Lease payment (Per month)"}
                          sicon={<AttachMoney />}
                          required
                          error={errors?.solarleaseapyment?.message}
                        />
                      )}
                    />

                    <Controller
                      name="countryPropertyTax"
                      control={control}
                      rules={LoanDetailsValidation.countryPropertyTax}
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          type={"number"}
                          label={"Country Property Tax"}
                          sicon={<AttachMoney />}
                          required
                          error={errors?.countryPropertyTax?.message}
                        />
                      )}
                    />

                    <Controller
                      name="mortgageInsurance"
                      control={control}
                      rules={LoanDetailsValidation.mortgageInsurance}
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          type={"number"}
                          label={"Mortgage Insurance"}
                          sicon={<AttachMoney />}
                          required
                          error={errors?.mortgageInsurance?.message}
                        />
                      )}
                    />
                  </>
                ) : null}
              </>
            ) : null}
          </Box>
        </Box>

        <Box maxWidth={{ xs: "100%", md: "50%" }} mt={2}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            {/* <Link to={"#"}> */}
            <Button
              startIcon={<ArrowBackIos />}
              onClick={() => {
                route(location.pathname.replace(/[^/]*$/, "getting-started"));
              }}
            >
              Previous
            </Button>
            {/* </Link> */}
            <Button type="submit" endIcon={<ArrowForwardIos />}>
              Next
            </Button>
          </Stack>
        </Box>
      </Stack>
    </form>
  );
}
