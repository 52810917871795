import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { formState } from "../../../recoil/profile-atom";
import { Link } from "react-router-dom";
import { Menu, MoreVert } from "@mui/icons-material";
import { kstheme } from "../../../theme/theme";

export default function LoanTopBar({ mobileNvbarOpen }) {
  const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));
  let [totalProgress, setTotalProgress] = useState(0);
  let formData = useRecoilValue(formState);
  const progressCalculator = () => {
    let length = Object.keys(formData).length;
    let subLength = Object.keys(formData?.borrowerInfo?.page).length;
    let count = 0;
    let countSub = 0;
    let perSlice = 100 / length;
    let subSlice = perSlice / subLength;

    Object.keys(formData).forEach((i) => {
      if (formData[i]?.isComplete == true) {
        count++;
      }
    });
    Object.keys(formData?.borrowerInfo?.page).forEach((i) => {
      if (formData?.borrowerInfo?.page[i]?.isComplete == true) {
        countSub++;
      }
    });
    if (formData?.hasPartner && formData?.hasPartner == true) {
      Object.keys(
        formData?.partnerInfo?.page ? formData?.partnerInfo?.page : {}
      ).forEach((i) => {
        if (formData?.partnerInfo?.page[i]?.isComplete == true) {
          countSub++;
        }
      });
    }
    let totalSliceValue = perSlice * count + subSlice * countSub;

    setTotalProgress(totalSliceValue);
  };
  useEffect(() => {
    progressCalculator();
  }, [formData, window.location.pathname]);

  return (
    <>
      <Box
        display={"flex"}
        px={{ xs: 1, md: 2 }}
        py={{ xs: 1, md: 3 }}
        boxShadow={1}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          {isMobile && (
            <IconButton onClick={mobileNvbarOpen} color="primary">
              <MoreVert />
            </IconButton>
          )}
          <Typography variant="h3">Loan Application</Typography>
        </Stack>
        <Link to={"/"} replace>
          <Button variant={"text"} size="large">
            Resume Later
          </Button>
        </Link>
      </Box>
      <Box>
        <LinearProgress variant="determinate" value={totalProgress} />
      </Box>
    </>
  );
}
