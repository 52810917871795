import {Box, Button} from "@mui/material";
import {useFormik} from "formik";
import React from "react";
import {useRecoilValue} from "recoil";
import {MODAL_ACTION, UPDATE} from "../../../../constants/constatnt-variable";
import CustomInput from "../../../../helpers/custom-input";
import useInput from "../../../../helpers/use-input";
import {webInfoState} from "../../../../recoil/profile-atom";
import {API} from "../../../../services/api-config";
import {postApiCallDynamicWithOutReturn} from "../../../../services/api-service";

export default function RatesForm({
  data,
  handleClose,
  setTableReload,
  ...props
}) {
  let webInfo = useRecoilValue(webInfoState);

  let [loanYear] = useInput({
    data: data.loanYear,
    status: props.action == UPDATE,
    initial: "",
  });

  let [loanAmountUpto] = useInput({
    data: data.loanAmountUpto,
    status: props.action == UPDATE,
    initial: "",
  });

  let [mortgageRate] = useInput({
    data: data.mortgageRate,
    status: props.action == UPDATE,
    initial: "",
  });

  let [APRrates] = useInput({
    data: data.APRrates,
    status: props.action == UPDATE,
    initial: "",
  });

  const handleSubmit = (event) => {
    const apiData = {
      websiteId: webInfo.id,
      id: data.id,
      mortgageRate: mortgageRate.value,
      loanAmountUpto: loanAmountUpto.value,
      loanYear: loanYear.value,
      APRrates: APRrates.value,
    };
    postApiCallDynamicWithOutReturn(
      {
        data: apiData,
        refresh: () => {
          setTableReload((prev) => !prev);
          handleClose();
        },
        path: API.rates,
        header: event.target.id
      }
    );
  };

  const handleValidation = (values) => {
    let errors = {};
    if (loanYear.value === "") {
      errors.loanYear = "Select a loan Year to proceed";
    }
    if (loanAmountUpto.value === "") {
      errors.loanAmountUpto = "Enter a loan Amount Upto proceed";
    }
    if (mortgageRate.value === "") {
      errors.mortgageRate = "Enter  mortgage Rate to proceed";
    }
    if (APRrates.value === "") {
      errors.APRrates = "Enter APR rates to proceed";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      loanYear: loanYear.value,
      loanAmountUpto: loanAmountUpto.value,
      mortgageRate: mortgageRate.value,
      APRrates: APRrates.value,
    },
    validate: (values) => handleValidation(values),
    onSubmit: (values) => {
      handleSubmit({ target: { id: props.action } });
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <CustomInput
          size="small"
          fullWidth
          boxProps={{ my: 2 }}
          label={"Loan Amount Up to"}
          required={true}
          placeholder={"600,000"}
          change={loanAmountUpto}
          type="number"
          error={formik.errors.loanAmountUpto}
        />
      </Box>
      <Box>
        <CustomInput
          size="small"
          fullWidth
          boxProps={{ my: 2 }}
          label={"Loan Type "}
          required={true}
          placeholder={"30 years"}
          change={loanYear}
          type="text"
          error={formik.errors.loanYear}
        />
      </Box>
      <Box>
        <CustomInput
          size="small"
          fullWidth
          boxProps={{ my: 2 }}
          label={"Mortgage Rate (%)"}
          change={mortgageRate}
          required={true}
          placeholder={"6.000"}
          type="number"
          error={formik.errors.mortgageRate}
        />
      </Box>
      <Box>
        <CustomInput
          size="small"
          fullWidth
          boxProps={{ my: 2 }}
          label={"APR Rate (%)"}
          required={true}
          placeholder={"6.500"}
          change={APRrates}
          type="number"
          error={formik.errors.APRrates}
        />
      </Box>
      <Box my={2} display="flex" flexDirection={"row"} justifyContent={"end"}>
        <Button
          variant="contained"
          id={props.action}
          type="submit"
        // onClick={handleSubmit}
        >
          {MODAL_ACTION[props.action]}
        </Button>
      </Box>
    </form>
  );
}
