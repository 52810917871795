import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  styled,
  useRadioGroup,
  Typography,
  FormLabel,
  Divider,
} from "@mui/material";
import React from "react";

export default function RadioButtonGroup({
  label,
  required,
  data = [],
  defaultValue,
  state,
  direction = "column",
  labelColor = false,
  field,
  error = null,
  labelComponent,
  borderBottom = false,
  ...props
}) {
  const StyledFormControlLabel = styled((props) => (
    <FormControlLabel {...props} />
  ))(({ theme, checked }) => ({
    ".MuiFormControlLabel-label": checked && {
      color: theme.palette.primary.main,
    },
  }));

  const handleChange = (event) => {
    state.saveValue(event.target.value);
  };

  const MyFormControlLabel = (props) => {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }
    if (field) {
      checked = props.value === props.selected;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
  };

  return (
    <FormControl>
      <FormLabel>
        {label}
        {labelComponent ? labelComponent : null}
        {required ? (
          <Typography variant="small" color="error">
            *
          </Typography>
        ) : null}
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={defaultValue}
        name="radio-buttons-group"
        // onChange={handleChange}
        // {...props.field}
        {...(field ? field : { onChange: handleChange })}
      >
        <Grid item container direction={direction}>
          {data?.map((item, index) => (
            <Grid key={index} item>
              {labelColor ? (
                <MyFormControlLabel
                  selected={field?.value}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ) : (
                <FormControlLabel
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              )}

              {borderBottom ? <Divider /> : null}
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
}
