import { Box, Button, FormHelperText, Grid, Link as MuLink, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomInput from "../../helpers/custom-input";
import useInput from "../../helpers/use-input";
import {
  getApiCallDynamic,
  postApiCallDynamic,
} from "../../services/api-service";
import { useFormik } from "formik";
import { MAXLength, ValidationConstant } from "../../constants/validation";
import { API } from "../../services/api-config";
import { CountDown } from "../../helpers/time-counter";
import { useRecoilState } from "recoil";
import { profileState } from "../../recoil/profile-atom";
import { EditOutlined } from "@mui/icons-material";
import { NMLS_STATUS_VALID, SERVICE_TYPE } from "../../constants/constatnt-variable";
import { toastNotify } from "../../helpers/alert-msg";


export default function IdentityChange({ identityValue, variant }) {
  let [identityChange, setIdentityChange] = useState("identity");
  let [profileData, setProfileData] = useRecoilState(profileState);



  // let [newIdentityValue] = useInput({
  //   data: "",
  //   status: false,
  //   initial: "",
  // });

  let [emailOtpCount] = CountDown({ sec: 0 });
  let [phoneOtpCount] = CountDown({ sec: 0 });

  let [emailOTP] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  let [phoneOTP] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  let componentData = {
    label: "",
    placeholder: "",
    description: "",
    nmlsStatus: "",
    edit: "",
    type: "",
  }


  if (variant === "nmls") {
    componentData.title = "Your NMLS ID"
    componentData.label = "Enter your new NMLS ID"
    componentData.placeholder = "985647"
    componentData.description = "Verify OTP to Update NMLS ID"
    componentData.nmlsStatus = profileData?.nmls?.status
    componentData.edit = profileData?.nmls?.id
    componentData.type = "number"

  } else if (variant === "companyNmls") {
    componentData.title = "Company NMLS ID"
    componentData.label = "Enter your new Company NMLS ID"
    componentData.placeholder = "256486"
    componentData.description = "Verify OTP to Update Company NMLS ID"
    componentData.nmlsStatus = profileData?.companyNmls?.status
    componentData.edit = profileData?.companyNmls?.id
    componentData.type = "number"


  } else {
    componentData.title = "Email"
    componentData.label = "Enter your new Email ID"
    componentData.placeholder = "thomas@cache.com"
    componentData.description = "Verify OTP to Update your email id"
    componentData.nmlsStatus = ""
    componentData.edit = profileData?.email
    componentData.type = "email"


  }

  const identityUpdateRequest = (event) => {
    let data = {}
    switch (variant) {
      case 'nmls':
        data = {
          service: SERVICE_TYPE.changeNmlsId,
        }
        break;
      case 'companyNmls':
        data = {
          service: SERVICE_TYPE.changeCompanyNmlsId,
        }
        break;
      case 'email':
        data = {
          service: SERVICE_TYPE.emailChange,
        }
        break;
      default:
    }

    if (event.target.id == "newIdentityValue") {
      data = {
        ...data,
        changedValue: identityValue.value,
      };
      postApiCallDynamic(
        {
          data: data,
          refresh: (res) => {
            return res;
          },
          path: API.info
        }
      ).then((res) => {
        if (res?.status == 200) {
          setIdentityChange("verify");
          emailOtpCount.setCounter(res.data.emailOtpCount);
          phoneOtpCount.setCounter(res.data.phoneOtpCount);
        }
      });
    }

    if (event.target.id === "verify") {
      data = {
        ...data,
        emailOTP: emailOTP.value,
        phoneOTP: phoneOTP.value,
      };
      postApiCallDynamic(
        {
          data: data,
          refresh: (res) => {
            return res;
          },
          path: API.securedOTP
        }
      ).then((res) => {
        if (res?.status === 200) {
          if (variant === "nmls") {
            setProfileData((prev) => {
              return {
                ...prev,
                nmlsId: identityValue.value,
              };
            });
          } else if (variant === "companyNmls") {
            setProfileData((prev) => {
              return {
                ...prev,
                companyNmlsId: identityValue.value,
              };
            });
          } else {
            setProfileData((prev) => {
              return {
                ...prev,
                email: identityValue.value,
              };
            });
          }
          toastNotify("success", "Successfully Updated")
        }
      });
      identityChangeCancel();
    }
  };

  const handleValidation = (values) => {
    let errors = {};
    if (variant === 'nmls' || variant === 'companyNmls') {
      if (
        identityValue.value.length < MAXLength.nmlsIdMin ||
        identityValue.value.length > MAXLength.nmlsIdMax
      ) {
        errors.identityValue = "Please enter a valid nmlsId";
      }
    }

    if (variant === 'email') {
      if (ValidationConstant.emailRegx.test(String(identityValue.value)) === false) {
        errors.identityValue = "Please enter a valid Email";
      }
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      identityValue: identityValue.value,
    },
    validate: (values) => handleValidation(values),
    onSubmit: (values) => {
      identityUpdateRequest({ target: { id: "newIdentityValue" } });
    },
  });



  const handleValidationOTP = (values) => {
    let errors = {};

    if (emailOTP.value.trim() == "") {
      errors.emailOTP = "Please enter your email otp";
    }
    if (phoneOTP.value.trim() == "") {
      errors.phoneOTP = "Please enter your phone otp";
    }
    return errors;
  };

  const formikOTP = useFormik({
    initialValues: {
      emailOTP: emailOTP.value,
      phoneOTP: phoneOTP.value,
    },
    validate: (values) => handleValidationOTP(values),
    onSubmit: (values) => {
      identityUpdateRequest({ target: { id: "verify" } });
    },
  });

  const resetForm = () => {
    emailOTP.saveValue("");
    phoneOTP.saveValue("");
    identityValue.saveValue(componentData?.edit);
  };
  const identityChangeCancel = () => {
    resetForm();
    setIdentityChange("identity");
  };

  const resendOtp = (event) => {
    event.preventDefault();

    let param = {}
    switch (variant) {
      case 'nmls':
        param = {
          email: profileData.email,
          type: event.target.value,
          service: SERVICE_TYPE.changeNmlsId,
        }
        break;
      case 'companyNmls':
        param = {
          email: profileData.email,
          type: event.target.value,
          service: SERVICE_TYPE.changeCompanyNmlsId,
        }
        break;
      case 'email':
        param = {
          email: identityValue.value,
          type: event.target.value,
          service: SERVICE_TYPE.emailChange,
        }
        break;
      default:
    }

    getApiCallDynamic({ param: param, path: API.verifyOTP }).then((res) => {
      if (res?.status === 200) {
        if (event.target.value === "email") {
          emailOtpCount.setCounter(res.data.emailOtpCount);
        }
        if (event.target.value === "phone") {
          phoneOtpCount.setCounter(res.data.phoneOtpCount);
        }
      }
    });
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={6} my={1}>
        <Typography variant="h3">{componentData.title}</Typography>
        <Typography variant="body1" my={1} color="text.secondary">
          {componentData.description}
        </Typography>
        {componentData.nmlsStatus === "" ? (
          <Box display={identityChange == "identity" ? "" : "none"}>
            <Button
              startIcon={<EditOutlined />}
              variant="outlined"
              onClick={() => {
                setIdentityChange("newIdentityValue");
              }}
            >
              Edit
            </Button>
          </Box>
        ) :
          componentData.nmlsStatus === NMLS_STATUS_VALID ? null : (
            <Box display={identityChange == "identity" ? "" : "none"}>
              <Button
                startIcon={<EditOutlined />}
                variant="outlined"
                onClick={() => {
                  setIdentityChange("newIdentityValue");
                }}
              >
                Edit
              </Button>
            </Box>
          )}
      </Grid>
      <Grid item xs={12} sm={12} md={4} my={1}>
        <Box my={2} display={identityChange === "identity" ? "" : "none"}>
          <CustomInput
            label={componentData.title}
            size="small"
            fullWidth
            value={identityValue.value}
            disabled
          />
        </Box>
        <form id="newIdentityValue" onSubmit={formik.handleSubmit}>
          <Box my={2} display={identityChange === "newIdentityValue" ? "" : "none"}>
            <CustomInput
              size="small"
              type={componentData.type}
              change={identityValue}
              fullWidth
              label={componentData.label}
              placeholder={componentData.placeholder}
              error={formik.errors.identityValue}
            />
            <Box my={2} display={"flex"} justifyContent={"space-between"}>
              <Button
                size="large"
                color="primary"
                onClick={identityChangeCancel}
                type="button"
                variant="outlined"
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                size="large"
                color="primary"
                sx={{ borderRadius: "24px", fontWeight: "bold" }}
                id="newIdentityValue"
                type="submit"
              >
                Update
              </Button>
            </Box>
          </Box>
        </form>
        {/* Use for OTP */}
        <form onSubmit={formikOTP.handleSubmit}>
          <Box display={identityChange === "verify" ? "" : "none"}>
            <Typography my={2}>
              We have sent OTP to your registered mobile number & new email
            </Typography>

            {/* Used for  making inline*/}
            <Grid container spacing={1} alignItems="center" mb={2}>
              <Grid item xs={12}>
                <FormHelperText
                  sx={{
                    color: "blue",
                    width: "25%",
                    float: "right",
                    textAlign: "right",
                  }}
                >
                  {emailOtpCount.counter == 0 ? (
                    <MuLink
                      component="button"
                      id={"identityChange"}
                      value="email"
                      onClick={resendOtp}
                    >
                      {" "}
                      Resend
                    </MuLink>
                  ) : (
                    emailOtpCount.obj.m + " min " + emailOtpCount.obj.s + " sec"
                  )}
                </FormHelperText>
                <CustomInput
                  label={"Enter OTP sent on your new email"}
                  size="small"
                  error={formikOTP.errors.emailOTP}
                  change={emailOTP}
                  fullWidth
                  placeholder="Email OTP"
                  maxLength={MAXLength.otp}
                />
              </Grid>
            </Grid>
            {/* Used for  making inline*/}
            <Grid container spacing={1} alignItems="center" mb={2}>
              <Grid item xs={12}>
                <FormHelperText
                  sx={{
                    color: "blue",
                    width: "25%",
                    float: "right",
                    textAlign: "right",
                  }}
                >
                  {phoneOtpCount.counter == 0 ? (
                    <MuLink
                      component="button"
                      id={"identityChange"}
                      value="phone"
                      onClick={resendOtp}
                    >
                      {" "}
                      Resend
                    </MuLink>
                  ) : (
                    phoneOtpCount.obj.m + " min " + phoneOtpCount.obj.s + " sec"
                  )}
                </FormHelperText>
                <CustomInput
                  label={"Enter OTP sent on your phone"}
                  size="small"
                  error={formikOTP.errors.phoneOTP}
                  change={phoneOTP}
                  fullWidth
                  placeholder="Phone OTP"
                  maxLength={MAXLength.otp}
                />
              </Grid>
            </Grid>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                type="button"
                onClick={identityChangeCancel}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                size="large"
                color="primary"
                id="verify"
                type="submit"
              >
                Verify
              </Button>
            </Box>
          </Box>
        </form>
      </Grid>
    </Grid>
  );
}
