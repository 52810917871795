import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {formState} from '../../../../../../recoil/profile-atom';
import {postApiCallDynamicWithOutReturn} from '../../../../../../services/api-service';
import {API} from '../../../../../../services/api-config';
import {UPDATE} from '../../../../../../constants/constatnt-variable';
import {Demogrpahic} from './demographic-form';

export default function LoanDemographics() {
    const route = useNavigate()
    const location = useLocation();
    const partnerInfoPath = location.pathname.split("/")[location.pathname.split("/").length - 2]

    const [formValue, setFormValue] = useRecoilState(formState)

    let [demographic, setDemographic] = useState(() => null);

    let [formValueId, setFormValueId] = useState(() => null);

    useEffect(() => {
        if (partnerInfoPath == "partner-info") {
            setDemographic(formValue.partnerInfo?.page?.demographics?.subPage)
            setFormValueId(formValue.id)

        } else {
            setDemographic(formValue.borrowerInfo?.page?.demographics?.subPage)
            setFormValueId(formValue.id)

        }
    }, [formValue.partnerInfo?.page?.demographics?.subPage, formValue.borrowerInfo?.page?.demographics?.subPage, formValue.id])




    const onSubmit = async data => {

        let path, updateState
        if (partnerInfoPath == "partner-info") {
            path = API.partnerInfoDemographic
            updateState = {
                ...formValue,
                partnerInfo: {
                    ...formValue.partnerInfo,
                    page: {
                        ...formValue.partnerInfo.page,
                        demographics: {
                            ...formValue.partnerInfo.page.demographics,
                            subPage: data,
                            isComplete: true
                        }
                    }
                }
            }
        } else {
            path = API.borrowerInfoDemographic
                updateState = {
                    ...formValue,
                    borrowerInfo: {
                        ...formValue.borrowerInfo,
                        page: {
                            ...formValue.borrowerInfo.page,
                            demographics: {
                                ...formValue.borrowerInfo.page.demographics,
                                subPage: data,
                                isComplete: true
                            }
                        }
                    }
                }
        }


        const apiCallData = {
            page: {
                subPage: data,
                isComplete: true
            },
            isComplete: true,
            formId: formValue.id
        }

        const setDemographicInrecoil = () => {
            setFormValue(updateState)
            route(location.pathname.replace(/[^/]*$/, 'declarations'))
        }

        await postApiCallDynamicWithOutReturn({
            data: apiCallData,
            path: path,
            refresh: setDemographicInrecoil,
            header: UPDATE,
        })

    }

    return (
        <>
            {

                formValueId == null ?
                    <></>
                    :
                    <Demogrpahic partnerInfoPath={partnerInfoPath} data={demographic} onSubmit={onSubmit} />

            }
        </>
    )
}
