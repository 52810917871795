import {
  Box,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import LoanSideBar from "./loan-side-bar";
import {
  formState,
  globalSpinner,
  profileState,
} from "../../../recoil/profile-atom";
import { API } from "../../../services/api-config";
import { getApiCallDynamic } from "../../../services/api-service";
import LoanTopBar from "./loan-top-bar";
import MainLoyOut from "../../main-layout";
import { Close } from "@mui/icons-material";
import { AlertSeverity } from '../../../helpers/alert-msg';


export default function LoanLayout() {
  const fetcher = useParams();
  let [formData, setFormData] = useRecoilState(formState);
  let [profileData, setProfileData] = useRecoilState(profileState);
  let [spinner, setSpinner] = useRecoilState(globalSpinner);

  const [mobileNav, setMobileNav] = useState(false);

  const mobileNvbarOpen = () => {
    setMobileNav(true);
  };

  const mobileNvbarClose = () => {
    setMobileNav(false);
  };

  let [apiCalled, setApiCalled] = useState(false);

  const route = useNavigate();
  const location = useLocation();

  const navigatetoFromPage = (url) => {
    route(url);
  };

  const updateFormState = () => {
    setSpinner(true);
    getApiCallDynamic({
      path: API.formData,
      param: { formId: fetcher.id },
    }).then((res) => {
      if (res?.status === 200) {
        setFormData(res.data);
        navigatetoFromPage(res?.data?.currentFormPanel);
        setApiCalled(true);
      } else {
        navigatetoFromPage("/");
      }
      setSpinner(false);
    });
  };
  useEffect(() => {
    updateFormState();
  }, [formData.hasPartner]);
  if (!apiCalled) {
    return <></>;
  }
  return (
    <MainLoyOut p={0}>
      <Grid
        container
        height={"calc(100vh - 64px)"}
        flexWrap={"nowrap"}
        direction={"column"}
        overflow={"hidden"}
      >
        <Grid item flexGrow={0}>
          <Box position={'relative'}>
            <Box position={'absolute'} width={'100%'} zIndex={50}>
              <AlertSeverity />
            </Box>
            <LoanTopBar mobileNvbarOpen={mobileNvbarOpen} />
          </Box>
        </Grid>
        <Grid
          item
          container
          flexGrow={1}
          flexWrap={"nowrap"}
          overflow={"hidden"}
        >
          <Grid
            item
            bgcolor={"#FAFAFA"}
            px={1}
            flexGrow={0}
            overflow={"auto"}
            minWidth={"max-content"}
          >
            <Box
              component={"div"}
              width={"100%"}
              height={"100%"}
              display={{ xs: "none", sm: "block" }}
              minWidth={"max-content"}
            >
              <LoanSideBar formData={formData} profileData={profileData} />
            </Box>
          </Grid>
          <Grid item xs={12} flexGrow={1} overflow={"hidden"}>
            <Box
              component={"div"}
              width={"100%"}
              height={"100%"}
              overflow={"auto"}
            >
              {apiCalled ? <Outlet /> : null}
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Drawer
        variant="temporary"
        open={mobileNav}
        onClose={mobileNvbarClose}
        sx={{ zIndex: 1201 }}
      >
        <Grid
          container
          direction={"column"}
          flexWrap={"nowrap"}
          overflow={"hidden"}
        >
          <Grid item>
            <Stack
              p={1}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              borderColor={"primary.main"}
              bgcolor={"primary.main"}
              color={"white"}
            >
              <Typography variant="h5">{`Application Menu`}</Typography>
              <IconButton onClick={mobileNvbarClose} color="inherit">
                <Close />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item overflow={"auto"}>
            <LoanSideBar
              formData={formData}
              profileData={profileData}
              mobileNvbarClose={mobileNvbarClose}
            />
          </Grid>
        </Grid>
      </Drawer>
    </MainLoyOut >
  );
}
