import React, { useEffect, useState } from "react";
import MainLoyOut from "../../../../components/main-layout";
import { useLocation, useNavigate } from "react-router-dom";
import { getApiCallDynamic } from "../../../../services/api-service";
import { API } from "../../../../services/api-config";
import PreapprovalForm from "./preapproval-form";

export default function Preapproval() {

    const route = useNavigate();
    const location = useLocation();
    const leadId = location.pathname.split("/")[location.pathname.split("/").length - 1];

    let [leadData, setleadData] = useState({});

    const getLeadData = () => {
        getApiCallDynamic({ path: API.lead, param: { leadId: leadId } }).then((res) => {

            if (res?.status === 200) {
                setleadData(res.data)
            } else {
                route("/broker/leads")
            }
        })

    }

    useEffect(() => {
        if (leadId) {
            getLeadData()
        }
    }, [leadId])

    if (_.isEmpty(leadData)) {
        return <></>
    }




    return (
        <MainLoyOut>
            <PreapprovalForm leadData={leadData} />
        </MainLoyOut>
    );
}
