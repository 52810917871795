import { AttachMoney } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import CustomInput from '../../../../../../../helpers/custom-input'
import CustomDropDown from '../../../../../../../helpers/dropdown/custom-dropdown'
import CustomLargeLabel from '../../../../../../../helpers/large-label'
import CustomRadioChip from '../../../../../../../helpers/radio-chip'
import { Controller, useForm } from 'react-hook-form'
import { OWNERSHIP, STATE_OF_COUNTRY } from '../../../../../../../constants/constatnt-variable'
import { SelfEmployedValidation } from '../../../../../../../constants/validation-rules'
import { TimeStampToMonthDateYearForInput } from '../../../../../../../helpers/time-formater'

export default function SelfEmployedForm({ onSubmit, editItem }) {



    const { control, handleSubmit, formState: { errors }, watch } = useForm({
        defaultValues: {
            ownerShip: editItem?.ownerShip,
            businessName: editItem?.businessName,
            positionOfTitle: editItem?.positionOfTitle,
            startDate: TimeStampToMonthDateYearForInput(editItem?.startDate),
            profession: editItem?.profession,
            addressLine1: editItem?.addressLine1,
            addressLine2: editItem?.addressLine2,
            unit: editItem?.unit,
            city: editItem?.city,
            stateOfCountry: editItem?.stateOfCountry,
            zip: editItem?.zip,
            averageMonthlyBonus: editItem?.sideIncome?.averageMonthlyBonus,
            averageMonthlyOvertime: editItem?.sideIncome?.averageMonthlyOvertime,
            militaryEntitlement: editItem?.sideIncome?.militaryEntitlement,
            commission: editItem?.sideIncome?.commission,
        }
    })
    return (
        <form id='selfEmployed' onSubmit={handleSubmit(onSubmit)}>
            {/* <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}> */}

            <Controller
                name='ownerShip'
                control={control}
                rules={SelfEmployedValidation.ownerShip}
                render={({ field }) => (
                    <CustomRadioChip
                        {...field}
                        field={field}
                        required
                        label={'Owernship'}
                        data={OWNERSHIP}
                        error={errors?.ownerShip?.message}
                    />
                )}
            />

            <Controller
                name='businessName'
                control={control}
                rules={SelfEmployedValidation.businessName}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={'Employer or Business Name'} required error={errors?.businessName?.message} />
                )}
            />

            <Controller
                name='positionOfTitle'
                control={control}
                rules={SelfEmployedValidation.positionOfTitle}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={'Position or Title'} required error={errors?.positionOfTitle?.message} />
                )}
            />

            <Controller
                name='startDate'
                control={control}
                rules={SelfEmployedValidation.startDate}
                render={({ field }) => (
                    <CustomInput {...field} type={'date'} label={'Start Date'} required error={errors?.startDate?.message} />
                )}
            />

            <Controller
                name='profession'
                control={control}
                rules={SelfEmployedValidation.profession}
                render={({ field }) => (
                    <CustomInput {...field} type={'number'} label={'Years in this line of profession '} required error={errors?.profession?.message} />
                )}
            />

            <Typography variant='h6'>{`Business Address`}</Typography>

            <Controller
                name='addressLine1'
                control={control}
                rules={SelfEmployedValidation.addressline1}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={'Address Line 1'} required error={errors?.addressLine1?.message} />
                )}
            />

            <Controller
                name='addressLine2'
                control={control}
                rules={SelfEmployedValidation.addressline2}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={'Address Line 2'} required error={errors?.addressLine2?.message} />
                )}
            />

            <Controller
                name='unit'
                control={control}
                rules={SelfEmployedValidation.unit}
                render={({ field }) => (
                    <CustomInput {...field} type={'Number'} label={'Unit'} required error={errors?.unit?.message} />
                )}
            />

            <Controller
                name='city'
                control={control}
                rules={SelfEmployedValidation.city}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={'City'} required error={errors?.city?.message} />
                )}
            />

            <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 0, md: 2 }}>
                <Box width={'100%'}>
                    <Controller
                        name='stateOfCountry'
                        control={control}
                        rules={SelfEmployedValidation.stateOfCountry}
                        render={({ field }) => (
                            <CustomDropDown
                                {...field}
                                field={field}
                                label={'State'}
                                required
                                option={STATE_OF_COUNTRY}
                                error={errors?.stateOfCountry?.message}
                            />
                        )}
                    />
                </Box>
                <Box width={'100%'}>
                    <Controller
                        name='zip'
                        control={control}
                        rules={SelfEmployedValidation.zip}
                        render={({ field }) => (
                            <CustomInput {...field} type={'text'} label={'ZIP'} required error={errors?.zip?.message} />
                        )}
                    />
                </Box>
            </Stack>

            <CustomLargeLabel value={`Income details`} />
            <Controller
                name='averageMonthlyBonus'
                control={control}
                rules={SelfEmployedValidation.averageMonthlyBonus}
                render={({ field }) => (
                    <CustomInput {...field} type={'number'} label={`Average monthly bonus`} sicon={<AttachMoney />} required error={errors?.averageMonthlyBonus?.message} />
                )}
            />
            <Controller
                name='averageMonthlyOvertime'
                control={control}
                rules={SelfEmployedValidation.averageMonthlyOvertime}
                render={({ field }) => (
                    <CustomInput {...field} type={'number'} label={`Average monthly overtime`} sicon={<AttachMoney />} required error={errors?.averageMonthlyOvertime?.message} />
                )}
            />
            <Controller
                name='militaryEntitlement'
                control={control}
                rules={SelfEmployedValidation.militaryEntitlement}
                render={({ field }) => (
                    <CustomInput {...field} type={'number'} label={`Military entitlement `} sicon={<AttachMoney />} required error={errors?.militaryEntitlement?.message} />
                )}
            />
            <Controller
                name='commission'
                control={control}
                rules={SelfEmployedValidation.commission}
                render={({ field }) => (
                    <CustomInput {...field} type={'number'} label={`Average monthly commission`} sicon={<AttachMoney />} required error={errors?.commission?.message} />
                )}
            />
            <Stack direction={'row-reverse'} spacing={2}>
                <Button form={'selfEmployed'} variant='contained' type='submit'>
                    {editItem.length != 0 ? 'Update' : 'Add'}
                </Button>
            </Stack>
            {/* </Stack> */}
        </form>
    )
}
