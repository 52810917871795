import React from 'react'
import { AttachMoney } from '@mui/icons-material'
import { Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material'
import CustomInput from '../../../../../../../helpers/custom-input'
import CustomInputSideCheckbox from '../../../../../../../helpers/custom-input-side-checkbox'

import { Controller, useForm, useWatch } from 'react-hook-form'
import { PropertyAddress } from '../../../../../../../components/loan-application/property-address'
import { usPhoneNumberFormatDefaultValue } from '../../../../../../../constants/validation'
import { TimeStampToMonthDateYearForInput } from '../../../../../../../helpers/time-formater'
import { SecondaryEmployedValidation } from '../../../../../../../constants/validation-rules'

export default function SecondaryEmploymentForm({ onSubmit, editItem }) {




    const { control, handleSubmit, formState: { errors }, watch } = useForm({
        defaultValues: {
            employmentStatus: editItem?.employmentStatus,
            businessName: editItem?.businessName,
            employNumber: usPhoneNumberFormatDefaultValue(editItem?.employNumber),
            positionOfTitle: editItem?.positionOfTitle,
            startDate: TimeStampToMonthDateYearForInput(editItem?.startDate),
            endDate: TimeStampToMonthDateYearForInput(editItem?.endDate),
            profession: editItem?.profession,
            monthlyIncome: editItem?.monthlyIncome,
            addressLine1: editItem?.addressLine1,
            addressLine2: editItem?.addressLine2,
            unit: editItem?.unit,
            city: editItem?.city,
            stateOfCountry: editItem?.stateOfCountry,
            zip: editItem?.zip,
            isStillWorking: editItem?.isStillWorking == "Yes" ? true : false,
        }
    })

    const { stateofCountry, isStillWorking } = useWatch({ control })

    return (
        <form id={'secondaryEmployed'} onSubmit={handleSubmit(onSubmit)}>
            {/* <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}> */}

            <Controller
                name='businessName'
                control={control}
                rules={SecondaryEmployedValidation.businessName}
                render={({ field }) => (
                    <CustomInput {...field} {...field} type={'text'} label={`Employer or Business Name`} required error={errors?.businessName?.message} />
                )}
            />
            <Controller
                name='employNumber'
                control={control}
                rules={SecondaryEmployedValidation.employNumber}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={`Employer Number`} required error={errors?.employNumber?.message} />
                )}
            />

            <Controller
                name='positionOfTitle'
                control={control}
                rules={SecondaryEmployedValidation.positionOfTitle}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={`Position or Title`} required error={errors?.positionOfTitle?.message} />
                )}
            />

            <Controller
                name='startDate'
                control={control}
                rules={SecondaryEmployedValidation.startDate}
                render={({ field }) => (
                    <CustomInput
                        {...field}
                        type={'date'}
                        label={`Start Date`}
                        labelRightComponent={
                            <FormControlLabel
                                control={
                                    <Controller
                                        name='isStillWorking'
                                        control={control}
                                        render={({ field }) => (
                                            <Checkbox checked={isStillWorking}  {...field} />
                                        )}
                                    />
                                }
                                label=
                                {`Are you still working there?`}
                            />
                        }
                        required
                        error={errors?.startDate?.message}
                    />
                )}
            />
            {isStillWorking ? null : <Controller
                name='endDate'
                control={control}
                rules={SecondaryEmployedValidation.endDate}
                render={({ field }) => (
                    <CustomInput {...field} type={'date'} label={`End Date`} required error={errors?.endDate?.message} />
                )}
            />}

            <Controller
                name='profession'
                control={control}
                rules={SecondaryEmployedValidation.profession}
                render={({ field }) => (
                    <CustomInput {...field} type={'number'} label={`Years in this line of profession `} required error={errors?.profession?.message} />
                )}
            />
            <Controller
                name='monthlyIncome'
                control={control}
                rules={SecondaryEmployedValidation.monthlyIncome}
                render={({ field }) => (
                    <CustomInput {...field} type={'number'} label={`Base Monthly Income `} sicon={<AttachMoney />} required error={errors?.monthlyIncome?.message} />
                )}
            />

            <Typography variant='h6'>{`Employer or Business Address`}</Typography>
            <PropertyAddress control={control} Controller={Controller} errors={errors} stateOfCountry={stateofCountry} />
            <Stack direction={'row-reverse'} spacing={2}>
                <Button form={'secondaryEmployed'} variant='contained' type='submit'>
                    {editItem.length != 0 ? 'Update' : 'Add'}
                </Button>
            </Stack>
            {/* </Stack> */}
        </form >
    )
}
