import * as React from "react";
import {Box, Button, Divider, Link as MuiLink} from "@mui/material";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Badge from "@mui/material/Badge";
import {styled} from "@mui/material/styles";
import UserImg from "../../src/assets/profileDemo.png";
import {makeStyles} from "@mui/styles";

const customStyle = makeStyles({
  UserImage: {
    width: "50px",
    height: "50px",
    borderRadius: "10px",
  },
});

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -15,
    padding: "0 4px",
    borderRadius: "5px ",
    backgroundColor: "#F2F2F2",
  },
}));

export default function Notificaiton() {
  const classes = customStyle();
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box width={{ xl: "20vw", lg: "30vw", md: "40vw", sm: "50vw", xs: "90vw" }}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        p={2}
      >
        <Typography variant="h4">Notification</Typography>
        <MuiLink sx={{ cursor: "pointer" }}>Mark all as read</MuiLink>
      </Box>
      <Box>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} variant={"fullWidth"}>
              <Tab
                label="Loan updates"
                value="1"
                iconPosition="end"
                icon={<StyledBadge badgeContent={3} max={99}></StyledBadge>}
              />

              <Tab
                label="General"
                value="2"
                iconPosition="end"
                icon={<StyledBadge badgeContent={5} max={99}></StyledBadge>}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Box maxHeight={"50vh"} overflow={"auto"}>
              {/* notificaiton card */}
              <Box display={"flex"} mb={1}>
                <img src={UserImg} className={classes.UserImage} />
                <Box ml={2}>
                  <Typography variant="body2" fontWeight={"bold"}>
                    Arthur Shelby
                    <Typography variant="body2" component={"span"} pl={"3px"}>
                      has been added to your team!
                    </Typography>
                  </Typography>
                  <Typography variant="caption">2h ago</Typography>
                </Box>
              </Box>

              <Divider />
              {/* notificaiton card */}
              <Box display={"flex"} my={1}>
                <img src={UserImg} className={classes.UserImage} />
                <Box ml={2}>
                  <Typography variant="body2" fontWeight={"bold"}>
                    Finn
                    <Typography variant="body2" component={"span"} pl={"3px"}>
                      has been added to your team!
                    </Typography>
                  </Typography>
                  <Typography variant="caption">2h ago</Typography>
                </Box>
              </Box>

              <Divider />
              {/* notificaiton card */}
              <Box display={"flex"} my={1}>
                <img src={UserImg} className={classes.UserImage} />
                <Box ml={2}>
                  <Box mb={1}>
                    <Typography variant="body2" fontWeight={"bold"}>
                      Complete your profile now!
                    </Typography>
                    <Typography variant="caption">2h ago</Typography>
                  </Box>
                  <Button variant="contained" size="small">
                    Accept
                  </Button>
                </Box>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value="2">General</TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}
