import {Box, Button, FormHelperText, Grid, Link as MuLink, Typography} from '@mui/material';
import {useFormik} from 'formik';
import React from 'react'
import {MAXLength} from '../../constants/validation';
import CustomInput from '../custom-input';
import useInput from '../use-input';

export default function OTPValidation({
    emailOtpCount,
    phoneOtpCount,
    onlyVerifyBtn = false,
    setShow,
    resendOtp,
    phoneNoChange,
    setOpenModal,
    variant,
    title = "Verify OTP from User",
    description = "We have sent OTP to user’s registered new mobile number and user’s email",
    emailOtpLabel = "Enter OTP sent on your new email",
    phoneOtpLabel = "Enter OTP sent on your phone"
}) {
    let [emailOTP] = useInput({
        data: "",
        status: false,
        initial: "",
    });

    let [phoneOTP] = useInput({
        data: "",
        status: false,
        initial: "",
    });

    const formikOTP = useFormik({
        initialValues: {
            emailOTP: emailOTP.value,
            phoneOTP: phoneOTP.value,
        },
        validate: (values) => handleValidationOTP(values),
        onSubmit: (values) => {

            phoneNoChange(emailOTP, phoneOTP);


            // { target: { id: "verifyUser" } }
        },
    });


    const handleValidationOTP = (values) => {
        let errors = {};

        if (emailOTP.value.trim() == "") {
            errors.emailOTP = "Please enter your email otp";
        }
        if (phoneOTP.value.trim() == "") {
            errors.phoneOTP = "Please enter your phone otp";
        }
        return errors;
    };


    const handleClose = () => {
        emailOTP.saveValue("")
        phoneOTP.saveValue("")
        setShow("updateContact")
        setOpenModal(false)
    }

    return (
        <Box sx={{ px: { lg: 2, md: 2 } }}>
            <form onSubmit={formikOTP.handleSubmit}>

                <Typography my={2} variant="h3">
                    {title}
                </Typography>
                <Typography my={2}>
                    {description}
                </Typography>


                {/* Used for  making inline*/}
                <Grid container spacing={1} alignItems="center" mb={2}>
                    <Grid item xs={12}>
                        <FormHelperText
                            sx={{
                                color: "blue",
                                width: "25%",
                                float: "right",
                                textAlign: "right",
                            }}
                        >
                            {phoneOtpCount.counter == 0 ? (
                                <MuLink
                                    component="button"
                                    value="phone"
                                    onClick={resendOtp}
                                >
                                    {" "}
                                    Resend
                                </MuLink>
                            ) : (
                                phoneOtpCount.obj.m + " min " + phoneOtpCount.obj.s + " sec"
                            )}
                        </FormHelperText>
                        <CustomInput
                            label={variant == "email" ? "Enter OTP sent on mobile no." : phoneOtpLabel}
                            size="small"
                            error={formikOTP.errors.phoneOTP}
                            change={phoneOTP}
                            fullWidth
                            placeholder="Phone OTP"
                            maxLength={MAXLength.otp}
                        />
                    </Grid>
                </Grid>
                {/* Used for  making inline*/}
                <Grid container spacing={1} alignItems="center" mb={2}>
                    <Grid item xs={12}>
                        <FormHelperText
                            sx={{
                                color: "blue",
                                width: "25%",
                                float: "right",
                                textAlign: "right",
                            }}
                        >
                            {emailOtpCount.counter == 0 ? (
                                <MuLink
                                    component="button"
                                    value="email"
                                    onClick={resendOtp}
                                >
                                    {" "}
                                    Resend
                                </MuLink>
                            ) : (
                                emailOtpCount.obj.m + " min " + emailOtpCount.obj.s + " sec"
                            )}
                        </FormHelperText>
                        <CustomInput
                            label={variant == "email" ? "Enter OTP sent on user’s new email" : emailOtpLabel}
                            size="small"
                            error={formikOTP.errors.emailOTP}
                            change={emailOTP}
                            fullWidth
                            placeholder="Email OTP"
                            maxLength={MAXLength.otp}
                        />
                    </Grid>
                </Grid>
                <Box display={"flex"} justifyContent={"space-between"}>
                    {onlyVerifyBtn ? null :
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            type="button"
                            onClick={() => { handleClose() }}
                        >
                            Cancel
                        </Button>
                    }
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        id="verify"
                        type="submit"
                        fullWidth={onlyVerifyBtn}
                    >
                        Verify
                    </Button>
                </Box>
            </form>
        </Box>
    )
}
