import React, { useEffect, useState } from "react";
import { Button, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import CustomInput from "../../helpers/custom-input";
import { MailOutlined } from "@mui/icons-material";
import { Path } from "../../routes/route-config";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useInput from "../../helpers/use-input";
import { useFormik } from "formik";
import TermCondition from "../../components/term-condition";
import { MAXLength, ValidationConstant } from "../../constants/validation";
import { ROLE_LIST, SERVICE_TYPE } from "../../constants/constatnt-variable";
import { API } from "../../services/api-config";
import { postApiCallDynamic } from "../../services/api-service";
import loginImage from "../../assets/bg_sign.png";

export default function InvitationRegForm(props) {
  const { state, ...location } = useLocation();
  const [spinner, setspinner] = useState(false);
  const website = state?.website
  const router = useNavigate();


  // if there is no state with the page.
  //  it will redirect to landing page
  useEffect(() => {
    if (state == null) {
      router(Path.landing);
    }

  }, []);

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  let [email] = useInput({
    data: state?.email,
    status: !state?.website?.profileUri,
    initial: "",
  });

  let [contactNumber] = useInput({
    data: state?.phone,
    status: true,
    initial: "",
  });

  let [firstName] = useInput({
    data: "",
    status: true,
    initial: "",
  });

  let [lastName] = useInput({
    data: "",
    status: true,
    initial: "",
  });

  let [entityName] = useInput({
    data: "",
    status: true,
    initial: "",
  });

  let [companyName] = useInput({
    data: "",
    status: true,
    initial: "",
  });

  let [suffix] = useInput({
    data: "",
    status: true,
    initial: "",
  });
  let [altContactNumber] = useInput({
    data: "",
    status: true,
    initial: "",
  });

  let [nmlsId] = useInput({
    data: "",
    status: true,
    initial: "",
  });

  let [termConditon, setTermCondtion] = useState(false);
  let [opt, setOpt] = useState(false);


  const handleSubmit = () => {
    let data = {
      firstName: firstName.value,
      lastName: lastName.value,
      entityName: entityName.value,
      companyName: companyName.value,
      email: email.value,
      contactNumber: contactNumber.value,
      suffix: suffix.value,
      altContactNumber: altContactNumber.value,
      nmlsId: nmlsId.value,
      service: state?.service,
      tempToken: state?.tempToken,
      updatesOverSMS: opt,
      ...(website && { "website": website })
    };

    if (state?.website?.profileUri) {
      postApiCallDynamic(
        {
          data: data,
          refresh: (res) => {
            return res;
          },
          path: API.signup
        }
      ).then((res) => {
        if (
          res &&
          (res.status === 200 || res.status === 201 || res.status === 202)
        ) {


          router(Path.otp, {
            state: { ...state, ...res.data, ...res.requestData, from: SERVICE_TYPE.signup },
          });
        }
      });

    }
    else {
      router(Path.setPassword, {
        state: { ...data, from: state?.from },
      });
    }

  };
  const handleValidation = (values) => {
    let errors = {};
    if (
      state?.role === ROLE_LIST.mlo ||
      state?.role === ROLE_LIST.mlp ||
      state?.role === ROLE_LIST.broker
    ) {
      //do nothing
    } else {
      if (suffix.value == "") {
        errors.suffix = "Please enter Suffix";
      }
      if (
        ValidationConstant.usPhoneNumber.test(String(altContactNumber.value)) === false
      ) {
        errors.altContactNumber = "Please enter valid alternate contact number";
      }
    }

    if (firstName.value.trim() == "") {
      errors.firstName = "Please enter your First Name";
    }

    if (lastName.value.trim() == "") {
      errors.lastName = "Please enter your Last Name";
    }

    if (
      state?.role === ROLE_LIST.mlo) {
      if (entityName.value.trim() == "") {
        errors.entityName = "Please enter your entity Name";
      }
    }

    if (
      state?.role === ROLE_LIST.mlp
    ) {
      if (companyName.value.trim() == "") {
        errors.companyName = "Please enter your company Name";
      }
    }

    if (email.value.trim() == "") {
      errors.email = "Please enter your Email Id";
    }
    if (ValidationConstant.usPhoneNumber.test(String(contactNumber.value)) === false) {
      errors.contactNumber = "Please enter valid Contact Number";
    }
    if (state?.role === ROLE_LIST.mlo) {
      if (nmlsId.value.trim() == "") {
        errors.nmlsId = "Please enter your nmls Id";
      }
    }

    if (termConditon == false) {
      errors.termConditon = "Please accept Terms and Conditions";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      suffix: suffix.value,
      firstName: firstName.value,
      lastName: lastName.value,
      entityName: entityName.value,
      companyName: companyName.value,
      email: email.value,
      contactNumber: contactNumber.value,
      altContactNumber: altContactNumber.value,
      nmlsId: nmlsId.value,
      termConditon: "",
    },
    validate: (values) => handleValidation(values),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid
          item
          xs={false}
          md={6}
          fontWeight={"bold"}
          p={{ sx: 0, md: 5 }}
          height="100vh"
          justifyContent={{ sm: "space-between", md: "initial" }}
          flexDirection={{ xs: "column" }}
          className="bgTablet"
        >
          <Grid
            item
            container
            md={12}
            sm={12}
            xs={false}
            display={{ xs: "none", sm: "block" }}
          >
            <Grid item height={"30vh"}>
              <Box p={5}>
                <Typography
                  mt={4}
                  sx={{
                    fontFamily: "inter",
                    fontSize: "32px",
                    fontWeight: 200,
                    color: "#888888",
                    lineHeight: "48px",
                  }}
                >
                  The Best Homes are
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "inter",
                    fontSize: "32px",
                    fontWeight: 200,
                    color: "#888888",
                    lineHeight: "48px",
                  }}
                >
                  Financed through
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "inter",
                    fontSize: "60px",
                    fontWeight: 700,
                    color: "#2F80ED",
                  }}
                >
                  Kramasoft
                </Typography>
              </Box>
            </Grid>
            <Grid item height={"60vh"}>
              <Box width={{ xs: "100%", sm: "80%", md: "75%" }}>
                <img src={loginImage} width={"100%"} style={{ marginTop: "20%" }} />
              </Box>
            </Grid>
          </Grid>

        </Grid>
        <Grid item xs={12} sm={6} sx={{ height: "100vh", overflow: "auto" }}>
          {/* back button */}
          <Grid container md={12} justifyContent="space-between">
            <Grid item p={2}>
              {/* <Button
                onClick={() => {
                  router(-1);
                }}
              >
                <ArrowBackOutlinedIcon />
              </Button> */}
            </Grid>

          </Grid>
          <Box p={2}>
            <Grid container md={12} justifyContent="space-between">
              <Grid item>

              </Grid>
              <Grid item sx={{ textAlign: "right" }}>
                <Typography>
                  Already a member? &nbsp;

                  <Link to={Path.signin} state={website && state} style={{ color: "#2f80ed" }}>
                    Sign In
                  </Link>

                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box px={{ xs: 2, md: 20 }} pb={{ xs: 2, md: 3 }}>
            <Typography variant="h4" fontWeight="bold" mb={3}>
              Register
            </Typography>

            {/* all input field */}
            <Box
              display={
                state?.role === ROLE_LIST.mlo || state?.role === ROLE_LIST.mlp ? "none" : ""
              }
              my={2}
            >
              <CustomInput
                label={"Suffix"}
                placeholder={"Enter Suffix"}
                change={suffix}
                error={formik.errors.suffix}
                maxLength={MAXLength.name}
                required
              />
            </Box>

            <Box my={2}>
              <CustomInput
                label={"First Name"}
                placeholder={"Enter First Name"}
                change={firstName}
                error={formik.errors.firstName}
                maxLength={MAXLength.name}
                required
              />
            </Box>

            <Box my={2}>
              <CustomInput
                change={lastName}
                label={"Last Name"}
                placeholder={"Enter Last Name"}
                error={formik.errors.lastName}
                maxLength={MAXLength.name}
                required
              />
            </Box>

            <Box my={2} display={
              state?.role === ROLE_LIST.mlo ? null : "none"
            }>
              <CustomInput
                change={entityName}
                label={"Entity Name"}
                placeholder={"Enter Entity Name"}
                error={formik.errors.entityName}
                maxLength={MAXLength.entityName}
                required
              />
            </Box>

            <Box my={2} display={
              state?.role === ROLE_LIST.mlp ? null : "none"
            }>
              <CustomInput
                change={companyName}
                label={"Company Name"}
                placeholder={"Enter Company Name"}
                error={formik.errors.companyName}
                maxLength={MAXLength.entityName}
                required
              />
            </Box>

            <Box my={2}>
              <CustomInput
                change={email}
                disabled={state?.website?.profileUri ? false : true}
                label={"Email ID"}
                placeholder={"Enter Email Id"}
                sicon={<MailOutlined />}
                error={formik.errors.email}
                maxLength={MAXLength.email}
                required
              />
            </Box>

            <Box my={2}>
              <CustomInput
                type="number"
                change={contactNumber}
                label={"Contact Number"}
                placeholder={"Please enter Contact Number"}
                sicon={<LocalPhoneOutlinedIcon />}
                error={formik.errors.contactNumber}
                required
              />
            </Box>

            <Box my={2} display={state?.role === ROLE_LIST.mlo || state?.role === ROLE_LIST.mlp ? "none" : null}>
              <CustomInput
                type="number"
                change={altContactNumber}
                label={"Alternate Contact Number"}
                placeholder={"Please enter Alternate Contact Number"}
                sicon={<LocalPhoneOutlinedIcon />}
                error={formik.errors.altContactNumber}
                required
              />
            </Box>
            <Box my={2} display={state?.role === ROLE_LIST.mlo ? null : "none"}>
              <CustomInput
                type="nmlsId"
                change={nmlsId}
                label={"Your NMLS ID"}
                placeholder={"Please enter Nmls Id"}
                error={formik.errors.nmlsId}
                required
              />
            </Box>

            {/* Checkbox */}
            <TermCondition
              formik={formik}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              open={open}
              setTermCondtion={setTermCondtion}
              termConditon={termConditon}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={opt}
                  onChange={(e) => {
                    setOpt((prev) => !prev);
                  }}
                />
              }
              sx={{ mr: "0" }}
              label={"Opt in for receiving updates over SMS"}
            ></FormControlLabel>

            <Box my={2}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                sx={{ borderRadius: "24px", fontWeight: "bold" }}
              >
                Register
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}
