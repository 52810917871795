import React from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { Box, Button, Stack, Typography } from '@mui/material'

import { MAXLength } from '../../../constants/validation'
import { ERROR_MESSAGE } from '../../../constants/constatnt-variable'
import CustomInput from '../../../helpers/custom-input'
import { PropertyAddress } from '../property-address'
import { ContactAdditionalValidation } from '../../../constants/validation-rules'

export default function AdditionalAddressForm({ onSubmit, editItem, handleClose }) {




    const { control, handleSubmit, formState: { errors }, watch } = useForm({
        defaultValues: {
            addressLine1: editItem?.addressLine1,
            addressLine2: editItem?.addressLine2,
            unit: editItem?.unit,
            city: editItem?.city,
            stateOfCountry: editItem?.stateOfCountry,
            zip: editItem?.zip,
            year: editItem?.year,
            month: editItem?.month,
        }
    })

    const { state } = useWatch({ control })

    // const onSubmit = async data => {

    //     setModalOpen(true)

    //     handleClose()
    // }



    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box width={'100%'} my={2} >

                <PropertyAddress control={control} Controller={Controller} stateOfCountry={state} errors={errors} />

                <Typography variant='body1'>Period of Stay</Typography>
                <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 0, md: 2 }}>
                    <Box width={'100%'}>
                        <Controller
                            name='year'
                            control={control}
                            rules={ContactAdditionalValidation.year}
                            render={({ field }) => (
                                <CustomInput {...field} type={'number'} label={'Year'} required error={errors?.year?.message} />
                            )}
                        />
                    </Box>
                    <Box width={'100%'}>
                        <Controller
                            name='month'
                            control={control}
                            rules={ContactAdditionalValidation.month}
                            render={({ field }) => (
                                <CustomInput {...field} type={'number'} label={'Month'} required error={errors?.month?.message} />
                            )}
                        />
                    </Box>
                </Stack>
                <Stack direction={'row-reverse'} gap={2} >
                    <Button type='submit' variant='contained' >
                        {editItem.length !== 0 ? 'Update' : 'Add'}
                    </Button>
                    <Button variant='text' onClick={handleClose}>Cancel</Button>
                </Stack>
            </Box>
        </form>
    )
}
