import {ArrowBackIos, ArrowForwardIos} from '@mui/icons-material'
import {Box, Button, IconButton, Stack} from '@mui/material'
import React, {useEffect, useMemo, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import CustomLargeLabel from '../../../../../helpers/large-label'
import {DialogCommonHooks} from '../../../../../helpers/modal/modal'
import CustomReactMaterialTable from '../../../../../helpers/table/react-material-table'
import {AddRealState} from '../../../../../components/loan-application/real-estate/add-real-estate'
import {useRecoilState} from 'recoil'
import {formState} from '../../../../../recoil/profile-atom'
import {postApiCallDynamicWithOutReturn} from '../../../../../services/api-service'
import {API} from '../../../../../services/api-config'
import {UPDATE} from '../../../../../constants/constatnt-variable'
import {KSPencilAltIcon, KSTrashIcon} from '../../../../../icons/custome-icon'
import ConfirmationModel from '../../../../../helpers/modal/confirm-modal'

export default function LoanRealEstate() {
    const location = useLocation();
    const route = useNavigate();

    let [formValue, setFormValue] = useRecoilState(formState)

    let [realEstateList, setRealEstateList] = useState(() =>
        formValue?.realEstate?.page == null ? [] : _.cloneDeep(formValue?.realEstate?.page))

    const [editState, setEditState] = useState(() => {
        return null
    })

    // Edit table
    useEffect(() => {
        if (editState != null) {
            let list = _.cloneDeep(realEstateList)
            let editItem = list.find((item, i) => i == editState)
            setSecondRealState(editItem)
            setModalOpen(true)
        }
    }, [editState])


    const [deleteState, setDeleteState] = useState(() => {
        return null;
    })

    //Delete

    useEffect(() => {
        if (deleteState != null) {
            realEstateList.splice(deleteState, 1)
            setRealEstateList([...realEstateList])
            setDeleteState(null)
        }
    }, [deleteState])



    const realEstateColumn = useMemo(
        () => [
            {
                accessorKey: "addressLine1",
                header: "Address",
                enableColumnActions: false,
            },
            {
                accessorKey: "propertyType",
                header: "Property type",
                enableColumnActions: false,
            },
            {
                accessorKey: "city",
                header: "City",
                enableColumnActions: false,
            },
        ],
        []
    );
    //
    const [modalOpen, setModalOpen] = useState(false);
    const [secondRealState, setSecondRealState] = useState();

    const handleClickOpen = () => {
        setSecondRealState([])
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
        setEditState(null)
    };

    const handleCloseDelete = (event, row) => {
        if (event.target.value == 'yes') {
            setDeleteState(row.index)
        }

    }

    const realEstateSubmit = (data) => {

        if (editState != null) {
            realEstateList[editState] = {
                ...realEstateList[editState],
                ...data
            }
            setEditState(null)
        } else {
            realEstateList.push(data);
        }

        setRealEstateList([...realEstateList])
        handleClose()
    }

    useEffect(() => {
        setRealEstateList(realEstateList);
    }, [realEstateList.length])



    const handleSubmit =async () => {
        const apiCallData = {
            page: realEstateList,
            formId: formValue.id,
            isComplete: true
        }

        const updateState = () => {
            setFormValue({
                ...formValue,
                realEstate: {
                    ...formValue.realEstate,
                    page: realEstateList,
                    isComplete: true,
                }
            })
            route(location.pathname.replace(/[^/]*$/, 'credit-report'));
        }

        await postApiCallDynamicWithOutReturn(
            {
                data: apiCallData,
                path: API.realEstate,
                header: UPDATE,
                refresh: updateState
            }
        )
    }



    return (
        <>
            <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                <Box>
                    <CustomLargeLabel value={'Real Estate'} />

                    <CustomReactMaterialTable
                        staticLoad={true}
                        staticData={realEstateList}
                        columns={realEstateColumn}
                        enablePagination={false}
                        enableBottomToolbar={false}
                        enableSorting={false}
                        enableToolbarInternalActions={false}
                        renderTopToolbarCustomActions={
                            () => (
                                <Button variant='outlined' onClick={handleClickOpen}>Add</Button>
                            )
                        }
                        enableRowActions={true}
                        renderRowActions={
                            ({ row }) => {
                                return (
                                    <Stack direction={'row'}>
                                        <IconButton onClick={() => { setEditState(row.index) }}>
                                            <KSPencilAltIcon />
                                        </IconButton>
                                        <ConfirmationModel
                                            body={<IconButton
                                                id={row.index} onClick={() => {

                                                }}>
                                                <KSTrashIcon />
                                            </IconButton>}
                                            message={`Are you sure want remove the real estate ${row?.original?.addressLine1}...?`}
                                            title={`Delete Additional Address`}
                                            handleSubmit={(event) => { handleCloseDelete(event, row) }}
                                        />
                                    </Stack>
                                )
                            }
                        }
                    />


                </Box>
                <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Link to={'../assets-credits'}>
                            <Button startIcon={<ArrowBackIos />}>
                                Previous
                            </Button>
                        </Link>

                        <Link onClick={handleSubmit}>
                            <Button endIcon={<ArrowForwardIos />} >
                                Next
                            </Button>
                        </Link>

                    </Stack>
                </Box>
            </Stack>

            <DialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={`Real estate`}
                body={
                    <AddRealState
                        onSubmit={realEstateSubmit}
                        editItem={secondRealState}
                        handleClose={handleClose} />
                }
            />
        </>
    )
}
