import {ArrowBackIos, ArrowForwardIos} from '@mui/icons-material';
import {Box, Button, Stack, Typography} from '@mui/material'
import React, {useMemo} from 'react'
import {useRecoilState} from 'recoil';
import {DialogCommonHooks} from '../../../../../helpers/modal/modal';
import CustomReactMaterialTable from '../../../../../helpers/table/react-material-table'
import {profileState} from '../../../../../recoil/profile-atom';

export default function PartnerCoBorrowerSet({ data, partnerData, primaryBorrower, hasPartner, handleSubmit, setPage }) {

    data = _.cloneDeep(data).map((v, index) => ({ ...v, initials: `(CB ${index + 1})` }))


    if (hasPartner == "Yes") {
        primaryBorrower = {
            ...primaryBorrower,
            partnerData: partnerData
        }
    }
    let primaryBorrowerList = []
    primaryBorrowerList.push(primaryBorrower)
    let coborrowerList = _.cloneDeep(data)

    let list = _.unionBy(primaryBorrowerList, coborrowerList)


    let [profileData, setProfileData] = useRecoilState(profileState);





    let coBorrowerNamesForConfirm = data.map(a => " " + a.firstName);



    const tableColumn = useMemo(
        () => [
            {
                accessorKey: "firstName",
                header: "Name",
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <>
                        <Typography>
                            {`${row.original["firstName"]} ${row.original["lastName"]} ${row.original.initials ? row.original.initials : ""}`}
                        </Typography>

                        {
                            partnerData?.firstName == null ? '' :
                                row.original["partnerData"] && <Typography>
                                    {`${row.original["partnerData"]["firstName"]} ${row.original["partnerData"]["lastName"]} (Partner)`}
                                </Typography>
                        }
                    </>
                ),
            },
            {
                accessorKey: "phone",
                header: "Mobile Number",
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <>
                        <Typography>
                            {`${row.original["phone"]}`}
                        </Typography>
                        {
                            partnerData?.firstName == null ? '' :
                                row.original["partnerData"] && <Typography>
                                    {`${row.original["partnerData"]["phone"]}`}
                                </Typography>
                        }
                    </>
                ),
            },
            {
                accessorKey: "email",
                header: "Email ID",
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <>
                        <Typography>
                            {`${row.original["email"]}`}
                        </Typography>
                        {
                            partnerData?.firstName == null ? '' :
                                row.original["partnerData"] && <Typography>
                                    {`${row.original["partnerData"]["email"]}`}
                                </Typography>
                        }
                    </>
                ),
            },


        ],
        []
    );

    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalChildOpen, setModalChildOpen] = React.useState(false);

    const handleClickOpen = () => {
        if (data?.length != 0) {
            setModalOpen(true);
        } else {
            handleSubmit()
        }

    };

    const handleClose = () => {
        setModalOpen(false);
    };
    const handleClickChildClose = () => {
        setModalChildOpen(false);
        setModalOpen(false);

    };

    const handleClickChildOpen = () => {
        setModalChildOpen(true);
    };

    const handleChildClose = () => {
        handleSubmit()
        handleClose()
        setModalChildOpen(false);
    };

    return (
        <>
            <Stack direction={'column'} justifyContent={'space-between'} height={'100%'} px={{ xs: 1, md: 4 }}>
                <Box>
                    <Box my={2}>
                        <Typography variant={'h2'}>{`Hello ${profileData?.firstName},`}</Typography>
                        <Typography component={'p'} mt={1}>
                            {`Here's the list of all the borrowers of this loan. Now by pressing next you will get into the 1003 application form.`}
                        </Typography>
                    </Box>
                    <Box my={2}>
                        <CustomReactMaterialTable
                            staticLoad={true}
                            staticData={list}
                            columns={tableColumn}
                            enableTopToolbar={false}
                            enableBottomToolbar={false}
                            enableSorting={false}
                            enableToolbarInternalActions={false}
                            endPoint={"/user"}
                        />
                    </Box>
                </Box>
                <Box my={2} width={{ xs: '100%', md: '50%' }}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Button type='button' startIcon={<ArrowBackIos />} onClick={() => {

                            setPage("co-borrower")
                        }}>
                            Previous
                        </Button>
                        <Button endIcon={<ArrowForwardIos />} onClick={handleClickOpen}>
                            Next
                        </Button>
                    </Stack>
                </Box>
            </Stack>

            <DialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={`Confirm Co-Borrower`}
                body={
                    <>
                        <Box width={'100%'} my={2} >
                            <Box >
                                <Typography textAlign={'justify'} mb={2}>
                                    {
                                        `Are you sure you want to add ${coBorrowerNamesForConfirm} as a co-borrower for this loan?`
                                    }
                                </Typography>

                                <Stack direction={'row-reverse'} spacing={2}>
                                    <Button variant='contained' onClick={() => { handleClickChildOpen() }}>Yes</Button>
                                    <Button variant='text' onClick={() => { handleClose(); handleSubmit() }}>No</Button>
                                </Stack>
                            </Box>
                        </Box>

                    </>
                }
            />

            <DialogCommonHooks
                show={modalChildOpen}
                handleClose={handleClickChildClose}
                title={`Invite Co-Borrower`}
                body={
                    <>
                        <Box width={'100%'} my={2} >

                            <Box >
                                <Typography textAlign={'justify'} mb={2}>
                                    {
                                        `Do you want to send an invitation to these co-borrowers (${coBorrowerNamesForConfirm})? `
                                    }
                                </Typography>


                                <Stack direction={'row-reverse'} spacing={2}>
                                    {/* <Link to={"#"} > */}
                                    <Button onClick={handleChildClose} variant='contained'>Yes</Button>
                                    {/* </Link> */}
                                    <Button variant='text' onClick={handleChildClose}>No</Button>
                                </Stack>
                            </Box>
                        </Box>

                    </>
                }
            />
        </>
    )
}
