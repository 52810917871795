import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ReviewSection from './review-section'
import { useRecoilState, useRecoilValue } from 'recoil'
import { formState, profileState } from '../../../../../recoil/profile-atom'
import { postApiCallDynamicWithOutReturn } from '../../../../../services/api-service'
import { API } from '../../../../../services/api-config'
import { ROLE_LIST, UPDATE } from '../../../../../constants/constatnt-variable'
import UploadDocumentCard from '../documents/upload-document-card'
import { useForm } from 'react-hook-form'

export default function LoanReview() {

    let [formValue, setFormValue] = useRecoilState(formState)
    let profileInfo = useRecoilValue(profileState);

    const location = useLocation();
    const route = useNavigate();

    let {
        getStarted: {
            page: {
                primaryBorrower: primaryBorrowerDetails,
                partner: partnerDetails,
                coBorrower: coBorrowerDetails,
            }
        },
        loanDetails: {
            page: loanDetailsReview
        },
        borrowerInfo: {
            page: {
                personal: {
                    subPage: personalDetails,
                },
                contact: {
                    subPage: {
                        currentAddress: currentAddressDetails,
                        mailingAddress: mailingAddressDetails
                    }
                },
                demographics: {
                    subPage: demographicsDetails
                },
                declarations: {
                    subPage: declarationsDetails
                },
                dependent: {
                    subPage: dependentDetails
                },
                employment: {
                    subPage: {
                        employed: employedDetails,
                        retired: retiredDetails,
                        secondary: secondaryDetails,
                        selfEmployed: selfEmployedDetails,
                    }
                },
                militaryServices: {
                    subPage: militaryServicesDetails
                },
            }
        },

        assetsCredits: {
            page: assetsCreditsDetails
        },
        realEstate: {
            page: realEstateDetails
        },
        liabilities: {
            page: {
                expense: expenseDetails,
                mortgage: mortgageDetails,
                nonMortgage: nonMortgageDetails,
            }
        },
        documents: {
            page: {
                guidedDocument: {
                    subPage: document
                }
            }
        },
    } = formValue
    console.log("documents", document);


    let PARTNER_INFO = []

    const set_partner_info = () => {
        if (formValue.hasPartner) {
            let { partnerInfo: {//7no id
                page: {
                    personal: {
                        subPage: personalDetailsPartner,
                    },
                    contact: {
                        subPage: {
                            currentAddress: currentAddressDetailsPartner,
                            mailingAddress: mailingAddressDetailsPartner
                        }
                    },
                    demographics: {
                        subPage: demographicsDetailsPartner
                    },
                    declarations: {
                        subPage: declarationsDetailsPartner
                    },
                    dependent: {
                        subPage: dependentDetailsPartner
                    },
                    employment: {
                        subPage: {
                            employed: employedDetailsPartner,
                            retired: retiredDetailsPartner,
                            secondary: secondaryDetailsPartner,
                            selfEmployed: selfEmployedDetailsPartner,
                        }
                    },
                    militaryServices: {
                        subPage: militaryServicesDetailsPartner
                    },
                }
            }, } = formValue
            // add employed sub section name for partner
            employedDetailsPartner = employedDetailsPartner.map((e, i) => ({
                ...e, "chidSectionName": `Employed 0${i + 1}`
            }))

            // add retired sub section name for partner
            retiredDetailsPartner = retiredDetailsPartner.map((e, i) => ({
                ...e, "chidSectionName": `Retired 0${i + 1}`
            }))

            // add secondary sub section name for partner
            secondaryDetailsPartner = secondaryDetailsPartner.map((e, i) => ({
                ...e, "chidSectionName": `Secondary 0${i + 1}`
            }))

            // add self Employed sub section name for partner
            selfEmployedDetailsPartner = selfEmployedDetailsPartner.map((e, i) => ({
                ...e, "chidSectionName": `Self Employed 0${i + 1}`
            }))


            PARTNER_INFO = [
                { "chidSectionName": "Personal", ...personalDetailsPartner },
                { "chidSectionName": "Contact", ...currentAddressDetailsPartner },
                mailingAddressDetailsPartner,
                { "chidSectionName": "Demographics", ...demographicsDetailsPartner },
                { "chidSectionName": "Declarations", ...declarationsDetailsPartner },
                { "chidSectionName": "Dependent", ...dependentDetailsPartner },
                // { "chidSectionName": "Employed", ...employedDetailsPartner },
                { "chidSectionName": "Military Services", ...militaryServicesDetailsPartner },
            ].concat(employedDetailsPartner, retiredDetailsPartner, secondaryDetailsPartner, selfEmployedDetailsPartner)
        }
    }

    set_partner_info()



    function renameKeys(obj, newKeys) {
        const keyValues = Object.keys(obj).map(key => {
            const newKey = newKeys[key] || key;
            return { [newKey]: obj[key] };
        });
        return Object.assign({}, ...keyValues);
    }

    coBorrowerDetails = coBorrowerDetails.map((e, i) => ({
        ...e, "chidSectionName": `Co-Borrower 0${i + 1}`
    }))

    const GET_STARTED = [
        { "chidSectionName": "Primary Borrower", ...primaryBorrowerDetails },
        { "chidSectionName": "Partner Info", ...partnerDetails }
    ].concat(coBorrowerDetails)

    const LOAN_DETAILS = [
        loanDetailsReview
    ]

    // add employed sub section name
    employedDetails = employedDetails.map((e, i) => ({
        ...e, "chidSectionName": `Employed 0${i + 1}`
    }))

    // add retired sub section name
    retiredDetails = retiredDetails.map((e, i) => ({
        ...e, "chidSectionName": `Retired 0${i + 1}`
    }))

    // add secondary sub section name
    secondaryDetails = secondaryDetails.map((e, i) => ({
        ...e, "chidSectionName": `Secondary 0${i + 1}`
    }))

    // add self Employed sub section name
    selfEmployedDetails = selfEmployedDetails.map((e, i) => ({
        ...e, "chidSectionName": `Self Employed 0${i + 1}`
    }))

    const BORROWER_INFO = [
        { "chidSectionName": "Personal", ...personalDetails },
        { "chidSectionName": "Contact", ...currentAddressDetails },
        mailingAddressDetails,
        { "chidSectionName": "Demographics", ...demographicsDetails },
        { "chidSectionName": "Declarations", ...declarationsDetails },
        { "chidSectionName": "Dependent", ...dependentDetails },
        // { "chidSectionName": "Employed", ...employedDetails },
        { "chidSectionName": "Military Services", ...militaryServicesDetails },
    ].concat(employedDetails, retiredDetails, secondaryDetails, selfEmployedDetails)


    // add Assets credit sub section name
    assetsCreditsDetails = assetsCreditsDetails.map((e, i) => ({
        ...e, "chidSectionName": `Assets Info (0${i + 1})`
    }))
    const ASSETS_CREDITS = assetsCreditsDetails

    // add Real Estate sub section name
    realEstateDetails = realEstateDetails.map((e, i) => ({
        ...e, "chidSectionName": `Real Estate Info (0${i + 1})`
    }))
    const REAL_ESTATE = realEstateDetails


    // add Mortgate Liability sub section name
    mortgageDetails = mortgageDetails.map((e, i) => ({
        ...e, "chidSectionName": `Mortgate Liability 0${i + 1}`
    }))

    // add Non Mortgate Liability sub section name
    nonMortgageDetails = nonMortgageDetails.map((e, i) => ({
        ...e, "chidSectionName": `Non Mortgate Liability 0${i + 1}`
    }))

    // add Non Mortgate Liability sub section name
    expenseDetails = expenseDetails.map((e, i) => ({
        ...e, "chidSectionName": `Expenses 0${i + 1}`
    }))
    const LIABILITIES = mortgageDetails.concat(nonMortgageDetails, expenseDetails)


    const handleSubmit = async () => {
        const apiCallData = {
            page: {},
            formId: formValue?.id,
            isComplete: true
        }

        const updateState = () => {
            setFormValue({
                ...formValue,
                review: {
                    ...formValue.review,
                    page: {},
                    isComplete: true,
                }
            })
            route(location.pathname.replace(/[^/]*$/, 'submit'));
        }

        await postApiCallDynamicWithOutReturn(
            {
                data: apiCallData,
                path: API.review,
                header: UPDATE,
                refresh: updateState
            }
        )
    }
    const { control } = useForm()
    return (
        <>
            <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                <Box>
                    <Typography variant='h2' mb={2} color={'primary'}>{`Review my application`}</Typography>

                    <ReviewSection
                        sectionInfo={GET_STARTED}
                        sectionName={'Get Started!'}
                        // chidSectionName={'Primary Borrower'}
                        hasPartner={formValue?.hasPartner ? '' : 'This loan has no partner.'}
                        editUrl={'getting-started'}
                        isLocked={formValue?.isLocked}
                    />
                    <ReviewSection
                        sectionInfo={LOAN_DETAILS}
                        sectionName={'Loan Details'}
                        chidSectionName={'Property Details'}
                        editUrl={'loan-details'}
                        isLocked={formValue?.isLocked}
                    />
                    <ReviewSection
                        sectionInfo={BORROWER_INFO}
                        sectionName={'Borrower Info'}
                        editUrl={'borrower-info/personal-information'}
                        isLocked={formValue?.isLocked}
                    />
                    {formValue?.hasPartner ?
                        <ReviewSection
                            sectionInfo={PARTNER_INFO}
                            sectionName={'Partner Info'}
                            editUrl={'partner-info/personal-information'}
                            isLocked={formValue?.isLocked}
                        /> : null
                    }
                    <ReviewSection
                        sectionInfo={ASSETS_CREDITS}
                        sectionName={'Assets & Credits'}
                        editUrl={'assets-credits'}
                        isLocked={formValue?.isLocked}
                    />
                    <ReviewSection
                        sectionInfo={REAL_ESTATE}
                        sectionName={'Real Estate'}
                        editUrl={'real-estate'}
                        isLocked={formValue?.isLocked}
                    />
                    <ReviewSection
                        sectionInfo={LIABILITIES}
                        sectionName={'Liabilities'}
                        editUrl={'liabilities'}
                        isLocked={formValue?.isLocked}
                    />
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant='h3'>{'Document'}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {
                                document.map((item, index) => (
                                    <>
                                        <UploadDocumentCard
                                            key={item.title}
                                            title={item.title}
                                            control={control}
                                            data={item.documents}
                                            description={item.description}
                                            isReview={true}
                                        />


                                    </>
                                ))
                            }
                        </AccordionDetails>
                    </Accordion>

                </Box>

                <Box width={'100%'} mt={2}>
                    <Stack direction={'row-reverse'}>

                        <Button disabled={profileInfo.role == ROLE_LIST.borrower ? formValue?.isLocked : false} variant='contained' onClick={handleSubmit} >
                            Proceed
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        </>
    )
}
