import { Box, Button, Checkbox, FormControlLabel, FormHelperText, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { DialogCommonHooks } from '../../../../../helpers/modal/modal'
import LoanCreditReportStatus from './credit-report-status'
import { useRecoilState } from 'recoil'
import { formState } from '../../../../../recoil/profile-atom'
import { getApiCallDynamic, postApiCallDynamic, postApiCallDynamicWithOutReturn } from '../../../../../services/api-service'
import { API } from '../../../../../services/api-config'
import { UPDATE } from '../../../../../constants/constatnt-variable'
import LoadingOverlay from '@ronchalant/react-loading-overlay'
import { CreditReportValidation } from '../../../../../constants/validation-rules'


export default function LoanCreditReport() {
    let [formValue, setFormValue] = useRecoilState(formState)
    const route = useNavigate()
    const location = useLocation();

    const [creditData, setCreditData] = useState();
    //  const creditData={
    //     isConsentGiven: true,
    //     isPulled: false,
    //     creditReportStatus: false,
    //     pullDate: 16435654,
    //     creditScore: 755,
    //     consentDate: 16435654
    // }

    const [pullState, setPullState] = useState(false)
    useEffect(() => {
        getApiCallDynamic({ path: API.creditReport, param: { formId: formValue.id } })
            .then((res) => {

                if (res?.status === 200) {
                    console.log("ss", res);
                    setCreditData(res.data)
                }
            })
    }, [pullState]);

    let [consentModal, setConsentModal] = useState(false);
    const consentRequiredOpen = () => {
        setConsentModal(true)
    }
    const consentRequiredClose = () => {
        setConsentModal(false)
    }

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            primaryBorrower: false,
            coBorrower: false
        }
    })

    const onSubmit = async data => {
        console.log("data", data);
        pullLoaderOpen()
        const apiCallData = {
            formId: formValue.id
        }


        postApiCallDynamic({
            path: API.credcoPull,
            refresh: (res) => {
                return res;
            },
            data: apiCallData,
        })
            .then((res) => {
                setPullState(true)
                setTimeout(pullLoaderClose, 1000);

            });


        // setTimeout(pullLoaderClose, 4000);

    }



    let [pullLoaderModal, setPullLoaderModal] = useState(false);

    const pullLoaderOpen = () => {
        setPullLoaderModal(true)
    }
    const pullLoaderClose = () => {
        setPullLoaderModal(false)
    }


    const handleCreditSubmit = async () => {
        const apiCallData = {
            page: {
                isConsentGiven: true,
            },
            formId: formValue?.id,
            isComplete: true
        }

        const updateState = () => {
            setFormValue({
                ...formValue,
                creditReport: {
                    ...formValue.creditReport,
                    page: {
                        isConsentGiven: true,
                    },
                    isComplete: true,
                }
            })
            route(location.pathname.replace(/[^/]*$/, 'liabilities'));
        }

        await postApiCallDynamicWithOutReturn(
            {
                data: apiCallData,
                path: API.creditReport,
                header: UPDATE,
                refresh: updateState
            }
        )
    }

    const handlePrevious = () => {
        route(location.pathname.replace(/[^/]*$/, 'real-estate'))
    }

    if (!creditData) {
        return <></>
    }



    return (
        <>{creditData?.isConsentGiven ?
            <LoanCreditReportStatus
                creditData={creditData}
                handleCreditSubmit={handleCreditSubmit}
                handlePrevious={handlePrevious}
            /> :
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                    <Box width={{ xs: '100%', md: '50%' }}>
                        <Typography variant='h3' mb={2}>{`Credit Report`}</Typography>
                        <Typography variant='body1' textAlign={'justify'}>
                            {`
                            By agreeing, I/we authorize the obtaining of a tri-merged consumer credit report through Kramasoft LLC. I/We acknowledge that this credit report will be used to confirm my/our residency address, verify other credit information, including past and present mortgages, and evaluate whether my/our income is sufficient to support the mortgage loan for which I/we have applied. In addition to the credit report, I/We understand that I/we may be asked to verify past and present employment earnings records, bank accounts, stockholdings, and any other asset balances necessary to process the mortgage loan application.
                            I/We acknowledge that the credit report obtained will be used solely to process the mortgage loan application and that this information may only be shared with other agencies directly involved in the processing of the mortgage loan application. I/We understand that credit inquiries may have an impact on my/our credit score.
                            I/We understand that the tri-merged credit report will be retained on file, along with all other loan application documents.
`}
                        </Typography>
                        <FormControlLabel
                            control={
                                <Controller
                                    name='primaryBorrower'
                                    control={control}
                                    rules={CreditReportValidation.primaryBorrower}
                                    render={({ field }) => (
                                        <Checkbox
                                            defaultChecked={false}
                                            {...field}
                                        />
                                    )}
                                />
                            }
                            label=
                            {`
                            I, PB(${formValue?.borrowerInfo?.page?.personal?.subPage?.firstName} ${formValue?.borrowerInfo.page?.personal?.subPage?.lastName}) Agree to consent to do business electronically and to view Loan Documents electronically
                            `}
                        />
                        {errors.primaryBorrower && (
                            <FormHelperText error>{errors.primaryBorrower.message}</FormHelperText>
                        )}
                    </Box>

                    <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Button onClick={consentRequiredOpen}>
                                Cancel
                            </Button>
                            <Button type='submit' variant='contained'>
                                I agree
                            </Button>
                        </Stack>
                    </Box>
                </Stack>
            </form>
        }

            <DialogCommonHooks
                show={consentModal}
                handleClose={consentRequiredClose}
                onlyCloseIcon={true}
                body={
                    <Box width={'100%'} my={4}>
                        <Typography variant='body1'>{`Credit Consent is required to go further.`}</Typography>

                        <Stack direction={'row-reverse'} my={2}>

                            <Button variant='contained' onClick={consentRequiredClose} >
                                ok
                            </Button>
                        </Stack>
                    </Box>
                }
            />
            <DialogCommonHooks
                show={pullLoaderModal}
                handleClose={pullLoaderClose}
                onlyCloseIcon={true}
                body={
                    <LoadingOverlay
                        active={pullLoaderModal}
                        spinner={true}
                        text="Please wait a moment"
                    >
                        <Box width={'100%'} my={4} py={4}>
                            <Typography variant='h3'>{`Pulling Credit Report`}</Typography>
                            <Typography variant='body1'>{`Lorem ipsum dolor sit amet, consectetur adipiscing elit`}</Typography>
                        </Box>
                    </LoadingOverlay>
                }
            />
        </>
    )
}
