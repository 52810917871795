import { CloudUpload, Lock } from '@mui/icons-material'
import { Checkbox, Chip, FormControlLabel, Grid, IconButton, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'

export default function CustomCheckboxFileUpload({
  control,
  status,
  title = null,
  docIc,
  checkData,
  id,
  fileUpload,
  changeCheck,
  isReview,
  isSkiped = false,
  ...props
}) {
  const [checked, setChecked] = useState(() => {
    return checkData ? checkData : "";
  });

  const handleChange = (event) => {
    changeCheck(id)
    setChecked(event.target.checked);

    // callBackForSwitch({ state: event.target.checked })
  };

  return (
    <>
      <Stack direction={'row'} gap={2} alignItems={'start'}>

        {/* Skip Checkbox */}
        {
          isReview || isSkiped ? null : <FormControlLabel
            control={
              <Controller
                name='checkbox'
                control={control}
                render={
                  ({ field }) => (
                    <Checkbox
                      {...field}
                      defaultChecked={checked}
                      onChange={handleChange}
                      color='error'
                    />
                  )
                }
              />
            }
            label="Skip"
            labelPlacement="start"
            sx={{ paddingY: 1.5 }}
          />
        }

        <Grid container my={1} border={1} p={1} borderRadius={'5px'} borderColor={'#11111115'}>

          {/* Document title */}
          <Grid item xs={12} md={6} >
            <Typography my={.5}>{title}</Typography>
          </Grid>

          {/* Skip status label */}
          <Grid item xs={12} md={3} textAlign={'center'}>
            {checked === true ?
              <Chip
                icon={<Lock color='error' />}
                label="Skipped"
                variant="filled"
                size='small'
                sx={{
                  textTransform: 'uppercase',
                  marginY: .5,
                  backgroundColor: '#FF454520',
                  color: 'error.main'
                }}
              />
              :
              null}
          </Grid>

          {/* File upload inputfield */}
          <Grid display={isReview ? 'none' : null} item xs={12} md={3} textAlign={{ xs: 'center', md: 'right' }}>
            <IconButton
              disabled={checked === true ? true : false}
              color="primary"
              component="label"
              sx={{
                borderRadius: '4%',
                padding: '0px'
              }}
            >
              <input id={docIc} onChange={fileUpload} hidden accept="image/*" type="file" />
              <CloudUpload />
              <Typography variant='body1' ml={.5} my={.5}>Upload</Typography>
            </IconButton>
          </Grid>
        </Grid>
      </Stack>

    </>
  )
}
