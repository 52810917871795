import { FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, Typography, } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import MainLoyOut from "../../../components/main-layout";
import { TimeStampToMonthDateYear } from "../../../helpers/time-formater";
import { globalSpinner } from "../../../recoil/profile-atom";
import { API } from "../../../services/api-config";
import { getApiCallDynamic } from "../../../services/api-service";
import CredcoCredentialsForm from "./credco-credentials-form";
import CredcoCredentialsView from "./credco-credentials-view";

export default function CredcoCredentials() {
    let [spinner, setSpinner] = useRecoilState(globalSpinner);



    const [data, setData] = useState(null)

    const getCredcoData = () => {
        setSpinner(true)
        let param = { isUsingBrokerInfo: false };
        getApiCallDynamic({ path: API.credcoCredentials, param: param }).then((res) => {
            if (res?.status === 200) {

                setData(res.data);
                setSpinner(false);

            }
        });
    }

    useEffect(() => {
        getCredcoData()
    }, []);



    const [credcoEditSection, setCredcoEditSection] = useState(false);





    return (
        <MainLoyOut>
            <Paper>
                <Grid container justifyContent="space-between" p={{ xs: 2, md: 5 }}>
                    <Grid
                        item
                        container
                        md={4}
                        direction="column"
                        justifyContent={"space-between"}
                    >
                        <Grid item>
                            <Typography variant="body1" pb={2}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </Typography>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"

                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel checked={"Not validated" == data?.credcoStatus} value="Not validated" control={<Radio />} label="Not validated" />
                                    <FormControlLabel checked={"Valid Credentials" == data?.credcoStatus} value="Valid Credentials" control={<Radio />} label="Valid Credentials" />
                                    <FormControlLabel checked={"Invalid Credentials" == data?.credcoStatus} value="Invalid Credentials" control={<Radio />} label="Invalid Credentials" />
                                </RadioGroup>
                            </FormControl>
                            {/* <RadioButtonGroup data={CREDCO_STATUS} defaultValue={""} state={""} /> */}
                        </Grid>
                        <Grid item my={{ xs: 3, md: 0 }}>
                            <Typography variant="body2">
                                Last Validated: {TimeStampToMonthDateYear(data?.lastValidatedDate)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item md={4}>
                        {

                            credcoEditSection ?
                                < CredcoCredentialsForm
                                    setCredcoEditSection={setCredcoEditSection}
                                    setData={setData}
                                    data={data} />
                                :

                                data?.credcoStatus ?
                                    < CredcoCredentialsView
                                        setCredcoEditSection={setCredcoEditSection}
                                        data={data}
                                    />
                                    :
                                    < CredcoCredentialsForm
                                        setCredcoEditSection={setCredcoEditSection}
                                        setData={setData}

                                        data={data} />
                        }

                    </Grid>
                </Grid>
            </Paper>
        </MainLoyOut >
    );
}
