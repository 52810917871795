import { Box, Typography } from '@mui/material'
import React from 'react'
import CustomCheckboxFileUpload from '../../../../../helpers/custom-checkbox-file-upload'
import DocumentStatusCard from './document-status-card'
import { TimeStampToMonthDateYear } from '../../../../../helpers/time-formater';

export default function UploadDocumentCard({ title, description, data, control, fileUpload, isReview = false, setUpdateState }) {

    const changeCheck = (id) => {
        data[id].isSkipped = !data[id].isSkipped;
    }

    return (
        <>

            {/* Document Upload Start */}
            <Box my={3}>
                <Typography variant='h3' color={'primary'} mb={2} >{title}</Typography>
                <Typography mb={2}>{description}</Typography>

                {/* Before Upload Cards  Start*/}
                {data.map((item, index) => (
                    <>
                        {item.uploadDate ?
                            <DocumentStatusCard
                                key={item.documentName}
                                control={control}
                                title={item.documentName}
                                fileName={item.fileName}
                                info={item.info}
                                docIc={item.id}
                                uploadTime={TimeStampToMonthDateYear(item.uploadDate)}
                                status={item.matchStatus}
                                fileUpload={fileUpload}
                                isReview={isReview}
                                isOverridden={item.isOverridden}
                                validStatus={item.validStatus}
                                overrideReason={item.overrideReason}
                                setUpdateState={setUpdateState}
                            />
                            :

                            <CustomCheckboxFileUpload
                                id={index}
                                key={item.documentName}
                                control={control}
                                title={item.documentName}
                                docIc={item.id}
                                checkData={item.isSkipped}
                                changeCheck={changeCheck}
                                fileUpload={fileUpload}
                                isReview={isReview}
                            />

                        }
                    </>
                ))}

                {/* Before Upload Cards End*/}
            </Box>
        </>
    )
}
