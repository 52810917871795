import { AttachMoney } from '@mui/icons-material'
import { Box, Stack } from '@mui/material'
import React from 'react'
import CustomInput from '../../../../../../../helpers/custom-input'
import CustomLargeLabel from '../../../../../../../helpers/large-label'
import CustomRadioChip from '../../../../../../../helpers/radio-chip'
import { Controller, useWatch } from 'react-hook-form'
import { ExtraIncomeValidation } from '../../../../../../../constants/validation-rules'

export default function LoanExtraIncome({ errors, control }) {


    const { isSideIncomeAvailable } = useWatch({ control })


    return (
        <>
            <Stack direction={'column'} justifyContent={'space-between'} py={2} height={'inherit'} overflow={'auto'}>
                <Box>


                    <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>

                        <Controller
                            name='isSideIncomeAvailable'
                            control={control}
                            rules={ExtraIncomeValidation.isSideIncomeAvailable}
                            render={({ field }) => (
                                <CustomRadioChip
                                    labelComponent={<CustomLargeLabel value={`Do you receive bonus, military entitlement or commission income?`} required />}
                                    {...field}
                                    field={field}
                                    state={isSideIncomeAvailable}
                                    data={[
                                        { id: 'yes', value: "Yes", label: "Yes" },
                                        { id: 'no', value: "No", label: "No" },
                                    ]}
                                    error={errors?.isSideIncomeAvailable?.message}
                                />
                            )}
                        />
                        {isSideIncomeAvailable == 'Yes' ?
                            <>
                                <Controller
                                    name='avgMonthlyIncome'
                                    control={control}
                                    rules={ExtraIncomeValidation.avgMonthlyIncome}
                                    render={({ field }) => (
                                        <CustomInput {...field} type={'number'} label={`Average monthly bonus`} sicon={<AttachMoney />} required error={errors?.avgMonthlyIncome?.message} />
                                    )}
                                />

                                <Controller
                                    name='avgMonthlyOvertime'
                                    control={control}
                                    rules={ExtraIncomeValidation.avgMonthlyOvertime}
                                    render={({ field }) => (
                                        <CustomInput {...field} type={'number'} label={`Average monthly overtime`} sicon={<AttachMoney />} required error={errors?.avgMonthlyOvertime?.message} />
                                    )}
                                />

                                <Controller
                                    name='militaryEntitlement'
                                    control={control}
                                    rules={ExtraIncomeValidation.militaryEntitlement}
                                    render={({ field }) => (
                                        <CustomInput {...field} type={'number'} label={`Military entitlement `} sicon={<AttachMoney />} required error={errors?.militaryEntitlement?.message} />
                                    )}
                                />

                                <Controller
                                    name='avgMonthlyComission'
                                    control={control}
                                    rules={ExtraIncomeValidation.avgMonthlyComission}
                                    render={({ field }) => (
                                        <CustomInput {...field} type={'number'} label={`Average monthly commission`} sicon={<AttachMoney />} required error={errors?.avgMonthlyComission?.message} />
                                    )}
                                />
                            </>
                            : null
                        }

                    </Box>

                </Box>
            </Stack>
        </>
    )
}