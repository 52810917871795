import React from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import LoneOfficer_1 from "../../assets/avater.png";
import { makeStyles } from "@mui/styles";

import { useRecoilValue } from "recoil";
import { profileState } from "../../recoil/profile-atom";
import { KSLinkIcon, KSMailIcon, KSPhoneIcon } from "../../icons/custome-icon";
import { ROLE_LIST, SERVICE_TYPE } from "../../constants/constatnt-variable";

const customStyle = makeStyles({
  customDeclineBtn: {
    backgroundColor: "#F9E0E6",
    color: "#D82E57",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#D82E57",
    },
  },
  customUploadBtn: {
    borderColor: "#999999",
    color: "#999999",
    "&:hover": {
      color: "#FFFFFF",
      borderColor: "#999999",
      backgroundColor: "#999999",
    },
  },


  avterSize: {
    width: 40,
    height: 40,
  },
  chatHead: {
    backgroundColor: "#000435",
    color: "white",
  },
  chatBody: {
    backgroundColor: "#D7E9FD",
    height: "670px",
    overflow: "auto",
  },
  chatSeenIcon: {
    fontSize: "16px",
    color: "#2F80ED",
  },
  chatRight: {
    width: "80%",
    padding: "10px",
    borderRadius: "0 0 10px 10px",
    position: "relative",
    background: "#E3F1FF",
    textAlign: "initial",
    wordBreak: "break-word",
    "&:before": {
      content: '""',
      position: "absolute",
      borderRadius: "5px",
      border: "10px solid transparent",
      borderLeft: "10px solid #E3F1FF",
      transform: "rotate(90deg)",
      right: -10,
      top: -0.5,
    },
  },
  chatLeft: {
    width: "80%",
    padding: "10px",
    borderRadius: "0 0 10px 10px",
    position: "relative",
    background: "#E3F1FF",
    textAlign: "initial",
    wordBreak: "break-word",

    "&:before": {
      content: '""',
      position: "absolute",
      borderRadius: "5px",
      border: "10px solid transparent",
      borderLeft: "10px solid #E3F1FF",
      transform: "rotate(90deg)",
      left: -10,
      top: -0.5,
    },
  },
  chatInput: {
    "& fieldset": {
      border: "none",
    },
  },
  chatDivider: {
    width: "95%",
    margin: "auto",
  },
});

function InvitationData({ invitationData, invitationStatusChange }) {
  const classes = customStyle();

  const [state, setState] = React.useState({
    right: false,
  });

  let profileInfo = useRecoilValue(profileState);

  let service = profileInfo?.role == ROLE_LIST.borrower ? SERVICE_TYPE.invitation : SERVICE_TYPE.teamInvitation

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12} my={3}>
        <Typography variant="h2">Welcome, {profileInfo.firstName + " " + profileInfo.lastName} !</Typography>
      </Grid>

      {/* invitation card 1 */}
      {invitationData.map((data, i) =>
        <Grid
          key={i}
          item
          container
          p={4}
          mb={5}
          borderRadius={"10px"}
          bgcolor={"common.white"}
          justifyContent="space-between"
        >
          <Grid item lg={7} md={5} sm={12} xs={12} margin={"auto"} py={2}>
            <Typography variant="body1" my={3}>
              {profileInfo?.role == ROLE_LIST.mlp ? `${data?.invitedBy?.firstName + " " + data?.invitedBy?.lastName} from ${data?.invitedBy?.company}  has requested you to collaborate with him.`
                : `Your loan officer ${data?.invitedBy?.firstName + " " + data?.invitedBy?.lastName} from ${data?.invitedBy?.company} has invited you
              to fill the loan application.`}
            </Typography>
            <Stack direction={{ xs: 'column', lg: 'row' }} gap={2}>
              <Button variant="contained" onClick={() => { invitationStatusChange(data.id, "Accepted", service) }}>
                {profileInfo?.role == ROLE_LIST.mlp ? `Accept Request` : `Start application`}
              </Button>
              {profileInfo?.role == ROLE_LIST.borrower ?
                <Button variant="outlined" className={classes.customUploadBtn}>
                  {`Upload MISMO file`}
                </Button>
                : null}
              <Button variant="contained" className={classes.customDeclineBtn} onClick={() => { invitationStatusChange(data.id, "Declined", service) }} >
                {`Decline Invitation`}
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={0} md={1} />
          <Grid
            item
            md={6}
            lg={4}
            sm={12}
            xs={12}
            bgcolor={"accent.A2"}
            borderRadius={"10px"}
            container
          >
            <Grid item md={12} container>
              <Grid item md={7} p={2} display={"flex"}>
                <Avatar variant="rounded" src={LoneOfficer_1} />
                <Stack direction={"column"} spacing={0.5} paddingLeft={2}>
                  <Typography color={"primary.main"}>
                    {data?.invitedBy?.firstName + " " + data?.invitedBy?.lastName}
                  </Typography>
                  <Typography color={"primary.main"}>NMLS ID# {data?.invitedBy?.nmlsId}</Typography>
                </Stack>
              </Grid>
              <Grid item md={5} p={2} textAlign={"end"}>
                <Typography color={"primary.main"}>{data?.invitedBy?.company}</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              px={2}
              pb={2}
              container
              alignItems={'center'}
            >
              {/* <Grid item md={4} sm={6} xs={12}>
                {["right"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={<ChatBubbleOutlineIcon />}
                      onClick={toggleDrawer(anchor, true)}
                    >
                      Chat with me
                    </Button>
                    <Drawer anchor={anchor} open={state[anchor]}>
                      {chatArea(anchor)}
                    </Drawer>
                  </React.Fragment>
                ))}
              </Grid> */}
              <Grid item xs={12}>
                <Box
                  display={"flex"}
                  color={"primary.main"}
                  justifyContent={"flex-end"}
                  my={1}
                >
                  <Typography mr={1}>{data?.invitedBy?.phone}</Typography>
                  <KSPhoneIcon />
                </Box>
                <Box
                  display={"flex"}
                  color={"primary.main"}
                  justifyContent={"flex-end"}
                  my={1}
                >
                  <Typography mr={1}>{data?.invitedBy?.email}</Typography>
                  <KSMailIcon />
                </Box>
                <Box
                  display={"flex"}
                  color={"primary.main"}
                  justifyContent={"flex-end"}
                  my={1}
                >
                  <Typography mr={1}>www.kramasoft.com</Typography>
                  <KSLinkIcon />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      )}
    </Grid>
  );
}

export default InvitationData;