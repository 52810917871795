import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import ConfirmationModel from '../../../../../../../helpers/modal/confirm-modal';
import { DialogCommonHooks } from '../../../../../../../helpers/modal/modal';
import CustomReactMaterialTable from '../../../../../../../helpers/table/react-material-table';
import { DateToTimestapmFormater, TimeStampToMonthDateYearForInput } from '../../../../../../../helpers/time-formater';
import { KSPencilAltIcon, KSTrashIcon } from '../../../../../../../icons/custome-icon';
import EmployedForm from './employed-form';

export default function Employed({ setValue, setEmployedList, employedList }) {


    const [editState, setEditState] = useState(() => {
        return null
    })


    useEffect(() => {
        setValue('employed', employedList)
    }, [employedList])


    // Edit table
    useEffect(() => {
        if (editState != null) {
            let list = _.cloneDeep(employedList)
            let editItem = list.find((item, i) => i == editState)
            setEmployedEdit(editItem)
            setModalOpen(true);
        }

    }, [editState])


    const [deleteState, setDeleteState] = useState(() => {
        return null
    })


    // delete table
    useEffect(() => {
        if (deleteState != null) {
            employedList.splice(deleteState, 1)
            setEmployedList([...employedList])
            setDeleteState(null)
        }

    }, [deleteState])

    const tableColumn = useMemo(
        () => [
            {
                accessorKey: "businessName",
                header: "Employer or Business Name",
                enableColumnActions: false,
            },
            {
                accessorKey: "positionOfTitle",
                header: "Position",
                enableColumnActions: false,
            },
            {
                accessorKey: "employmentStatus",
                header: "Status",
                enableColumnActions: false,
            },
            {
                accessorFn: (row) => {
                    return (
                        <Typography>{TimeStampToMonthDateYearForInput(row.startDate)}</Typography>
                    )
                },
                accessorKey: "startDate",
                header: "Start date",
                enableColumnActions: false,
            },
        ],
        []
    );

    const [modalOpen, setModalOpen] = useState(false);
    const [employedEdit, setEmployedEdit] = useState();

    const handleModalOpen = () => {
        setEmployedEdit([])
        setModalOpen(true);
    }

    const handleClose = () => {
        setModalOpen(false);
        setEditState(null)
    }

    const handleCloseDelete = (event, row) => {

        if (event.target.value == 'yes') {
            setDeleteState(row.index)

        }
    }
    const employedSubmit = async data => {
        let list = _.cloneDeep(employedList)
        data = Object.assign(data, {
            startDate: DateToTimestapmFormater(data?.startDate),
            endDate: DateToTimestapmFormater(data?.endDate),
            isStillWorking: data?.isStillWorking ? 'Yes' : 'No',
            isCurrent: "Yes",
            employmentStatus: "Employed"
        })

        if (editState != null) {
            list[editState] = {
                ...list[editState],
                ...data
            }


            setEditState(null)
        } else {
            list.push(data)

        }

        setEmployedList(list)
        handleClose();

    }




    return (
        <Box>
            <Typography variant='h3' mb={1} mt={3} >{`Employed Details`}</Typography>
            {/* <Typography variant='body1' mb={2} >{`Lorem Ipsum is simply dummy text of the printing and typesetting industry.`}</Typography> */}

            <CustomReactMaterialTable
                staticLoad={true}
                staticData={employedList}
                columns={tableColumn}
                enablePagination={false}
                enableBottomToolbar={false}
                enableSorting={false}
                enableToolbarInternalActions={false}
                renderTopToolbarCustomActions={
                    () => (
                        <Button onClick={handleModalOpen} variant='outlined' >Add</Button>
                    )
                }
                enableRowActions={true}
                renderRowActions={
                    ({ row }) => {

                        return (
                            <Stack direction={'row'}>
                                <IconButton onClick={() => { setEditState(row.index) }}>
                                    <KSPencilAltIcon />
                                </IconButton>


                                <ConfirmationModel
                                    body={<IconButton
                                        id={row.index} >
                                        <KSTrashIcon />
                                    </IconButton>}
                                    message={`Are you sure want remove ${row.original.businessName} ?`}
                                    title={'Delete '}
                                    handleSubmit={(event) => { handleCloseDelete(event, row) }}
                                />

                            </Stack>
                        )
                    }
                }
            />

            <DialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={`Employed Info`}
                body={
                    <EmployedForm
                        onSubmit={employedSubmit}
                        editItem={employedEdit}
                    />
                }
            />
        </Box>
    )
}
