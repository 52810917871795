import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { ERROR_MESSAGE, MODAL_ACTION, ROLE_LIST } from "../../../../../../constants/constatnt-variable";
import CustomInput from "../../../../../../helpers/custom-input";

export default function OverriddeReasonForm({
  overrideReasonOnSubmit,
  overrideValidInvalidStatus,
  overrideReason,
  handleClose,
  role,
  fileName
}) {
  const OverrideReasonRules = {
    overrideReason: {
      required: { value: true, message: ERROR_MESSAGE.required },
    },

  }
  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      overrideReason: overrideReason
    }
  })


  return (
    <form id="override" onSubmit={handleSubmit(overrideReasonOnSubmit)}>
      <Typography variant="h3">{role == ROLE_LIST.borrower ? 'Reasons for Override' : 'Overriden Document'}</Typography>
      <Typography display={role == ROLE_LIST.borrower ? "none" : ""} variant="h5" mt={2}>{fileName}</Typography>
      <Controller
        name='overrideReason'
        control={control}
        rules={OverrideReasonRules.overrideReason}
        render={({ field }) => (
          <CustomInput
            {...field}
            disabled={role !== ROLE_LIST.borrower}
            placeholder={'This loan is in the process'}
            size="large"
            multiline
            rows={10}
            error={errors?.overrideReason?.message}
          />
        )}
      />

      <Box display={role == ROLE_LIST.borrower ? "none" : "flex"} gap={2} justifyContent="flex-end" m={1}>
        <Button variant="outlined" onClick={() => overrideValidInvalidStatus("invalid")}>
          {`INVALID`}
        </Button>
        <Button variant="contained" onClick={() => overrideValidInvalidStatus("valid")}>
          {`VALID`}
        </Button>
      </Box>

      <Box display={role !== ROLE_LIST.borrower ? "none" : "flex"} gap={2} justifyContent="flex-end" m={1}>
        <Button variant="outlined" onClick={handleClose}>
          {`Cancel`}
        </Button>
        <Button form='override' type='submit' variant="contained" >
          {`Save`}
        </Button>
      </Box>
    </form>
  );
}
