import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, Typography, } from "@mui/material";
import React, { useState } from "react";
import { KSPlusCircleIcon } from "../../icons/custome-icon";

export default function CustomDropDown({
  option,
  defaultValue,
  changeCallBack = () => {
    // Nothing

  },
  id,
  justifyContent = 'center',
  state,
  label,
  required = false,
  field,
  labelComponent,
  labelRightComponent,
  error = null
}) {
  const [status, setStatus] = useState(() => {
    return defaultValue ? defaultValue : null;
  });

  const handleChange = (event) => {
    state?.saveValue(event.target.value)
    changeCallBack({ id: id, value: event.target.value });
    setStatus(event.target.value);
  };

  return (
    <Box justifyContent={justifyContent} mt={.5} mb={2}>
      {labelComponent ? labelComponent :
        <Stack direction={'row'} justifyContent={'space-between'}>
          <InputLabel>
            {label}
            {required ?
              <Typography component={'span'} color={'error.main'} >*</Typography>
              : null
            }
          </InputLabel>
          <InputLabel>
            {labelRightComponent}
          </InputLabel>
        </Stack>
      }
      <FormControl fullWidth>
        <Select
          displayEmpty
          value={status}
          // onChange={handleChange}
          defaultValue={null}
          {...(field ? field : { onChange: handleChange })}>
          <MenuItem name={null} value={null}>
            {`Select`}
          </MenuItem>
          {option.map((item) => (
            <MenuItem key={item.value} name={item.label} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {(error && <FormHelperText sx={{ pl: 2 }} error>{error}</FormHelperText>)
      }
    </Box >
  );
}
