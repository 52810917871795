import { GroupAdd } from "@mui/icons-material";
import { Box, FormControl, Grid, IconButton, MenuItem, Select, Tooltip, Typography, } from "@mui/material";
import React, { useMemo, useState } from "react";
import CustomReactMaterialTable from "../../../helpers/table/react-material-table";
import CustomDropDown from "../../../helpers/dropdown/custom-dropdown";
import ConfirmationModel from "../../../helpers/modal/confirm-modal";
import { KSUserGroupIcon } from "../../../icons/custome-icon";
import { postApiCallDynamicWithOutReturn } from "../../../services/api-service";
import { API } from "../../../services/api-config";
import { UPDATE } from "../../../constants/constatnt-variable";

export default function ReassignMLP({ MlpList }) {
  const [tableReload, setTableReload] = useState(() => false);


  const tableColumns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Team members",
        enableColumnActions: false,
        Cell: ({ cell, column, row, table }) => {
          return (
            <Typography>
              {`${row.original.firstName} ${row.original.lastName}`}
            </Typography>
          );
        },
      },
      //column definitions...
      {
        accessorKey: "reassignTo",
        header: "Re-assign to",
        enableColumnActions: false,
        Cell: ({ cell, column, row, table }) => {
          return (
            <Box sx={{ maxWidth: 120 }}>
              <CustomDropDown
                option={
                  MlpList.map((i) => {
                    return {
                      value: i.userCode,
                      label: i.firstName + " " + i.lastName,
                    };
                  })
                }
                defaultValue={row.original.reassignTo}
                id={row.original.id}
                changeCallBack={assignMlpValue}
              />
            </Box>
          );
        },
      },
      {
        accessorKey: "id",
        header: "Action",
        enableColumnActions: false,
        enableSorting: false,

        Cell: ({ cell, column, row, table }) => {
          let reAssignName
          MlpList.map(a => {
            if (a.userCode == data?.to) {
              reAssignName = a.firstName
            }
          });
          return (
            <Box justifyContent={"center"}>
              <ConfirmationModel
                body={
                  <Tooltip title="Re-assign MLP" arrow placement="right">
                    <IconButton color="primary">
                      <KSUserGroupIcon />
                    </IconButton>
                  </Tooltip>
                }
                disabled={data?.id !== row?.original?.id}
                handleSubmit={() => {
                  handleReassign(row?.original?.id, row?.original?.userCode);
                }}
                message={
                  `Are you sure you want to reassign all the loans assigned from ${row?.original?.firstName} to ${reAssignName}?`
                }
                title={"Confirmation"}
              />

            </Box>
          );
        },
      },

      //end
    ],
    []
  );

  const [changedValue, setChangedValue] = useState(() => { });
  let data

  const assignMlpValue = ({ id, value }) => {
    data = { id: id, to: value }
    setChangedValue(data)
  }

  const handleReassign = async (id, from) => {
    let apiCallData = {
      from: from,
      to: data.to
    }
    if (id == data?.id) {
      await postApiCallDynamicWithOutReturn({
        data: apiCallData,
        refresh: () => {
          setTableReload((prev) => !prev);
        },
        path: API.reAssign,
        header: UPDATE
      });
    }
  }


  return (
    <Grid container>
      {/* Left Column in md */}
      <Grid item xs={12}>
        <Box mb={3}>
          <Typography variant="h3">Reassign MLP</Typography>
          <Typography variant="body1" color="text.secondary">
            Re-assign roles, add or remove members in a team.
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <CustomReactMaterialTable
          staticLoad={true}
          staticData={MlpList}
          // endPoint={API.teams}
          // params={{ role: 'mlp' }}
          columns={tableColumns}
          refetch={tableReload}
          enablePagination={false}
          enableBottomToolbar={false}
          enableSorting={false}
          enableToolbarInternalActions={false}
          enableTopToolbar={false}
        />
      </Grid>
    </Grid>
  );
}
