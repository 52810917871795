import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton,} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

export const DialogCommonHooks = (props) => {
  return (
    <Dialog
      fullWidth
      maxWidth={props.size}
      open={props.show}
      // onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle
        sx={{ fontWeight: "bold", display: props?.title ? "" : "none" }}
      // style={{
      //   borderTop: " 5px solid #2f80ed",
      //   backgroundColor: " #f5f9fa",
      //   color: "#2f80ed",
      //   paddingBottom: " 10px",
      // }}
      >
        {props?.title}

        <IconButton
          aria-label="close"
          onClick={props.handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>


      <IconButton
        aria-label="close"
        onClick={props.handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "black",
        }}
      >
        <CloseIcon sx={{ display: props?.onlyCloseIcon ? null : "none" }} />
      </IconButton>


      <DialogContent>{props.body}</DialogContent>
      {props.footer ? (
        <DialogActions sx={{ py: 3 }}>{props.footer}</DialogActions>
      ) : null}
    </Dialog>
  );
};


