import React from 'react'
import { ArrowBackIos, ArrowForwardIos, NavigateNext } from '@mui/icons-material'
import { Box, Breadcrumbs, Button, Link as MLink, Stack, Typography } from '@mui/material'
import { Controller, useForm, useWatch } from 'react-hook-form'
import {
    MARITAL_STATUS,
    STATUS_OF_CITIZENSHIP,
    SUFFIX,
    VISA_TYPE
} from '../../../../../../constants/constatnt-variable'
import CustomInput from '../../../../../../helpers/custom-input'
import CustomInputSideCheckbox from '../../../../../../helpers/custom-input-side-checkbox'
import CustomDropDown from '../../../../../../helpers/dropdown/custom-dropdown'
import CustomLargeLabel from '../../../../../../helpers/large-label'
import CustomRadioChip from '../../../../../../helpers/radio-chip'
import { Link } from 'react-router-dom'
import { TimeStampToMonthDateYearForInput } from '../../../../../../helpers/time-formater'
import { PersonalInfoValidation } from '../../../../../../constants/validation-rules'


// Form State builder 
const PersonalForm = ({ onSubmit, data, partnerInfoPath }) => {

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            firstName: data?.firstName,
            lastName: data?.lastName,
            middleName: data?.middleName,
            ssn: data?.ssn,
            suffix: data?.suffix,
            dateOfBirth: TimeStampToMonthDateYearForInput(data?.dateOfBirth),
            statusOfCitizenship: data?.statusOfCitizenship,
            visatype: data?.visatype,
            maritalStatus: data?.maritalStatus
        },
    })

    const { statusOfCitizenship, maritalStatus } = useWatch({ control })


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                <Box>
                    <Breadcrumbs aria-label="breadcrumb"
                        separator={<NavigateNext fontSize="small" />}
                    >
                        <MLink underline="hover" color="inherit" href="#">
                            {`${partnerInfoPath == "partner-info" ? "Partner" : "Borrower"} Profile`}
                        </MLink>
                        <Typography color="primary.main">Personal</Typography>
                    </Breadcrumbs>
                    <Box maxWidth={{ xs: '100%', md: '50%' }}>
                        <CustomLargeLabel value={'Tell us more about yourself'} required />
                        <Controller
                            name='firstName'
                            control={control}
                            rules={PersonalInfoValidation.firstName}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    type={'text'}
                                    label={'First Name'}
                                    required
                                    error={errors?.firstName?.message}
                                />
                            )}
                        />

                        <Controller
                            name='middleName'
                            control={control}
                            rules={PersonalInfoValidation.middleName}
                            render={({ field }) => (
                                <CustomInput {...field} type={'text'} label={'Middle Name'} error={errors?.middleName?.message} />
                            )}
                        />

                        <Controller
                            name='lastName'
                            control={control}
                            rules={PersonalInfoValidation.lastName}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    type={'text'}
                                    label={'Last Name'}
                                    required
                                    error={errors?.lastName?.message}
                                />
                            )}
                        />
                        <Controller
                            name='suffix'
                            control={control}
                            rules={PersonalInfoValidation.suffix}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    required
                                    label={`Suffix`}
                                    option={SUFFIX}
                                    error={errors?.suffix?.message}
                                />
                            )}
                        />
                        <Controller
                            name='ssn'
                            control={control}
                            rules={PersonalInfoValidation.ssn}
                            render={({ field }) => (
                                <CustomInputSideCheckbox
                                    {...field}
                                    type={'number'}
                                    label={'SSN'}
                                    checkLabel={'ITIN'}
                                    required
                                    error={errors?.ssn?.message}
                                />
                            )}
                        />

                        <Controller
                            name='dateOfBirth'
                            control={control}
                            rules={PersonalInfoValidation.dateOfBirth}
                            render={({ field }) => (
                                <CustomInput {...field}
                                    type={'date'}
                                    label={'Date of Birth'}
                                    required
                                    error={errors?.dateOfBirth?.message}

                                />

                            )}
                        />
                        <Controller
                            name='statusOfCitizenship'
                            control={control}
                            rules={PersonalInfoValidation.statusOfCitizenship}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    state={statusOfCitizenship}
                                    required
                                    label={'Citizenship'}
                                    data={STATUS_OF_CITIZENSHIP}
                                    error={errors?.statusOfCitizenship?.message}
                                />
                            )}
                        />

                        {statusOfCitizenship == 'Non US Citizen' ?
                            <>
                                <Controller
                                    name='visatype'
                                    control={control}
                                    rules={PersonalInfoValidation.visatype}
                                    render={({ field }) => (
                                        <CustomDropDown
                                            {...field}
                                            field={field}
                                            required
                                            label={'Visa type'}
                                            option={VISA_TYPE}
                                            error={errors?.visatype?.message}
                                        />
                                    )}
                                />
                            </>
                            : null
                        }

                        <Controller
                            name='maritalStatus'
                            control={control}
                            rules={PersonalInfoValidation.maritalStatus}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    state={maritalStatus}
                                    required
                                    label={'Marital Status'}
                                    data={MARITAL_STATUS}
                                    error={errors?.maritalStatus?.message}
                                />
                            )}
                        />
                    </Box>
                </Box>
                <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Link to={'../../loan-details'}>
                            <Button startIcon={<ArrowBackIos />}>
                                Previous
                            </Button>
                        </Link>
                        <Button type='submit' endIcon={<ArrowForwardIos />}>
                            Next
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        </form>
    )

}
export default PersonalForm;