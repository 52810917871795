import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useRecoilState} from 'recoil'
import {UPDATE} from '../../../../../../constants/constatnt-variable'
import {DateToTimestapmFormater} from '../../../../../../helpers/time-formater';
import {formState} from '../../../../../../recoil/profile-atom';
import {API} from '../../../../../../services/api-config'
import {postApiCallDynamicWithOutReturn} from '../../../../../../services/api-service'
import PersonalForm from './personal-info-form'


// This page is for fetching data from recoil
// Submitting data to server
// Redirect to new page as per Bussiness
export default function LoanPersonalInfo() {
    const route = useNavigate()
    const location = useLocation();
    const partnerInfoPath = location.pathname.split("/")[location.pathname.split("/").length - 2]

    let [formValue, setFormValue] = useRecoilState(formState)

    let [personalInfo, setPersonalInfo] = useState(() => null)

    useEffect(() => {
        if (partnerInfoPath == "partner-info") {
            setPersonalInfo(formValue.partnerInfo?.page?.personal?.subPage)
        } else {
            setPersonalInfo(formValue.borrowerInfo?.page?.personal?.subPage)

        }


    }, [formValue.partnerInfo?.page?.personal?.subPage, formValue.borrowerInfo?.page?.personal?.subPage])

    // Server Api call
    const handleSubmit = async data => {
        let path, updateState
        if (partnerInfoPath == "partner-info") {
            path = API.partnerInfoPersonal
            updateState = {
                ...formValue,
                partnerInfo: {
                    ...formValue.partnerInfo,
                    page: {
                        ...formValue.partnerInfo.page,
                        personal: {
                            ...formValue.partnerInfo.page.personal,
                            subPage: data,
                            isComplete: true
                        }
                    }
                }
            }
        } else {
            path = API.borrowerInfoPersonal
                updateState = {
                    ...formValue,
                    borrowerInfo: {
                        ...formValue.borrowerInfo,
                        page: {
                            ...formValue.borrowerInfo.page,
                            personal: {
                                ...formValue.borrowerInfo.page.personal,
                                subPage: data,
                                isComplete: true
                            }
                        }
                    }
                }
        }
        data = Object.assign(data, { dateOfBirth: DateToTimestapmFormater(data?.dateOfBirth) })

        const apiCallData = {
            page: {
                subPage: data,
                isComplete: true
            },
            formId: formValue.id,
            isComplete: true
        }
        const personalInfoUpdateInRecoil = () => {
            setFormValue(updateState)
            route(location.pathname.replace(/[^/]*$/, 'contact-information'));
        }

        await postApiCallDynamicWithOutReturn(
            {
                data: apiCallData,
                path: path,
                header: UPDATE,
                refresh: personalInfoUpdateInRecoil
            }
        )
    }

    return (
        <>
            {personalInfo == null ?
                <></>
                :
                <PersonalForm data={personalInfo} partnerInfoPath={partnerInfoPath} onSubmit={handleSubmit} />

            }
        </>
    )
}
