import { Box, Button } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import CustomInput from '../../helpers/custom-input'
import { GettingStartedCoBorrowerValidation } from '../../constants/validation-rules'

export default function PartnerDetailsForm({ onSubmit, editItem }) {

    const { reset, register, control, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: {
            firstName: editItem?.firstName,
            lastName: editItem?.lastName,
            email: editItem?.email,
            phone: editItem?.phone,
            // loanCoBorrowerStatus: '',
        },
    })


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} >
                <Box width={'100%'} my={2} >
                    <Controller
                        name="firstName"
                        control={control}
                        rules={GettingStartedCoBorrowerValidation.firstName}
                        render={({ field }) => (
                            <CustomInput
                                {...field}
                                label="First Name"
                                required
                                error={errors?.firstName?.message}
                            />
                        )}
                    />

                    <Controller
                        name="lastName"
                        control={control}
                        rules={GettingStartedCoBorrowerValidation.lastName}
                        render={({ field }) => (
                            <CustomInput
                                {...field}
                                label="Last Name"
                                required
                                error={errors?.lastName?.message}
                            />
                        )}
                    />

                    <Controller
                        name="phone"
                        control={control}
                        rules={GettingStartedCoBorrowerValidation.phone}
                        render={({ field }) => (
                            <CustomInput
                                {...field}
                                label="Phone Number,"
                                required
                                type="number"
                                error={errors?.phone?.message}
                            />
                        )}
                    />

                    <Controller
                        name="email"
                        control={control}
                        rules={GettingStartedCoBorrowerValidation.email}
                        render={({ field }) => (
                            <CustomInput
                                {...field}
                                label="Email"
                                required
                                error={errors?.email?.message}
                            />
                        )}
                    />

                    <Box textAlign={'right'}>
                        <Button type='submit' variant='contained' >Submit</Button>
                    </Box>
                </Box>

            </form>
        </>
    )
}
