import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainLoyOut from "../../../components/main-layout";
import SupportEmpty from "./support-empty";
import { UserCard } from "./user-card";
import { getApiCallDynamic } from "../../../services/api-service";
import { API } from "../../../services/api-config";
import useInput from "../../../helpers/use-input";
import { Search } from "@mui/icons-material";
import { kstheme } from "../../../theme/theme";


export default function Support() {
  const [value, setValue] = useState(null);
  const [searchHide, setSearchHide] = useState(false)

  const [searchOptions, setSearchOptions] = useState([])
  const [userInfo, setUserInfo] = useState(null)

  const isTab = useMediaQuery(kstheme.breakpoints.down("md"));

  const [searchItem] = useInput({
    data: null,
    status: false,
    initial: null,
  });

  useEffect(() => {
    if (searchItem.value) {
      userList(searchItem.value)
    }

  }, [searchItem.value]);


  const userList = (v) => {
    let params = { "globalFilter": v, "start": 0, "size": 50 }
    getApiCallDynamic({ param: params, path: API.userList }).then((res) => {
      if (res?.status === 200) {
        setSearchOptions(res?.data?.data);
        setSearchHide(false)
      }
    });
  }





  useEffect(() => {
    if (value != null) {
      let param = { userCode: value };
      setUserInfo(null)
      getApiCallDynamic({ param: param, path: API.userInfo }).then((res) => {
        if (res?.status === 200) {
          setUserInfo(res?.data);
        }
      });
    }

  }, [value])


  return (
    <MainLoyOut>
      <Box py={5} px={{ xs: 1.5, md: 15 }}>
        <Grid container direction={"column"} spacing={2}>
          <Grid item>
            <Typography variant="h2">
              Looking to change email address or contact number of partner?
            </Typography>
          </Grid>
          <Grid item>
            <Box pb={4} width={"100%"} sx={{ display: "flex", flexDirection: "row" }}>
              <TextField
                autoComplete="off"
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px 0px 0px 8px",
                    height: "50px",
                  },
                }}

                placeholder={isTab ? "Search" : "Search for people with their name / registered number / registered email"}
                {...searchItem}
              />
              <Button
                variant="contained"
                size="large"

                sx={{
                  height: "50px",
                  borderRadius: "0px 8px 8px 0px",
                }}
              >
                <Search />
              </Button>
            </Box>

            {!searchHide ? (
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableBody>
                    {searchOptions.map((i) => (
                      <TableRow
                        onClick={() => { setValue(i.userCode); setSearchHide(true); }}
                        key={i.userCode}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {i.firstName + " " + i.lastName}
                        </TableCell>
                        <TableCell align="right">{i.phone}</TableCell>
                        <TableCell align="right">{i.email}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
          </Grid>
        </Grid>
        {!userInfo ? (
          searchOptions.length == 0 ?
            <SupportEmpty /> : null
        ) : (<>
          <UserCard userInfo={userInfo} />
        </>
        )}
      </Box>
    </MainLoyOut>
  );
}
