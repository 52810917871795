import { ArrowBackIos, ArrowForwardIos, NavigateNext } from '@mui/icons-material';
import { Box, Breadcrumbs, Button, Link as MLink, Stack, Typography } from '@mui/material';
import { DialogCommonHooks } from '../../../../../../helpers/modal/modal';
import CustomRadioChip from '../../../../../../helpers/radio-chip';
import { DemographicNeedInfo } from '../../../../../../components/loan-application/demographic-need-info';
import { Controller, useForm, useWatch } from 'react-hook-form'

import {
    CHOOSE_TYPE,
    ETHINI_CITY_STATUS,
    RACE_SUB_TYPE,
    RACE_SUB_TYPE_NATIVE,
    RACE_TYPE,
    SEX
} from '../../../../../../constants/constatnt-variable';
import React, { useState } from 'react';
import useInput from '../../../../../../helpers/use-input';
import CustomInput from '../../../../../../helpers/custom-input';
import { useLocation, useNavigate } from 'react-router-dom';
import { DemographicsValidation } from '../../../../../../constants/validation-rules';


export const Demogrpahic = ({ data, onSubmit, partnerInfoPath }) => {

    const route = useNavigate()
    const location = useLocation();

    const { control, handleSubmit, formState: { errors }, watch } = useForm({
        defaultValues: {
            ethinicityStatus: data?.ethinicityStatus,
            chooseType: data?.chooseType,
            text: data?.text,
            raceType: data?.raceType,
            raceSubType: data?.raceSubType,
            tribe: data?.tribe,
            sex: data?.sex,
        }
    })

    const [modalOpen, setModalOpen] = useState(true);
    const handleClose = () => {
        setModalOpen(false);
    };

    const [sexType] = useInput({
        data: null,
        status: false,
        initial: null
    });

    const {
        ethinicityStatus,
        chooseType,
        raceType,
    } = useWatch({ control })

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
                <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                    <Box>
                        <Box maxWidth={{ xs: '100%', md: '50%' }}>
                            <Breadcrumbs aria-label="breadcrumb"
                                separator={<NavigateNext fontSize="small" />}
                            >
                                <MLink underline="hover" color="inherit" href="#">
                                    {`${partnerInfoPath == "partner-info" ? "Partner" : "Borrower"} Profile`}
                                </MLink>
                                <Typography color="primary.main">Demographics</Typography>
                            </Breadcrumbs>
                            <Typography variant='h3' my={2}>{`Tell us more about your demographics`}</Typography>
                            <Typography variant='body1' mb={1}>
                                {`The purpose of collecting this information is 
                                to make sure that every individual will be treated fairly and the 
                                housing need of the communities will be fullfilled`
                                }
                            </Typography>

                            <Typography variant='h6' mt={2}>{`Ethinicity`}</Typography>
                            <Controller
                                name='ethinicityStatus'
                                control={control}
                                rules={DemographicsValidation.ethinicityStatus}
                                render={({ field }) => (
                                    <CustomRadioChip
                                        {...field}
                                        field={field}
                                        required
                                        label={`Are you a Hispanic or Latino?`}
                                        error={errors?.ethinicityStatus?.message}
                                        state={ethinicityStatus}
                                        data={ETHINI_CITY_STATUS}
                                    />
                                )}
                            />
                            <Controller
                                name='chooseType'
                                control={control}
                                rules={DemographicsValidation.chooseType}
                                render={({ field }) => (
                                    <CustomRadioChip
                                        {...field}
                                        field={field}
                                        required
                                        label={`Choose Type`}
                                        error={errors?.chooseType?.message}
                                        data={CHOOSE_TYPE}
                                    />
                                )}
                            />


                            {chooseType == 'others' ?
                                <Controller
                                    name="text"
                                    control={control}
                                    rules={DemographicsValidation.text}
                                    render={({ field }) => (
                                        <CustomInput {...field} type={'text'} error={errors?.text?.message} />
                                    )}
                                />
                                : null
                            }

                            <Typography variant='h6' mt={2}>{`Race`}</Typography>

                            <Controller
                                name='raceType'
                                control={control}
                                rules={DemographicsValidation.raceType}
                                render={({ field }) => (
                                    <CustomRadioChip
                                        {...field}
                                        field={field}
                                        required
                                        label={`Select your race`}
                                        error={errors?.raceType?.message}
                                        data={RACE_TYPE}
                                    />
                                )}
                            />

                            {raceType == 'asian' ?
                                <Controller
                                    name='raceSubType'
                                    control={control}
                                    rules={DemographicsValidation.raceSubType}
                                    render={({ field }) => (
                                        <CustomRadioChip
                                            {...field}
                                            field={field}
                                            required
                                            label={`Choose Type`}
                                            error={errors?.raceSubType?.message}
                                            data={RACE_SUB_TYPE}
                                        />
                                    )}
                                /> : null

                            }

                            {raceType == 'Native Hawaiian or Other pacific islander' ?
                                <Controller
                                    name='raceSubType'
                                    control={control}
                                    rules={DemographicsValidation.raceSubType}
                                    render={({ field }) => (
                                        <CustomRadioChip
                                            {...field}
                                            field={field}
                                            required
                                            label={`Choose Type`}
                                            error={errors?.raceSubType?.message}
                                            data={RACE_SUB_TYPE_NATIVE}
                                        />
                                    )}
                                /> : null

                            }

                            <Controller
                                name='tribe'
                                control={control}
                                rules={DemographicsValidation.tribe}
                                render={({ field }) => (
                                    <CustomInput {...field} type={'text'} label={'Mention your tribe'} required error={errors?.tribe?.message} />
                                )}
                            />

                            <Controller
                                name='sex'
                                control={control}
                                rules={DemographicsValidation.sex}
                                render={({ field }) => (
                                    <CustomRadioChip
                                        {...field}
                                        field={field}
                                        required
                                        label={`Sex`}
                                        error={errors?.sex?.message}
                                        data={SEX}
                                    />
                                )}
                            />
                        </Box>
                    </Box>

                    <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Button startIcon={<ArrowBackIos />} onClick={() => { route(location.pathname.replace(/[^/]*$/, 'contact-information')) }}>
                                Previous
                            </Button>

                            <Button type='submit' endIcon={<ArrowForwardIos />}>
                                Next
                            </Button>
                        </Stack>
                    </Box>
                </Stack>

                <DialogCommonHooks
                    show={modalOpen}
                    handleClose={handleClose}
                    title={`Why we need your Demographic Info?`}
                    body={
                        <DemographicNeedInfo handleClose={handleClose} />
                    }
                />
            </form>
        </>
    )
}